import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import '@store/languages'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { thousandsSeparators } from '@helpers/functions'
// components
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts'
import { Segment, Grid, Header } from 'semantic-ui-react'

const CaseStats = ({ listData, params }) => {
    const { t } = useTranslation()

    const [loading, setLoading] = useState(false)

    const [activeIndex1, setActiveIndex1] = useState(0)
    const [activeIndex2, setActiveIndex2] = useState(0)
    const [activeIndex3, setActiveIndex3] = useState(0)
    const [activeIndex4, setActiveIndex4] = useState(0)

    const [priorityCount, setPriorityCount] = useState([])
    const [stageCount, setStageCount] = useState([])
    const [typeCount, setTypeCount] = useState([])
    const [medianCount, setMedianCount] = useState([])

    const renderActiveShape = (props) => {
        const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props

        return (
            <g>
                <text x={cx} y={cy - 10} dy={8} textAnchor="middle">
                    {thousandsSeparators(value || 0)}
                </text>
                <text x={cx} y={cy + 10} dy={8} textAnchor="middle">
                    {`(${t('rate_value')} ${(percent * 100).toFixed(2)}%)`}
                </text>
                <text x={cx} y={cy + 105} dy={8} textAnchor="middle">
                    {payload.name}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
            </g>
        )
    }

    useEffect(() => {
        async function fetchData() {
            setLoading(true)

            const request = await requests.get(API.CASES_STATS + '?query={*}' + params)
            if (request.status === 200) {
                const result = request.response

                let piePriority = []
                piePriority.push(
                    {
                        id: 'high',
                        name: t('high'),
                        value: result.total_priority_high,
                        fill: 'var(--warning)',
                    },
                    {
                        id: 'low',
                        name: t('low'),
                        value: result.total_priority_low,
                        fill: 'var(--info)',
                    },
                    {
                        id: 'medium',
                        name: t('medium'),
                        value: result.total_priority_medium,
                        fill: 'var(--success)',
                    },
                    {
                        id: 'urgent',
                        name: t('urgent'),
                        value: result.total_priority_urgent,
                        fill: 'var(--danger)',
                    }
                )
                setPriorityCount(piePriority)

                let pieStage = []
                pieStage.push(
                    {
                        id: 'assigned',
                        name: t('assigned'),
                        value: result.total_assigned_cases,
                        fill: 'var(--primary)',
                    },
                    {
                        id: 'closed',
                        name: t('closed'),
                        value: result.total_closed_cases,
                        fill: 'var(--danger)',
                    },
                    {
                        id: 'new',
                        name: t('new'),
                        value: result.total_new_cases,
                        fill: 'var(--info)',
                    },
                    {
                        id: 'pending',
                        name: t('pending'),
                        value: result.total_pending_cases,
                        fill: 'var(--warning)',
                    }
                )
                setStageCount(pieStage)

                const pieTypes = Object.values(
                    request.response.case_by_types.reduce((acc, type) => {
                        if (type.total_cases > 0) {
                            if (!acc[type.case_type_title]) {
                                acc[type.case_type_title] = {
                                    name: type.case_type_title,
                                    value: 0,
                                    fill: getColorById(type.case_type_title),
                                }
                            }
                            acc[type.case_type_title].value += type.total_cases
                        }
                        return acc
                    }, {})
                )
                setTypeCount(pieTypes)

                let pieMedian = []
                pieMedian.push(
                    {
                        id: 'assigned_to_pending',
                        name: t('assigned_to_pending'),
                        value: result.median_assigned_to_pending,
                        fill: 'var(--warning)',
                    },
                    {
                        id: 'pending_to_closed',
                        name: t('pending_to_closed'),
                        value: result.median_pending_to_closed,
                        fill: 'var(--danger)',
                    },
                    {
                        id: 'new_to_assigned',
                        name: t('new_to_assigned'),
                        value: result.median_new_to_assigned,
                        fill: 'var(--primary)',
                    }
                )
                setMedianCount(pieMedian)
            }
            setLoading(false)
        }

        fetchData()
        // eslint-disable-next-line
    }, [listData])

    const onPieEnter1 = (_, index) => {
        setActiveIndex1(index)
    }
    const onPieEnter2 = (_, index) => {
        setActiveIndex2(index)
    }
    const onPieEnter3 = (_, index) => {
        setActiveIndex3(index)
    }
    const onPieEnter4 = (_, index) => {
        setActiveIndex4(index)
    }

    const getColorById = (id) => {
        const letters = '0123456789ABCDEF'
        let color = '#'
        for (let i = 0; i < 6; i++) {
            const charIndex = id.charCodeAt(i % id.length) % 16
            color += letters[charIndex]
        }
        return color
    }

    return (
        <Segment
            loading={loading}
            style={{
                padding: 0,
                marginTop: 0,
                background: 'transparent',
                boxShadow: 'none',
                border: 'none',
            }}
        >
            {loading && (
                <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '6rem' }}>
                    {t('loading_statistics')}
                </p>
            )}
            {!loading && (
                <Grid centered stackable>
                    <Grid.Row columns={5} style={{ paddingTop: 0 }}>
                        <Grid.Column tablet={6} computer={3} style={{ textAlign: 'center' }}>
                            <ResponsiveContainer width="100%" height={250}>
                                <PieChart>
                                    <Pie
                                        activeIndex={activeIndex1}
                                        activeShape={renderActiveShape}
                                        data={priorityCount}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={60}
                                        outerRadius={80}
                                        fill="#8884d8"
                                        dataKey="value"
                                        overflow={'visible'}
                                        onMouseEnter={onPieEnter1}
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                            <Header as={'h5'} style={{ margin: 0, marginBottom: '1rem' }}>
                                {t('priority')}
                            </Header>
                        </Grid.Column>
                        <Grid.Column tablet={6} computer={3} style={{ textAlign: 'center' }}>
                            <ResponsiveContainer width="100%" height={250}>
                                <PieChart>
                                    <Pie
                                        activeIndex={activeIndex2}
                                        activeShape={renderActiveShape}
                                        data={stageCount}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={60}
                                        outerRadius={80}
                                        fill="black"
                                        dataKey="value"
                                        onMouseEnter={onPieEnter2}
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                            <Header as={'h5'} style={{ margin: 0, marginBottom: '1rem' }}>
                                {t('stages')}
                            </Header>
                        </Grid.Column>
                        <Grid.Column tablet={6} computer={3} style={{ textAlign: 'center' }}>
                            <ResponsiveContainer width="100%" height={250}>
                                <PieChart>
                                    <Pie
                                        activeIndex={activeIndex3}
                                        activeShape={renderActiveShape}
                                        data={typeCount}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={60}
                                        outerRadius={80}
                                        fill="#8884d8"
                                        dataKey="value"
                                        onMouseEnter={onPieEnter3}
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                            <Header as={'h5'} style={{ margin: 0, marginBottom: '1rem' }}>
                                {t('type')}
                            </Header>
                        </Grid.Column>
                        {/* <Grid.Column tablet={6} computer={3} style={{ textAlign: 'center' }}>
                            <ResponsiveContainer width="100%" height={250}>
                                <PieChart>
                                    <Pie
                                        activeIndex={activeIndex4}
                                        activeShape={renderActiveShape}
                                        data={medianCount}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={60}
                                        outerRadius={80}
                                        fill="#8884d8"
                                        dataKey="value"
                                        onMouseEnter={onPieEnter4}
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                            <Header as={'h5'} style={{ margin: 0, marginBottom: '1rem' }}>
                                {t('median')}
                            </Header>
                        </Grid.Column> */}
                    </Grid.Row>
                </Grid>
            )}
        </Segment>
    )
}

export default CaseStats
