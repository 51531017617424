// API routes config
const localhost = false
const origin = window.location.origin
const development = localhost ? 'http://localhost:8000/api' : 'https://dev-hriis.sodonbos.com/api'
const API_URL = origin === 'http://localhost:3000' ? development : window.location.origin + '/api'

// for loading animation
export const LOADING_TIME = 250

// tables pagination
export const PAGINATION_LIMIT = 5

// Google API
export const googleMapsApiKey = 'AIzaSyBkzqEbiNLSqnulRtInQZJDz7X-wbRWemo'
export const libraries = ['places']

export const API = {
    API_URL: API_URL,
    LOGIN: API_URL + '/users/login/',
    REFRESH: API_URL + '/users/login/refresh/',
    VERIFY: API_URL + '/users/login/verify/',
    REGISTER: API_URL + '/users/',
    USERS: API_URL + '/users/',
    USERS_ME: API_URL + '/users/me/',
    RESEND_ACTIVATION: API_URL + '/users/resend_activation/',
    RESET_PASSWORD: API_URL + '/users/reset_password/',
    RESET_CONFIRM: API_URL + '/users/reset_password_confirm/',
    SET_PASSWORD: API_URL + '/users/set_password/',
    GET_USER_ACTIVATION: API_URL + '/users/activation/', // + <uid> / + <token>
    GET_USER_ME: API_URL + '/users/me/',
    SET_USERNAME: API_URL + '/users/set_username/',
    SET_EMAIL: API_URL + '/users/me/',
    USER_PREFERENCES: API_URL + '/users/me/preferences/',
    SET_AVATAR: API_URL + '/users/avatar/',
    DELETE_AVATAR: API_URL + '/users/avatar/',
    SET_PREFERENCES: API_URL + '/users/preferences/',
    GET_PREFERENCES: API_URL + '/users/preferences/',
    PERMISSION_GROUPS: API_URL + '/users/permission_groups/',
    PERMISSIONS: API_URL + '/users/permissions/',
    COMMON: API_URL + '/common/',
    BRANCHES: API_URL + '/common/branches/',
    DRIVER_LICENCE: API_URL + '/common/driver_licence/',
    PREFERENCES: API_URL + '/companies/preferences/',
    HEALTH_INSURANCE: API_URL + '/common/health_insurance/',
    HEALTH_INSURANCES: API_URL + '/common/health_insurance/',

    // public
    PUBLIC: API_URL + '/public/',
    PUBLIC_LOGO: API_URL + '/public/logo/',

    //COMPANY
    CREATE_PHONE: API_URL + '/common/phones/',
    CREATE_PHONE_TYPE: API_URL + '/common/phone_types/',
    PHONE_TYPES: API_URL + '/common/phone_types/',
    CREATE_EMAIL: API_URL + '/common/emails/',
    CREATE_CONTACT_INFO: API_URL + '/common/contact_info/',
    CREATE_ADDRESS: API_URL + '/common/addresses/',
    CREATE_BUSINESS_DETAIL: API_URL + '/common/business_details/',
    COMPANIES: API_URL + '/companies/',
    COMPANY_CERTIFICATES: API_URL + '/companies/certificates/',
    COMPANY_CERTIFICATE_TYPES: API_URL + '/companies/certificate_types/',
    TREE: '/tree/',
    POSITION_TYPES: API_URL + '/companies/positions/',
    UNIT_TYPES: API_URL + '/companies/unit_types/',
    UNITS: API_URL + '/companies/units/',
    BANK_ACCOUNTS: API_URL + '/common/bank_accounts/',
    CHOICES: API_URL + '/common/choices/',
    NACE_CODE: API_URL + '/common/nace_code/',
    CONTACT_LABELS: API_URL + '/common/contact_labels/',
    LOGO: '/logo/',
    SETTINGS_RETURN_URL: '/settings_return_url/',
    // STATS: "/stats/",

    //UNIT
    UNIT: 'units/',

    //PORTALS
    GET_PORTALS: API_URL + '/jobs/external_portals/',

    //EMPLOYEES
    EMPLOYEES: API_URL + '/employees/',
    GET_EMPLOYEES: API_URL + '/employees/',
    DELETE_EMPLOYEE: API_URL + '/employees/', // +{id}
    GET_EMPLOYEE: API_URL + '/employees/', // +{id}
    ADD_EDUCATION: API_URL + '/common/educations/',
    STATS: '/stats/',
    PERSONAL_INFORMATION: API_URL + '/employees/personal_information/',
    ADDRESSES: API_URL + '/common/addresses/',
    INTERESTS: API_URL + '/common/interests/',
    EXPERIENCES: API_URL + '/common/experiences/',
    SKILLS: API_URL + '/common/skills/',
    BIRTH_INFO: API_URL + '/common/birth_info/',
    CONTACT_INTO: API_URL + '/common/contact_info/',
    PERSONS: API_URL + '/common/persons/',
    BIRTH_INFO_FAMILY: API_URL + '/common/birth_info_family/',
    PERSON_TYPE: API_URL + '/common/person_types/',
    FAMILY_MEMBERS: API_URL + '/common/family_members/',
    UNIT_NAME: API_URL + '/companies/units/',
    RESPONSIBLE_PERSONS_NAME: API_URL + '/companies/responsible_persons/',
    ACTIVE_COMPANY: API_URL + '/companies/',
    ATTENDANCE_CLOSE_DAY: API_URL + '/attendance/records/close_day/',
    ATTENDANCE_BASE: API_URL + '/attendance/',
    ATTENDANCE: API_URL + '/attendance/records/',
    ATTENDANCE_INTERRUPTION_REASONS: API_URL + '/attendance/interruption_reasons/',
    ATTENDANCE_INTERRUPTIONS: API_URL + '/attendance/interruptions/',
    ATTENDANCE_TURNUSES: API_URL + '/attendance/turnuses/',
    ATTENDANCE_MANUAL: API_URL + '/attendance/records/manual/',
    ABSENCE_TYPES: API_URL + '/attendance/absence_types/',
    ATTACHMENT_GROUPS: API_URL + '/common/attachment_groups/',
    ATTACHMENTS: API_URL + '/common/attachments/',
    ATTACHMENTS_BULK: API_URL + '/common/attachments_bulk/',
    ACCESS_CARD: API_URL + '/employees/access_card/',
    ACCESS_CARD_CATEGORIES: API_URL + '/employees/access_card/categories/',

    //Candidates
    CANDIDATES: API_URL + '/candidates/',
    REJECTED: API_URL + '/candidates/rejections/',
    BLACKLISTED: API_URL + '/candidates/blacklist_records/',
    HIRING_ROOM: API_URL + '/candidates/recruitments/',
    HIRING_ROOMS: API_URL + '/candidates/recruitments/',
    PROCESSES: API_URL + '/processes/',
    REQUIREMENT_CONFIGS: API_URL + '/processes/requirement_configs/',
    STAGES: API_URL + '/processes/stages/',
    MANAGERS: API_URL + '/candidates/hiring_managers/',
    PERSONAL_INFORMATION_candidate: API_URL + '/candidates/personal_information/',
    RECRUITMENT_STAGES: API_URL + '/candidates/archived_recruitment_stages/',
    CREATE_STAGES: API_URL + '/processes/stages/',
    RECRUITER_STATS: API_URL + '/reports/candidates/recruiters/stats/',
    CANDIDATES_INTERACTIONS: API_URL + '/reports/candidates/interactions/stats/',
    SOURCES: API_URL + '/candidates/hiring_sources/',
    RECRUITMENT_ASSIGNMENTS: API_URL + '/candidates/recruitment_assignments/',
    CANDIDATES_LABELS: API_URL + '/candidates/labels/',
    ///api/companies/{id}/

    //TEAMS
    TEAMS: API_URL + '/teams/',
    TEAMMEMBERS: API_URL + '/teams/members/',

    //BENEFITS
    BENEFITS: API_URL + '/benefits/',
    BENEFITS_CATEGORIES: API_URL + '/benefits/categories/',

    //JOBS
    JOBS: API_URL + '/jobs/',
    APPLICATION: API_URL + '/jobs/application/',
    PUBLICATIONS: API_URL + '/jobs/publications/',
    EXTERNAL_PUBLICATIONS: API_URL + '/jobs/external_publications/',
    JOB_OFFERS: API_URL + '/jobs/offers/',
    BASE_OFFERS: API_URL + '/jobs/base_offers/',
    JOB_REQUIREMENTS: API_URL + '/jobs/requirements/',
    JOB_SALARIES: API_URL + '/jobs/salaries/',
    JOB_SUPLEMENTS: API_URL + '/jobs/suplements/',
    JOB_WORKPLACES: API_URL + '/jobs/workplaces/',
    JOB_CONTRACTS: API_URL + '/jobs/contracts/',
    JOB_BONUSES: API_URL + '/jobs/bonuses/',
    JOB_POSITIONS: API_URL + '/jobs/positions/',
    POSITIONS_GROUPS: API_URL + '/jobs/position_groups/',

    // Intergrations
    INTEGRATIONS: API_URL + '/integrations/',
    RABOTA_CITIES: API_URL + '/integrations/rabota_ua/cities/',
    GIT_ATTACHMENTS: API_URL + '/integrations/gitlab/attachments/',
    GIT_ISSUE: API_URL + '/integrations/gitlab/issues/',
    GET_INVOICES: API_URL + '/integrations/invoices/',
    AUTH_CONFIGS: API_URL + '/integrations/auth_configs/',
    CALL_JOURNALING: API_URL + '/integrations/3cx/call_journalings/',
    HRFLOW: API_URL + '/integrations/hrflow/cv_parser_records/',
    AZURE_PARSER: API_URL + '/integrations/susr/azure_parse/',
    OPENAI: API_URL + '/integrations/openai/',

    //ORDERS
    ORDERS: API_URL + '/orders/',
    ORDER_FULFILLMENTS: API_URL + '/orders/order_fulfillments/',
    ORDERS_HISTORY: API_URL + '/orders/order_history/',
    LEADS: API_URL + '/orders/leads/',
    CATALOGUE_ITEMS: API_URL + '/orders/catalogue_items/',
    CATALOGUE_ITEM_CATEGORY: API_URL + '/orders/catalogue_item_category/',

    //PROJECTS
    PROJECTS: API_URL + '/projects/',
    PROJECT_ISSUES_GROUPS: API_URL + '/projects/issue_groups/',
    PROJECT_ISSUES: API_URL + '/projects/issues/',
    PROJECT_GROUPS: API_URL + '/projects/issue_groups/',
    PROJECT_STAGES: API_URL + '/projects/project_stages/',
    PROJECT_LABELS: API_URL + '/projects/labels/',
    PROJECT_FILES: API_URL + '/projects/files/',
    PROJECT_FILE_FOLDER: API_URL + '/projects/folders/',
    PROJECT_MILESTONES: API_URL + '/projects/milestones/',
    PROJECT_MEMBERS: API_URL + '/projects/project_members/',

    //TIMESHEETS
    TIMESHEETS: API_URL + '/timesheets/',
    TIMESHEETS_CATEGORIES: API_URL + '/timesheets/categories/',
    TIMESHEETS_RECORDS: API_URL + '/timesheets/records/',
    TIMESHEETS_EXPORTS: API_URL + '/exports/timesheet_records/',

    //ACCOUNTS
    ACCOUNTS: API_URL + '/accounts/',
    ACCOUNTS_LABELS: API_URL + '/accounts/labels/',
    SUPPLIERS: API_URL + '/accounts/suppliers/',

    //JOBS
    GET_JOB_OFFERS: API_URL + '/jobs/offers/',
    JOB_PUBLICATIONS: API_URL + '/jobs/publications/',
    PUBLICATION_REACTIONS: API_URL + '/jobs/application/form_instances/',

    // COMMON
    BUSINESS_DETAIL: API_URL + '/common/business_details/',
    PASSPORTS: API_URL + '/common/passports/',
    PHONES: API_URL + '/common/phones/',
    EMAIL: API_URL + '/common/emails/',
    EDUCATIONS: API_URL + '/common/educations/',
    LANGUAGES: API_URL + '/common/languages/',
    IDENTITY_DOCUMENTS: API_URL + '/common/identity_documents/',

    // ONBOARDING
    ONBOARDING_CHECKLIST: API_URL + '/onboarding/checklist_instances/',
    ONBOARDING_CHECKLIST_CONFIG: API_URL + '/onboarding/checklist_configs/',
    ONBOARDING_ITEMS: API_URL + '/onboarding/item_instances/',
    ONBOARDING_ITEMS_CONFIG: API_URL + '/onboarding/item_configs/',

    // COURSES
    COURSES: API_URL + '/courses/',
    COURSES_CATEGORIES: API_URL + '/courses/categories/',
    COURSES_RECORDS: API_URL + '/courses/records/',
    PLANNED_COURSES: API_URL + '/courses/planned/',

    //CASES
    CASES: API_URL + '/cases/',
    CASES_TYPES: API_URL + '/cases/types/',
    CASES_NOTES: API_URL + '/cases/notes/',
    CASES_LABELS: API_URL + '/cases/labels/',

    //TEMPLATING
    TEMPLATES: API_URL + '/templating/',
    TEMPLATING: API_URL + '/templating/documents/',
    TEMPLATING_CATEGORY: API_URL + '/templating/categories/',
    TEMPLATING_MODELS: API_URL + '/templating/models/',

    // REPORTS
    REPORTS: API_URL + '/reports/',
    DASHBOARD_STATS: API_URL + '/reports/dashboard/',

    // CONTRACTS
    CONTRACTS: API_URL + '/contracts/',
    CONTRACTS_DATA: API_URL + '/contracts/data/',
    CONTRACTS_INSTANCES: API_URL + '/contracts/instances/',
    CONTRACTS_ISCO: API_URL + '/contracts/isco/',
    CONTRACTS_SALARIES: API_URL + '/contracts/salaries/',
    CONTRACTS_TYPES: API_URL + '/contracts/types/',
    CONTRACTS_TERMINATION_REASONS: API_URL + '/contracts/termination_reasons/',
    CONTRACTS_TERMINATIONS: API_URL + '/contracts/terminations/',
    CONTRACTS_FLUCTUATIONS: API_URL + '/reports/contracts/fluctuations/',
    CONTRACTS_ENGAGEMENTS_TABLE: API_URL + '/reports/contracts/engagements_table/',
    CONTRACTS_TERMINATIONS_TABLE: API_URL + '/reports/contracts/terminations_table/',

    // B2B Contracts
    B2B_CONTRACTS: API_URL + '/contracts/business/',
    // EXPORTING
    EXPORTS: API_URL + '/exports/',
    EXPORTS_HTML_TO_PDF: API_URL + '/exports/templating_pdf_view/',
    EXPORTS_TIMESHEET_RECORDS: API_URL + '/exports/timesheet_records',

    // CONTACTS
    CONTACTS: API_URL + '/contacts/',

    // APPROVALS
    APPROVALS: API_URL + '/approvals/',

    // payrolls
    PAYROLLS: API_URL + '/payrolls/',

    // ASSETS
    ASSETS: API_URL + '/assets/',
    ASSET_CATEGORIES: API_URL + '/assets/categories/',
    ASSET_CORRECTIONS: API_URL + '/assets/corrections/',
    ASSET_DISCARDS: API_URL + '/assets/discards/',
    ASSET_HISTORY: API_URL + '/assets/history/',
    ASSET_ISSUE_CARDS: API_URL + '/assets/issue_cards/',
    ASSET_ITEMS: API_URL + '/assets/items/',
    ASSET_MEASURE_UNITS: API_URL + '/assets/measure_units/',
    ASSET_POSITION_CONFIG: API_URL + '/assets/position_config/',
    ASSET_STORED_ITEMS: API_URL + '/assets/stored_items/',
    ASSETS_WAREHOUSE_POSITIONS: API_URL + '/assets/warehouse_positions/',
    ASSET_WAREHOUSES: API_URL + '/assets/warehouses/',
    ASSET_ISSUED_ITEMS: API_URL + '/assets/issued_items/',
    ASSET_ISSUED_ITEMS_OVERVIEW: API_URL + '/assets/issued_items/overview/',

    // COST CENTERS
    COST_CENTERS: API_URL + '/cost_centers/',
    COST_CENTERS_BUDGETS: API_URL + '/cost_centers/budgets/',
    COST_CENTERS_RECORD_CATEGORIES: API_URL + '/cost_centers/record_categories/',
    COST_CENTERS_RECORDS: API_URL + '/cost_centers/records/',
    COST_CENTERS_ALLOWED_USERS: API_URL + '/cost_centers/allowed_users/',
    BUDGETS_FORECAST: API_URL + '/cost_centers/budgets_forecast/',

    // INVOICES
    INVOICES: API_URL + '/integrations/invoices/',
    INVOICES_CREATE_USER_WITH_ACCOUNTS: API_URL + '/integrations/invoices/user_with_accounts/',
    INVOICES_CONNECTIONS: API_URL + '/integrations/invoices/invoice_connections/',
    INVOICES_ALLOWED_USERS: API_URL + '/integrations/invoices/allowed_users/',
    INVOICES_LOGIN: API_URL + '/integrations/invoices/login/',

    // VEHICLES
    VEHICLES: API_URL + '/vehicles/',

    // CONFIG
    CONFIG: API_URL + '/config/',

    // FORECASTS
    FORECASTS: API_URL + '/forecasts/',

    // GDPR Agreements
    GDPR_AGREEMENTS: API_URL + '/common/gdpr_agreements/',

    // GDPR
    GDPRS: API_URL + '/common/gdprs/',
    GDPRS_PUBLIC: API_URL + '/public/gdprs/',

    // stats
    CANDIDATE_STAGE_RECRUITMENT_STATS: API_URL + '/reports/candidates/stage_recruitments/stats/',
    PUBLISHERS_STATS: API_URL + '/reports/jobs/publishers/stats/',
    EMPLOYEES_TOTAL_STATS: API_URL + '/reports/employees/stats/',
    PROJECTS_ISSUE_STATS: API_URL + '/reports/projects/issues/stats/',
    PROJECTS_STATS: API_URL + '/reports/projects/',
    EMPLOYEES_STATS: API_URL + '/reports/employees/',
    COMPANY_STATS: API_URL + '/reports/company/',
    CANDIDATES_STATS: API_URL + '/reports/candidates/',
    TEAMS_STATS: API_URL + '/reports/teams/',
    TIMESHEETS_STATS: API_URL + '/reports/timesheets/',
    CONTRACT_TYPES_STATS: API_URL + '/reports/contract_types/',
    JOBS_STATS: API_URL + '/reports/jobs/',
    COST_CENTER_RATIO_STATS: API_URL + '/reports/cost_centers/ratios/',
    CONTRACTS_STATS: API_URL + '/reports/contracts/stats/',
    FUNCTION_STATS: API_URL + '/reports/function/stats/',
    CASES_STATS: API_URL + '/reports/cases/stats/',

    // imports
    IMPORTS: API_URL + '/imports/',
    IMPORTS_EMPLOYEES: API_URL + '/imports/employees/',

    // tasks
    TASKS: API_URL + '/tasks/',
    // tasks
    LOGISTICS: API_URL + '/logistics/',

    // abroads
    ABROADS: API_URL + '/abroads/slovak/',
    ABROADS_STATS: API_URL + '/reports/slovak_abroads/stats/',

    // visas and stays
    FOREIGNERS: API_URL + '/foreigners/',
    FOREIGNERS_BULK: API_URL + '/foreigners/processes/bulk/',
    FOREIGNERS_NOTES: API_URL + '/foreigners/notes/',
    FOREIGNERS_LABELS: API_URL + '/foreigners/labels/',
    SUBMISSION_OFFICES: API_URL + '/foreigners/submission_offices/',

    // accommodations
    ACCOMMODATIONS: API_URL + '/accommodations/',
    GUESTS: API_URL + '/accommodations/guests/',
    ACCOMMODATIONS_MONTH_STATS: API_URL + 'reports/accommodations/month_on_month/stats/',

    // notifications
    NOTIFICATIONS: API_URL + '/notifications/',

    // services
    SERVICES: API_URL + '/services/',
}
