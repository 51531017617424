import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
// store
import { isEmpty } from '@services/helpers/validation'
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import { Form, Divider, Label } from 'semantic-ui-react'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import NonFieldErrors from '@components/NonFieldErrors'
import SuperField from '@components/forms/SuperField'
import PhoneField from '@components/forms/common/PhoneField'
import EmailField from '@components/forms/common/EmailField'

const PersonsForm = ({ data, isCreate, setData, setTotal, onClose, setHeader }) => {
    const { t } = useTranslation()
    const currency = 'EUR'
    const [personType, setPersonType] = useState(
        data
            ? data?.profile
                ? data?.profile?.profile_type === 1
                    ? 1
                    : data?.profile?.profile_type === 2 && 0
                : 2
            : 0
    )
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(true)
    const [sumValueType, setSumValueType] = useState(null)

    const [guest, setGuest] = useState({
        id: data?.id || '',
        //purpose: data?.purpose || '',
        from_date: data?.from_date || '',
        to_date: data?.to_date || '',
        //is_archived: data?.is_archived || false,
        price_per_night_per_person: data?.price_per_night_per_person ? parseFloat(data.price_per_night_per_person) : '',
        accommodation: data?.accommodation?.id || '',
        currency: currency,
        profile: data?.profile?.id || null,
        business_detail: data?.business_detail?.id || '',
        room: data?.room?.id || '',
        firstname: data?.firstname || '',
        lastname: data?.lastname || '',
        email: data?.email || '',
        phone: data?.phone || '',
        id_card_number: data?.id_card_number || '',
        id_type: data?.id_type || '',
        is_self_paying: data?.is_self_paying || null,
        refundable_percentage: data?.refundable_percentage || '',
        refundable_price_value: data?.refundable_price_value || '',
        order: data?.order?.id || '',
        contract: data?.contract?.id || '',
    })

    useEffect(() => {
        async function fetchContracts() {
            setLoading(true)
            let query = guest.profile ? `&employee=${guest.profile}` : ''
            const request = await requests.get(
                API.CONTRACTS + '?status=1&status=3&status=6&only_basic_info=true' + query
            )

            if (request.status === 200) {
                const contracts = request.response.results
                const contractsOptions = []
                for (let i = 0; i < contracts.length; i++) {
                    contractsOptions.push({
                        key: contracts[i].id,
                        value: contracts[i].id,
                        text:
                            contracts[i]?.employee?.fullname_with_titles +
                            ' - ' +
                            (contracts[i]?.employer?.name || '') +
                            (contracts[i].work_position ? ' (' + contracts[i].work_position.title + ')' : ''),
                    })
                }
                setOptions(contractsOptions)
            }
            setLoading(false)
        }

        fetchContracts()
    }, [guest.profile])

    const isValidRange = (from, to) => {
        let dateFrom = from ? moment(from) : null
        let dateTo = to ? moment(to) : null

        if (dateFrom && dateTo) {
            if (dateFrom.isSame(dateTo)) return false
            if (dateFrom.isAfter(dateTo)) return false

            return true
        }

        return false
    }

    const [isProcessing, setIsProcessing] = useState(false)
    const [errors, setErrors] = useState(null)

    const handleSubmit = async () => {
        setErrors(null)
        setIsProcessing(true)

        const newData = {
            ...guest,
            price_per_night_per_person:
                guest.price_per_night_per_person !== '' ? guest.price_per_night_per_person : null,
            refundable_percentage: guest?.refundable_percentage || 0,
            refundable_price_value: guest?.refundable_price_value || 0,
            is_self_paying: guest?.is_self_paying || false,
        }

        if (!guest.id || guest.id === 0) {
            const request = await requests.post(API.ACCOMMODATIONS + 'guests/', newData)

            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                setData((prev) => [request.response, ...prev])
                setTotal((prev) => prev + 1)
                onClose()
            }
        } else {
            const request = await requests.patch(API.ACCOMMODATIONS + 'guests/' + guest.id + '/', newData)

            if (request.status === 400) setErrors(request.response)
            if (request.status === 200) {
                setData((prev) =>
                    prev.map((item) => {
                        if (item.id === guest.id) {
                            item = request.response
                        }
                        return item
                    })
                )
                onClose()
            }
        }

        setIsProcessing(false)
    }

    useEffect(() => {
        if (!isCreate) setHeader(t('edit_an_accommodation_record'))
        // eslint-disable-next-line
    }, [])

    return (
        <Form onSubmit={handleSubmit}>
            <NonFieldErrors errors={errors} />
            <Form.Group>
                <SuperField
                    as="radio"
                    label={t('employee')}
                    checked={personType === 0}
                    onChange={() => setPersonType(0)}
                />
                <SuperField
                    as="radio"
                    label={t('candidate')}
                    checked={personType === 1}
                    onChange={() => setPersonType(1)}
                />
                <SuperField
                    as="radio"
                    label={t('other_person')}
                    checked={personType === 2}
                    onChange={() => setPersonType(2)}
                />
            </Form.Group>
            {personType === 0 && (
                <SuperField
                    as="choice-select"
                    search
                    label={t('employee')}
                    value={guest.profile}
                    endpoint={API.EMPLOYEES}
                    additionalFilters="&only_basic_info=true"
                    error={errors?.profile?.[0] || false}
                    text="fullname"
                    onChange={(e, { value }) =>
                        setGuest({
                            ...guest,
                            profile: value,
                        })
                    }
                    initialOptions={{
                        attribute: 'fullname',
                        source: data?.profile,
                    }}
                />
            )}
            {personType === 1 && (
                <SuperField
                    as="choice-select"
                    search
                    label={t('candidate')}
                    value={guest.profile}
                    endpoint={API.CANDIDATES}
                    additionalFilters="&only_basic_info=true"
                    error={errors?.profile?.[0] || false}
                    text="fullname"
                    onChange={(e, { value }) =>
                        setGuest({
                            ...guest,
                            profile: value,
                        })
                    }
                    initialOptions={{
                        attribute: 'fullname',
                        source: data?.profile,
                    }}
                />
            )}
            {(!isCreate || personType === 2) && (
                <>
                    <Form.Group widths="equal">
                        <SuperField
                            as="input"
                            label={t('firstname')}
                            placeholder={t('firstname')}
                            value={guest.firstname}
                            onChange={(e, { value }) => setGuest({ ...guest, firstname: value })}
                        />
                        <SuperField
                            as="input"
                            label={t('lastname')}
                            placeholder={t('lastname')}
                            value={guest.lastname}
                            onChange={(e, { value }) => setGuest({ ...guest, lastname: value })}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <PhoneField
                            hideType
                            placeholder={t('enter_number')}
                            value={guest.phone}
                            setValue={(e, { value }) =>
                                setGuest({
                                    ...guest,
                                    phone: value.replaceAll(' ', ''),
                                })
                            }
                        />
                        <EmailField
                            label={t('email')}
                            placeholder={t('enter_email')}
                            value={guest.email}
                            setValue={(e, { value }) =>
                                setGuest({
                                    ...guest,
                                    email: value,
                                })
                            }
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <SuperField
                            as="choice"
                            search
                            //type="identity_document_types"
                            customOptions={[
                                { key: 0, value: t('driver_licence'), text: t('driver_licence') },
                                { key: 1, value: t('passport'), text: t('passport') },
                                { key: 2, value: t('personal_card'), text: t('personal_card') },
                            ]}
                            label={t('id_type')}
                            value={guest.id_type}
                            error={errors?.id_type?.[0] || false}
                            onChange={(e, { value }) =>
                                setGuest({
                                    ...guest,
                                    id_type: value,
                                })
                            }
                            // initialOptions={{
                            //     attribute: 'id_type',
                            //     source: data,
                            // }}
                        />
                        <SuperField
                            as="input"
                            label={t('id_card_number')}
                            placeholder={t('id_card_number')}
                            value={guest.id_card_number}
                            disabled={!guest.id_type}
                            onChange={(e, { value }) => setGuest({ ...guest, id_card_number: value })}
                        />
                    </Form.Group>
                </>
            )}
            <Divider />
            <Form.Group widths="equal">
                <SuperField
                    as="choice-select"
                    search
                    required
                    label={t('accommodation')}
                    value={guest.accommodation}
                    endpoint={API.ACCOMMODATIONS}
                    additionalFilters="&query={id, name}"
                    error={errors?.accommodation?.[0] || false}
                    text="name"
                    onChange={(e, { value }) =>
                        setGuest({
                            ...guest,
                            accommodation: value,
                        })
                    }
                    initialOptions={{
                        attribute: 'name',
                        source: data?.accommodation,
                    }}
                />
                <SuperField
                    as="choice-select"
                    search
                    label={t('room')}
                    value={guest.room}
                    endpoint={API.ACCOMMODATIONS + 'rooms/'}
                    additionalFilters={`&accommodation=${guest.accommodation}`}
                    error={errors?.room?.[0] || false}
                    disabled={!guest.accommodation}
                    text="number"
                    onChange={(e, { value }) =>
                        setGuest({
                            ...guest,
                            room: value,
                        })
                    }
                    initialOptions={{
                        attribute: 'number',
                        source: data?.room,
                    }}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField
                    as="datepicker"
                    required
                    label={t('date_from')}
                    placeholder={t('date_from')}
                    error={
                        guest.from_date !== '' && guest.to_date !== '' && !isValidRange(guest.from_date, guest.to_date)
                            ? t('invalid_date_range')
                            : false
                    }
                    value={guest.from_date}
                    onChange={(e, { value }) => setGuest({ ...guest, from_date: value })}
                />
                <SuperField
                    as="datepicker"
                    required
                    label={t('date_to')}
                    placeholder={t('date_to')}
                    error={
                        guest.from_date !== '' && guest.to_date !== '' && !isValidRange(guest.from_date, guest.to_date)
                            ? t('invalid_date_range')
                            : false
                    }
                    value={guest.to_date}
                    onChange={(e, { value }) => setGuest({ ...guest, to_date: value })}
                />
            </Form.Group>

            <Form.Group widths="equal">
                {/* <SuperField
                    as="choice-select"
                    search
                    type="purpose_choices"
                    label={t('purpose')}
                    value={guest.purpose}
                    error={errors?.purpose?.[0] || false}
                    onChange={(e, { value }) =>
                        setGuest({
                            ...guest,
                            purpose: value,
                        })
                    }
                    initialOptions={{
                        attribute: 'purpose',
                        as_display: true,
                        source: data,
                    }}
                /> */}
                <Form.Input
                    label={t('price_per_night_per_person')}
                    placeholder={t('price_per_night_per_person')}
                    value={guest.price_per_night_per_person}
                    error={errors?.price_per_night_per_person?.[0] || false}
                    onChange={(e, { value }) =>
                        setGuest({
                            ...guest,
                            price_per_night_per_person: value.replace(',', '.'),
                        })
                    }
                >
                    <input />
                    <Label basic style={{ paddingTop: '0.9rem' }}>
                        {currency}
                    </Label>
                </Form.Input>
            </Form.Group>
            <Form.Group widths="equal">
                {personType === 0 && (
                    <SuperField
                        as="choice"
                        search
                        label={t('contract')}
                        key={guest.profile}
                        value={guest.contract}
                        loading={loading}
                        disabled={loading}
                        customOptions={options}
                        onChange={(e, { value }) => {
                            setGuest({ ...guest, contract: value })
                        }}
                        // endpoint={API.CONTRACTS}
                        // additionalFilters={
                        //     '&status=1&status=3&status=6' + guest.profile ? `&employee=${guest.profile}` : ''
                        // }
                        // text={(item) =>
                        //     item?.employee?.fullname_with_titles +
                        //     ' - ' +
                        //     (item?.employer?.name || '') +
                        //     (item.work_position ? ' (' + item.work_position.title + ')' : '')
                        // }
                    />
                )}
                <SuperField
                    as="choice-select"
                    search
                    value={guest.order}
                    endpoint={API.ORDERS}
                    label={t('order')}
                    onChange={(e, { value }) => setGuest({ ...guest, order: value })}
                    text={(item) => `${item.name}`}
                    additionalFilters={`&only_basic_info=true&is_active=true`}
                    initialOptions={{
                        attribute: 'name',
                        source: data?.order,
                    }}
                />
            </Form.Group>
            <Divider />
            <Form.Group>
                <SuperField
                    as="checkbox"
                    label={t('self_paying')}
                    checked={guest.is_self_paying === true}
                    onChange={() => {
                        setGuest({
                            ...guest,
                            is_self_paying: true,
                            refundable_percentage: 0,
                            refundable_price_value: 0,
                        })
                    }}
                />
                <SuperField
                    as="checkbox"
                    label={t('paid_by_employer')}
                    checked={guest.is_self_paying === false}
                    onChange={() => {
                        setGuest({
                            ...guest,
                            is_self_paying: false,
                            refundable_percentage: 0,
                            refundable_price_value: 0,
                        })
                    }}
                />
            </Form.Group>
            {(guest.is_self_paying === true || guest.is_self_paying === false) && (
                <>
                    <Form.Group>
                        <SuperField
                            as="checkbox"
                            label={t('employer_contribution') + ' (%)'}
                            checked={sumValueType === 1}
                            onChange={() => {
                                setSumValueType(1),
                                    setGuest({ ...guest, refundable_percentage: '', refundable_price_value: '' })
                            }}
                            style={{ paddingTop: '1rem' }}
                        />
                        <SuperField
                            as="checkbox"
                            label={t('employer_contribution') + ' (' + t('fixed_amount') + ')'}
                            checked={sumValueType === 2}
                            onChange={() => {
                                setSumValueType(2),
                                    setGuest({ ...guest, refundable_percentage: '', refundable_price_value: '' })
                            }}
                            style={{ paddingTop: '1rem' }}
                        />
                    </Form.Group>
                    {sumValueType ? (
                        sumValueType === 1 ? (
                            <SuperField
                                as="input"
                                label={t('employer_contribution') + ' (%)'}
                                placeholder={t('employer_contribution')}
                                value={guest.is_self_paying === false ? '100' : guest.refundable_percentage}
                                onChange={(e, { value }) =>
                                    setGuest({ ...guest, refundable_percentage: value, refundable_price_value: '' })
                                }
                            />
                        ) : (
                            sumValueType === 2 && (
                                <SuperField
                                    as="input"
                                    label={t('employer_contribution') + ' (' + t('fixed_amount') + ')'}
                                    placeholder={t('employer_contribution')}
                                    value={guest.refundable_price_value}
                                    onChange={(e, { value }) =>
                                        setGuest({ ...guest, refundable_price_value: value, refundable_percentage: '' })
                                    }
                                />
                            )
                        )
                    ) : (
                        ''
                    )}
                </>
            )}
            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={
                        isProcessing ||
                        isEmpty(guest.accommodation) ||
                        isEmpty(guest.from_date) ||
                        isEmpty(guest.to_date) ||
                        !isValidRange(guest.from_date, guest.to_date)
                    }
                    text={t('submit')}
                />
            </Form.Field>
        </Form>
    )
}

export default PersonsForm
