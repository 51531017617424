import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { tzDateTime } from '@helpers/dates'
import { requests } from '@helpers/requests'
// components
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { Segment } from 'semantic-ui-react'

const MonthViewGraph = ({ employee, dateFrom, dateTo }) => {
    const { t } = useTranslation()
    const [records, setRecords] = useState([])
    const [loading, setLoading] = useState(true)
    const colors = useSelector((state) => state.global_pref.colors)

    const [totalHours, setTotalHours] = useState(0)

    useEffect(() => {
        async function fetchRecords() {
            const request = await requests.get(
                API.ATTENDANCE +
                    '?employee=' +
                    employee.id +
                    '&date_from=' +
                    dateFrom.format('YYYY-MM-DD') +
                    '&date_to=' +
                    dateTo.format('YYYY-MM-DD')
            )

            if (request.status === 200) {
                const dates = getDates(dateFrom, dateTo)
                const fetchedRecords = request.response
                const tmpRecords = []
                let totalWorkHours = 0
                for (let i = 0; i < dates.length; i++) {
                    const day = dates[i]
                    tmpRecords.push({
                        name: moment(day).format('DD'),
                        Hours: fetchedRecords.find((item) => item.date === day)?.work_hours || 0, //calcWorkingHours(day, fetchedRecords)
                    })

                    totalWorkHours += parseFloat(fetchedRecords.find((item) => item.date === day)?.work_hours || 0)
                }

                setRecords(tmpRecords)
                setTotalHours(parseFloat(totalWorkHours).toFixed(2))
            }
            setLoading(false)
        }

        fetchRecords()
        // eslint-disable-next-line
    }, [])

    // !! TODO: move this logic to helpers so we can use it globaly
    const getDates = (from, to) => {
        let dates = []
        // add logic to calculate days between two date range
        for (let day = from; day.isBefore(to); day.add(1, 'days')) {
            dates.push(day.format('YYYY-MM-DD'))
        }

        return dates
    }

    // !! TODO: move this logic to helpers so we can use it globaly
    const calcDifference = (start_datetime, end_datetime, type) => {
        let value = 0
        const start = start_datetime ? tzDateTime(start_datetime) : null
        const end = end_datetime ? tzDateTime(end_datetime) : null
        const current_time = moment.utc().format('YYYY-MM-DD HH:mm:ss')
        const current_time_timezone = tzDateTime(current_time)

        if (start !== null && end !== null) {
            // calculate difference between start-end
            value = end.diff(start, type, true)
        }

        if (start !== null && end === null) {
            // calculate difference between start and current time
            value = current_time_timezone.diff(start, type, true)
        }

        return value
    }

    // eslint-disable-next-line
    const calcWorkingHours = (date, workingRecords) => {
        let hours = 0
        // add logic to calculate working hours from given attendance records for given date ()
        const record = workingRecords.find((item) => item.date === moment(date).format('YYYY-MM-DD'))
        if (record) {
            let interruption_time = 0
            hours = calcDifference(record.start, record.end, 'hours')
            let break_hours = calcDifference(record.start_pause, record.end_pause, 'hours')

            for (let i = 0; i < record.interruptions.length; i++) {
                interruption_time += calcDifference(record.interruptions[i].start, record.interruptions[i].end, 'hours')
            }

            hours = hours - break_hours - interruption_time
        }

        return hours.toFixed(2).replace('.00', '')
    }

    return (
        <Segment
            loading={loading}
            style={{
                padding: 0,
                background: 'transparent',
                boxShadow: 'none',
                border: 'none',
            }}
        >
            {loading && (
                <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '6rem' }}>
                    {t('loading_monthly_overview')}
                </p>
            )}
            {!loading && (
                <>
                    <div style={{ marginLeft: '1rem' }}>
                        <strong>{t('total_hours')}:</strong> {totalHours}
                    </div>
                    <ResponsiveContainer width="100%" height={250}>
                        <BarChart
                            data={records}
                            margin={{
                                top: 20,
                                right: 0,
                                left: -40,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="1 1" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="Hours" stackId="a" fill={colors.primary} />
                        </BarChart>
                    </ResponsiveContainer>
                </>
            )}
        </Segment>
    )
}

export default MonthViewGraph
