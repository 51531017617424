import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useSelector } from 'react-redux'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { tzDateTime } from '@helpers/dates'
// components
import Icon from '@components/Icon'
import UserAvatar from 'react-user-avatar'
import CanView from '@components/perms/CanView'
import Action from '@components/general/Action'
import SpinnerSegment from '@components/SpinnerSegment'
import ConfirmModal from '@components/modals/ConfrimModal'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import {
    Grid,
    Card,
    Header,
    Divider,
    Label,
    Button,
    Checkbox,
    Form,
    Icon as SemanticIcon,
    Input,
    Dropdown,
    Popup,
    Ref,
    Sidebar,
    Menu,
    Segment,
} from 'semantic-ui-react'
// specific components
import FilterForm from '../components/FilterForm'
import TaskForm from '../components/TaskForm'
import SortedFileRenderer from '@components/SortedFileRenderer'
import MKEditor from '@components/editors/MKEditor'

const BoardColumn = ({ priority, view, name, color, tasks, setTasks, focusMode, setFocusMode }) => {
    const { t } = useTranslation()

    let colors = useSelector((state) => state?.global_pref?.colors || [])

    const getColor = (color, alpha) => {
        if (colors.length === 0) return color

        let colorName = color.replace('--', '')
        colorName = colorName.replace('var(', '')
        colorName = colorName.replace(')', '')

        return colors[colorName] + alpha
    }

    return (
        <Grid.Column>
            <Header as="h4">
                <Label
                    basic
                    style={{
                        width: '100%',
                        background: getColor(color, '33'), // "var(--light)",
                        borderBottom: `3px solid ${color}`,
                        color: 'var(--dark)',
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                        margin: 0,
                        textTransform: 'uppercase',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <div style={{ textAlign: 'left' }}>
                            <span style={{ position: 'relative', top: '0.2rem' }}>{name}</span>{' '}
                            <Label basic circular content={tasks.length} />
                        </div>
                        <div style={{ textAlign: 'right', display: 'flex' }}>
                            <Popup
                                size="small"
                                position="left center"
                                trigger={
                                    <Icon
                                        style={{ cursor: 'pointer' }}
                                        name={priority === focusMode ? 'flash' : 'flash-outline'}
                                        onClick={() =>
                                            focusMode === priority ? setFocusMode(null) : setFocusMode(priority)
                                        }
                                    />
                                }
                                content={t('focus_mode')}
                            />
                        </div>
                    </div>
                </Label>
            </Header>
            <Divider />

            <Card.Group>
                {tasks.map((item) => (
                    <TaskCard key={item.id} view={view} task={item} setTasks={setTasks} />
                ))}
            </Card.Group>
        </Grid.Column>
    )
}

const TaskCard = ({ task, view, setTasks }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)
    const [processing, setProcessing] = useState(false)

    const onPriorityChange = async (priority) => {
        setProcessing(true)
        const request = await requests.patch(API.TASKS + task.id + '/', {
            priority: priority,
        })

        if (request.status === 200) {
            setTasks((prev) =>
                prev.filter((item) => {
                    if (item.id === task.id) {
                        item.priority = request.response.priority
                        item.priority_display = request.response.priority_display
                    }

                    return item
                })
            )
        }
        setProcessing(false)
    }

    const closeTask = async (task) => {
        setProcessing(true)
        const request = await requests.patch(API.TASKS + task.id + '/', { is_closed: true })
        if (request.status === 200) {
            setTasks((prev) => prev.filter((item) => item.id !== task.id))
        }
        setProcessing(false)
    }

    const onTaskDelete = async (task) => {
        setProcessing(true)
        const request = await requests.del(API.TASKS + task.id + '/')
        if (request.status === 204) {
            setTasks((prev) => prev.filter((item) => item.id !== task.id))
        }
        setProcessing(false)
    }

    const DueDate = () => {
        const getDays = (date) => {
            var m = moment(date)
            var today = moment().startOf('day')

            var days = Math.round(moment.duration(m - today).asDays())

            return days
        }

        return (
            <>
                {task.to_date
                    ? '  ·  ' +
                      (moment(task.to_date).format(dateFormat) +
                          (getDays(task.to_date) >= 0
                              ? ', ' + t('in') + ' ' + getDays(task.to_date) + ' ' + t('days')
                              : ', ' + t('before') + ' ' + Math.abs(getDays(task.to_date)) + ' ' + t('dayss')))
                    : ''}
            </>
        )
    }

    const ActionBar = () => {
        const { t } = useTranslation()

        return (
            <div style={{ textAlign: 'right' }}>
                {task.attachments.length > 0 && (
                    <Popup
                        size="small"
                        flowing
                        inverted
                        hoverable
                        position="top center"
                        content={task.attachments.length + ' ' + t('attachments')}
                        trigger={
                            <span
                                style={{
                                    float: 'left',
                                    fontSize: '0.9rem',
                                    marginTop: '0.1rem',
                                    marginRight: '0.5rem',
                                }}
                            >
                                <Icon name="document-outline" style={{ marginRight: '0.2rem' }} />
                                <span style={{ position: 'relative', top: '-0.1rem' }}>{task.attachments.length}</span>
                            </span>
                        }
                    />
                )}
                <CanView permissions={['tasks.c_manage_user_tasks', 'tasks.c_manage_tasks']}>
                    {task.is_closed !== true && (
                        <Action
                            as="confirm"
                            type="icon"
                            size="huge"
                            disabled={processing}
                            // iconColor="var(--dark)"
                            tooltip={t('close_task')}
                            icon="checkmark-done"
                            text={t('are_you_sure_to_close_this_task')}
                            onClick={() => closeTask(task)}
                        />
                    )}

                    <Dropdown
                        onClick={(e) => e.stopPropagation()}
                        onFocus={(e) => e.stopPropagation()}
                        style={{ position: 'relative', top: '-0.2rem' }}
                        simple
                        icon={<Icon name="ellipsis-vertical-sharp" />}
                        direction="left"
                    >
                        <Dropdown.Menu onClick={(e) => e.stopPropagation()} onFocus={(e) => e.stopPropagation()}>
                            <Dropdown
                                direction="left"
                                pointing="left center"
                                text={t('change_priority')}
                                className="link item priority-selection"
                                style={{ fontWeight: 'bold' }}
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        style={{ borderLeft: '2px solid var(--danger)' }}
                                        onClick={() => onPriorityChange(4)}
                                    >
                                        {t('urgent')}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        style={{ borderLeft: '2px solid var(--warning)' }}
                                        onClick={() => onPriorityChange(3)}
                                    >
                                        {t('high')}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        style={{ borderLeft: '2px solid var(--success)' }}
                                        onClick={() => onPriorityChange(2)}
                                    >
                                        {t('medium')}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        style={{ borderLeft: '2px solid var(--info)' }}
                                        onClick={() => onPriorityChange(1)}
                                    >
                                        {t('low')}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <SuperDuperModal
                                trigger={
                                    <Dropdown.Item
                                        onClick={(e) => e.stopPropagation()}
                                        onFocus={(e) => e.stopPropagation()}
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        <Icon name="pencil-outline" style={{ marginRight: '0.5rem' }} />
                                        <span style={{ position: 'relative', top: '-0.2rem' }}>{t('update')}</span>
                                    </Dropdown.Item>
                                }
                                content={<TaskForm view={view} task={task} setTasks={setTasks} />}
                            />
                            <ConfirmModal
                                description={t('are_you_sure_that_you_want_to_remove_this_task')}
                                onConfirm={() => onTaskDelete(task)}
                                button={
                                    <Dropdown.Item style={{ fontWeight: 'bold', color: 'var(--danger)' }}>
                                        <Icon name="close-outline" style={{ marginRight: '0.5rem' }} />
                                        <span style={{ position: 'relative', top: '-0.2rem' }}>{t('remove')}</span>
                                    </Dropdown.Item>
                                }
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                </CanView>
            </div>
        )
    }

    const UserInfo = ({ justifyContent }) => {
        justifyContent = justifyContent || 'center'

        return (
            <div style={{ textAlign: 'right', display: 'flex', alignItems: 'center', justifyContent: justifyContent }}>
                {task.created_by?.profile_id === task.assigned_to?.id ? (
                    <Popup
                        size="small"
                        position="left center"
                        content={
                            <div>
                                <strong>{task.created_by?.name || 'JD'}</strong> <br />
                                <small style={{ opacity: '0.8' }}>{t('responsible_person_and_owner')}</small>
                            </div>
                        }
                        trigger={
                            <div>
                                <UserAvatar
                                    style={{ color: 'white', paddingLeft: '0rem', fontSize: 25 / 2.5 + 'px' }}
                                    size={25}
                                    name={task.created_by?.name || 'JD'}
                                    // color={getRandomColor()}
                                    src={task.created_by?.profile_picture}
                                />
                            </div>
                        }
                    />
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: justifyContent }}>
                        <Popup
                            size="small"
                            position="left center"
                            content={
                                <div>
                                    <strong>{task.created_by?.name || 'JD'}</strong> <br />
                                    <small style={{ opacity: '0.8' }}>{t('owner')}</small>
                                </div>
                            }
                            trigger={
                                <div>
                                    <UserAvatar
                                        style={{ color: 'white', paddingLeft: '0rem', fontSize: 25 / 2.5 + 'px' }}
                                        size={25}
                                        name={task.created_by?.name || 'JD'}
                                        // color={getRandomColor()}
                                        src={task.created_by?.profile_picture}
                                    />
                                </div>
                            }
                        />
                        <Icon name="chevron-forward" />
                        <Popup
                            size="small"
                            position="left center"
                            content={
                                <div>
                                    <strong>{task.assigned_to?.fullname_with_titles || 'JD'}</strong> <br />
                                    <small style={{ opacity: '0.8' }}>{t('responsible_person')}</small>
                                </div>
                            }
                            trigger={
                                <div>
                                    <UserAvatar
                                        style={{ color: 'white', paddingLeft: '0rem', fontSize: 25 / 2.5 + 'px' }}
                                        size={25}
                                        name={task.assigned_to?.fullname_with_titles || 'JD'}
                                        // color={getRandomColor()}
                                        src={task.assigned_to?.profile_picture}
                                    />
                                </div>
                            }
                        />
                    </div>
                )}
            </div>
        )
    }

    const headerLayoutStyles = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    }

    return (
        <Card fluid>
            <Card.Content style={{ background: 'var(--light)' }}>
                {task.task_type && (
                    <div style={headerLayoutStyles}>
                        <div>
                            <Label
                                size="tiny"
                                style={{
                                    background:
                                        task.task_type.color === '' ? 'var(--dark)' : task.task_type.color + 'B3',
                                    marginBottom: '0.5rem',
                                    color: 'white',
                                }}
                            >
                                {task.task_type.title}
                            </Label>
                        </div>
                        <ActionBar />
                    </div>
                )}
                <div style={task.task_type ? {} : headerLayoutStyles}>
                    <SuperDuperModal
                        size="tiny"
                        trigger={
                            <span
                                className="ref-link"
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '1.2rem',
                                    maxWidth: '80%',
                                    marginBottom: '0.5rem',
                                }}
                            >
                                {task.title}
                            </span>
                        }
                        content={
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'top',
                                    }}
                                >
                                    <div>
                                        <Header
                                            as="h3"
                                            content={task.title}
                                            style={{ marginBottom: '0.5rem', marginTop: 0 }}
                                        />
                                        <small style={{ opacity: 0.8 }}>
                                            #{task.id}{' '}
                                            {` · ${tzDateTime(task.created_on).format(dateFormat + ' HH:mm')}`}
                                            <Popup
                                                size="small"
                                                position="left center"
                                                trigger={
                                                    <strong>
                                                        {task.is_closed ? (
                                                            <strong>{` · ${tzDateTime(task.close_date).format(
                                                                dateFormat + ' HH:mm'
                                                            )}`}</strong>
                                                        ) : (
                                                            <DueDate />
                                                        )}
                                                    </strong>
                                                }
                                                content={task.is_closed ? t('close_date') : t('to_date')}
                                            />
                                        </small>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'right' }}>
                                        <span style={{ marginBottom: '0.5rem' }}>
                                            {task.task_type && (
                                                <Label
                                                    size="tiny"
                                                    style={{
                                                        background:
                                                            task.task_type.color === ''
                                                                ? 'var(--dark)'
                                                                : task.task_type.color + 'B3',
                                                        marginBottom: '0.5rem',
                                                        color: 'white',
                                                    }}
                                                >
                                                    {task.task_type.title}
                                                </Label>
                                            )}
                                        </span>
                                        <UserInfo justifyContent="right" />
                                    </div>
                                </div>
                                <Divider />
                                <MKEditor readOnly={true} markdown={task?.description} />
                                {task?.attachments?.length > 0 &&
                                    task?.attachments?.map((attachment) => (
                                        <div
                                            key={attachment.id}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'middle',
                                                justifyContent: 'space-between',
                                                marginTop: '1rem',
                                            }}
                                        >
                                            <SortedFileRenderer name={attachment.name} file={attachment.file} />
                                        </div>
                                    ))}
                            </div>
                        }
                    />
                    {task.task_type ? '' : <ActionBar />}
                </div>
                <Card.Meta style={{ ...headerLayoutStyles, marginTop: '0.2rem' }}>
                    <small>
                        #{task.id}
                        {` · ${tzDateTime(task.created_on).format(dateFormat + ' HH:mm')}`}
                        {task.to_date && (
                            <Popup
                                size="small"
                                position="left center"
                                trigger={
                                    <strong>
                                        {task.is_closed ? (
                                            <strong>{` · ${tzDateTime(task.close_date).format(
                                                dateFormat + ' HH:mm'
                                            )}`}</strong>
                                        ) : (
                                            <DueDate />
                                        )}
                                    </strong>
                                }
                                content={task.is_closed ? t('close_date') : t('to_date')}
                            />
                        )}
                    </small>
                    <UserInfo />
                </Card.Meta>
            </Card.Content>
        </Card>
    )
}

const TasksBoard = () => {
    const { t } = useTranslation()
    const target = useRef()
    const today = moment().format('YYYY-MM-DD')
    const user = useSelector((state) => state.user)

    const [loading, setLoading] = useState(false)
    const [tasks, setTasks] = useState([])

    const [focusMode, setFocusMode] = useState(null)
    const [visible, setVisible] = useState(false)
    const [view, setView] = useState('today')
    const [showClosed, setShowClosed] = useState(false)
    const [searching, setSearching] = useState(false)
    const [search, setSearch] = useState('')

    const onSearch = async (search) => {
        await fetchTasks(search)
    }

    const [filters, setFilters] = useState({
        date_from: today,
        date_to: today,
        task_type: '',
    })

    async function fetchTasks(search) {
        setLoading(true)
        if (search !== '') setSearching(true)

        let additionalFilters = ''
        if (search !== '') additionalFilters += `&search=${search}`
        if (filters.date_from !== '') additionalFilters += `&date_from=${filters.date_from}`
        if (filters.date_to !== '') additionalFilters += `&date_to=${filters.date_to}`
        if (filters.task_type !== '') additionalFilters += `&task_type=${filters.task_type}`

        if (view === 'today') {
            additionalFilters += '&assigned_to_current_user=true'
        } else if (view === 'assigned') {
            additionalFilters += '&owned_by_current_user=true&assigned_to_current_user=false'
        } else {
            additionalFilters += '&current_user_tasks=true'
        }

        const assigned_to = user.profile?.id
        if (assigned_to) {
            const request = await requests.get(
                API.TASKS + `?ordering=-priority&ordering=-created_on&is_closed=${showClosed}${additionalFilters}`
            )
            if (request.status === 200) setTasks(request.response)
        }

        setSearching(false)
        setLoading(false)
    }

    useEffect(() => {
        if (!loading) {
            fetchTasks('')
        }
        // eslint-disable-next-line
    }, [view, showClosed])

    return (
        <Ref innerRef={target}>
            <Sidebar.Pushable
                as={Segment}
                className="fixed-sidebar"
                style={{ border: 'none', background: 'transparent', boxShadow: 'none', padding: 0, margin: 0 }}
            >
                <Sidebar
                    direction="right"
                    style={{ width: '40rem', textAlign: 'left' }}
                    as={Menu}
                    animation="overlay"
                    icon="labeled"
                    vertical
                    visible={visible}
                    onHide={() => setVisible(false)}
                    target={target}
                >
                    <FilterForm
                        view={view}
                        setVisible={setVisible}
                        filters={filters}
                        setFilters={setFilters}
                        fetchTasks={async () => await fetchTasks(search)}
                        setView={setView}
                    />
                </Sidebar>
                <Grid stackable>
                    <Grid.Row verticalAlign="middle" columns={2} style={{ paddingBottom: 0 }}>
                        <Grid.Column style={{ textAlign: 'left' }}>
                            <Button.Group size="small" basic className="custom-filter-button-group">
                                <Button
                                    active={view === 'today'}
                                    onClick={() => {
                                        setView('today')
                                        setFilters((prev) => ({
                                            ...prev,
                                            date_from: today,
                                            date_to: today,
                                        }))
                                    }}
                                >
                                    {t('my_tasks_for_today')}
                                </Button>
                                <Button
                                    active={view === 'assigned'}
                                    onClick={() => {
                                        setView('assigned')
                                        setFilters((prev) => ({
                                            ...prev,
                                            date_from: '',
                                            date_to: '',
                                            type: '',
                                        }))
                                    }}
                                >
                                    {t('assigned_tasks')}
                                </Button>
                                <Button
                                    active={view === 'all'}
                                    onClick={() => {
                                        setView('all')
                                        setFilters((prev) => ({
                                            ...prev,
                                            date_from: '',
                                            date_to: '',
                                            type: '',
                                        }))
                                    }}
                                >
                                    {t('all_tasks')}
                                </Button>
                            </Button.Group>
                            <Checkbox
                                checked={showClosed}
                                onClick={() => setShowClosed(!showClosed)}
                                style={{ marginLeft: '1rem' }}
                                label={t('show_closed_tasks')}
                            />
                        </Grid.Column>
                        <Grid.Column style={{ textAlign: 'right' }}>
                            <div style={{ display: 'flex', float: 'right' }}>
                                <Form onSubmit={(e) => onSearch(search)} style={{ width: 'auto' }}>
                                    <Input
                                        style={{ position: 'relative', top: '0.5rem' }}
                                        loading={searching}
                                        size="small"
                                        icon={<SemanticIcon link name="search" onClick={() => onSearch(search)} />}
                                        placeholder="Search..."
                                        value={search}
                                        onChange={(e, { value }) => setSearch(value)}
                                    />
                                </Form>
                                <Action
                                    as="custom"
                                    type="icon"
                                    tooltip={t('filter')}
                                    iconColor="black"
                                    size="huge"
                                    icon="funnel-outline"
                                    paddingLeft="1rem"
                                    onClick={() => setVisible(!visible)}
                                />
                                <CanView permssions={['tasks.c_manage_user_tasks', 'tasks.c_manage_tasks']}>
                                    <Action
                                        as="modal"
                                        centered={false}
                                        type="icon"
                                        tooltip={t('add')}
                                        size="huge"
                                        modalSize={'tiny'}
                                        iconColor="var(--dark)"
                                        header={t('add_task')}
                                        icon="add-outline"
                                        modal={<TaskForm setTasks={setTasks} />}
                                    />
                                </CanView>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <SpinnerSegment loading={loading}>
                    <Grid stackable style={{ marginBottom: '10rem' }}>
                        <Grid.Row columns={4}>
                            <BoardColumn
                                view={view}
                                priority={4}
                                focusMode={focusMode}
                                name={t('urgent')}
                                setTasks={setTasks}
                                color="var(--danger)"
                                setFocusMode={setFocusMode}
                                tasks={tasks.filter(
                                    (item) => item.priority === 4 && (focusMode === item.priority || focusMode === null)
                                )}
                            />
                            <BoardColumn
                                view={view}
                                priority={3}
                                name={t('high')}
                                focusMode={focusMode}
                                setTasks={setTasks}
                                color="var(--warning)"
                                setFocusMode={setFocusMode}
                                tasks={tasks.filter(
                                    (item) => item.priority === 3 && (focusMode === item.priority || focusMode === null)
                                )}
                            />
                            <BoardColumn
                                view={view}
                                priority={2}
                                focusMode={focusMode}
                                name={t('medium')}
                                setTasks={setTasks}
                                color="var(--success)"
                                setFocusMode={setFocusMode}
                                tasks={tasks.filter(
                                    (item) => item.priority === 2 && (focusMode === item.priority || focusMode === null)
                                )}
                            />
                            <BoardColumn
                                view={view}
                                priority={1}
                                name={t('low')}
                                focusMode={focusMode}
                                color="var(--info)"
                                setTasks={setTasks}
                                setFocusMode={setFocusMode}
                                tasks={tasks.filter(
                                    (item) => item.priority === 1 && (focusMode === item.priority || focusMode === null)
                                )}
                            />
                        </Grid.Row>
                    </Grid>
                </SpinnerSegment>
            </Sidebar.Pushable>
        </Ref>
    )
}

export default TasksBoard
