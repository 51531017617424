import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import CanView from '@components/perms/CanView'
import { Form, Divider, Header } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import MKEditor from '@components/editors/MKEditor'

const TaskForm = ({ cases, handleRequest, setData, onClose }) => {
    const { t } = useTranslation()

    const user = useSelector((state) => state.user.id)
    const today = moment().format('YYYY-MM-DD')

    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState(null)
    const [project, setProject] = useState(null)
    const [form, setForm] = useState({
        title: '',
        name: '',
        description: '',
        priority: cases?.priority || 1,
        assigned_to: cases?.assigned_to?.id || null,
        to_date: '',
        task_date: today,
        is_closed: false,
        case: cases?.id,
        attachments: cases?.attachments ? { add: cases.attachments.map((item) => item.id) } : [],
    })

    const handleSubmit = async () => {
        setProcessing(true)
        setErrors(null)

        let data = form
        if (form.to_date === '') data['to_date'] = null
        if (form.task_date === '') data['task_date'] = today

        if (!project) {
            const request = await requests.post(API.TASKS, data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('task_created'),
                    animation: 'pulse',
                    time: 2000,
                })
                if (handleRequest) handleRequest(request.response, setData)
                onClose()
            }
        } else {
            data['project'] = project
            data['name'] = form.title
            data['assigned'] = { add: form.assigned_to }
            const request = await requests.post(API.PROJECT_ISSUES, data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('project_issue_created'),
                    animation: 'pulse',
                    time: 2000,
                })
                if (handleRequest) handleRequest(request.response, setData)
                onClose()
            }
        }

        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Header as="h3" content={t('make_a_task')} />
            <Divider />
            <SuperField
                as="input"
                autoFocus
                required
                label={t('name')}
                error={errors?.title?.[0] || false}
                value={form.title}
                onChange={(e, { value }) => setForm((prev) => ({ ...prev, title: value }))}
            />
            <SuperField
                as="choice-select"
                search
                text="name"
                label={t('project')}
                value={project}
                endpoint={API.PROJECTS}
                additionalFilters="&query={id, name}"
                onChange={(e, { value }) => setProject(value)}
            />

            <MKEditor
                label={t('description')}
                markdown={form.description}
                onChange={(value) => setForm((prev) => ({ ...prev, description: value }))}
            />

            <Form.Group widths="equal" style={{ marginTop: '1rem' }}>
                {!project && (
                    <SuperField
                        as="datepicker"
                        required
                        label={t('task_date')}
                        value={form.task_date}
                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, task_date: value }))}
                    />
                )}
                <SuperField
                    as="datepicker"
                    label={t('to_date')}
                    value={form.to_date}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, to_date: value }))}
                />
            </Form.Group>

            <Form.Group widths="equal">
                {!project && (
                    <SuperField
                        as="choice-select"
                        search
                        text="title"
                        label={t('type')}
                        value={form.task_type}
                        endpoint={API.TASKS + 'task_type/'}
                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, task_type: value }))}
                    />
                )}
                <CanView permissions={['tasks.c_manage_tasks']}>
                    <Form.Field>
                        <SuperField
                            as="choice-select"
                            search
                            key={project}
                            multiple={project}
                            text="fullname_with_titles"
                            label={t('assigned_to')}
                            value={form.assigned_to}
                            error={errors?.assigned_to?.[0] || false}
                            endpoint={API.EMPLOYEES}
                            additionalFilters={
                                project === undefined || project === null || project === ''
                                    ? '&only_basic_info=true&has_user=true&is_active=true'
                                    : '&project=' + project + '&only_basic_info=true&has_user=true&is_active=true'
                            }
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, assigned_to: value }))}
                        />
                    </Form.Field>
                </CanView>
            </Form.Group>
            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    disabled={processing || form.title === '' || form.task_date === ''}
                    loading={processing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    )
}

export default TaskForm
