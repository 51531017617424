import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { routes } from '@routes'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { useHasPermission, useHasEveryPermissions } from '@helpers/hooks'
// components
import Icon from '@components/Icon'
import ListView from '@components/ListView'
import Attachments from '@components/Attachments'
import SuperField from '@components/forms/SuperField'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { Form, Label, Popup } from 'semantic-ui-react'
import OrderDetail from '../../../sm/orders/shared/OrderDetail'
import PersonsForm from '../components/PersonsForm'
import DatePresets from '@components/DatePresets'
import ShortenText from '@components/ShortenText'

const AccommodatedPersons = () => {
    const { t } = useTranslation()

    const [attachmentsOpen, setAttachmentsOpen] = useState(0)
    const canManage = useHasPermission('accommodations.c_manage_accommodation')
    const canViewOrder = useHasEveryPermissions(['orders.c_view_order'])

    const dateFormat = useSelector((state) => state.date_format)

    const getDiff = (from, to) => {
        const dateFrom = moment(from, 'YYYY-MM-DD')
        const dateTo = moment(to, 'YYYY-MM-DD')

        if (!dateFrom.isValid() || !dateTo.isValid()) {
            return 0
        }

        return dateTo.diff(dateFrom, 'days')
    }

    return (
        <ListView
            as="table"
            allowSearch
            isSoftDeleteAllowed
            allowSelection
            allowRowExport
            actionsCellWidth="2"
            endpoint={API.ACCOMMODATIONS + 'guests/'}
            initialFilters={{
                accommodation: '',
                account: '',
                contract: '',
                date_from: '',
                date_to: '',
                employer: '',
                is_foreigner: '',
                is_self_paying: '',
                is_archived: '',
                order: '',
                profile: '',
                purpose: '',
                room: '',
                unit: '',
            }}
            exports={[
                {
                    endpoint: API.EXPORTS + 'dynamic_guests/',
                    permissions: ['accommodations.c_view_accommodation'],
                    apply_filters: true,
                    type: 'xlsx',
                    filename: 'accommodation_guests',
                },
            ]}
            renderFilterFields={(filters, setFilters) => (
                <>
                    <Form.Group widths="equal">
                        <SuperField
                            search
                            as="datepicker"
                            label={t('date_from')}
                            value={filters.date_from}
                            onChange={(e, { value }) => {
                                setFilters((prev) => ({ ...prev, date_from: value }))
                            }}
                        />
                        <SuperField
                            search
                            as="datepicker"
                            label={t('date_to')}
                            value={filters.date_to}
                            onChange={(e, { value }) => {
                                setFilters((prev) => ({ ...prev, date_to: value }))
                            }}
                        />
                    </Form.Group>

                    <DatePresets
                        open={true}
                        from={filters.date_from}
                        to={filters.date_to}
                        onSelection={(firstMonthDay, lastMonthDay, year) => {
                            setFilters((prev) => ({
                                ...prev,
                                date_from: firstMonthDay,
                                date_to: lastMonthDay,
                                year: year,
                            }))
                        }}
                    />
                    <Form.Group widths={'equal'}>
                        <SuperField
                            search
                            as="choice-select"
                            label={t('hostels')}
                            endpoint={API.ACCOMMODATIONS}
                            additionalFilters="&query={id, name}"
                            text="name"
                            value={filters.accommodation}
                            onChange={(e, { value }) => setFilters({ ...filters, accommodation: value })}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <SuperField
                            as="choice-select"
                            search
                            label={t('employee')}
                            endpoint={API.EMPLOYEES}
                            additionalFilters="&only_basic_info=true"
                            text="fullname"
                            value={filters.profile}
                            onChange={(e, { value }) =>
                                setFilters({
                                    ...filters,
                                    profile: value,
                                })
                            }
                        />
                        <SuperField
                            as="choice-select"
                            search
                            label={t('candidate')}
                            endpoint={API.CANDIDATES}
                            additionalFilters="&only_basic_info=true"
                            text="fullname_with_titles"
                            value={filters.profile}
                            onChange={(e, { value }) =>
                                setFilters({
                                    ...filters,
                                    profile: value,
                                })
                            }
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <SuperField
                            as="choice-select"
                            search
                            label={t('contract')}
                            value={filters.contract}
                            text={(item) =>
                                item?.employee?.fullname_with_titles +
                                ' - ' +
                                (item.employer?.name || '') +
                                (item.work_position ? ' (' + item.work_position.title + ')' : '')
                            }
                            endpoint={API.CONTRACTS}
                            additionalFilters="&only_basic_info=true"
                            onChange={(e, { value }) => {
                                setFilters({ ...filters, contract: value })
                            }}
                        />
                        <SuperField
                            as="choice-select"
                            search
                            value={filters.order}
                            endpoint={API.ORDERS}
                            label={t('order')}
                            onChange={(e, { value }) => setFilters({ ...filters, order: value })}
                            text={(item) => `${item.name}`}
                            additionalFilters={`&only_basic_info=true&is_active=true`}
                        />
                    </Form.Group>
                    {/* <SuperField
                        as="choice-select"
                        search
                        type="purpose_choices"
                        label={t('purpose')}
                        value={filters.purpose}
                        onChange={(e, { value }) =>
                            setFilters({
                                ...filters,
                                purpose: value,
                            })
                        }
                    /> */}

                    <SuperField
                        as="checkbox"
                        label={t('archived')}
                        checked={filters.is_archived}
                        onChange={() => setFilters({ ...filters, is_archived: !filters.is_archived })}
                    />
                    <SuperField
                        as="checkbox"
                        label={t('is_self_paying')}
                        checked={filters.is_self_paying}
                        onChange={() => setFilters({ ...filters, is_self_paying: !filters.is_self_paying })}
                    />
                    <SuperField
                        as="checkbox"
                        label={t('is_foreigner')}
                        checked={filters.is_foreigner}
                        onChange={() => setFilters({ ...filters, is_foreigner: !filters.is_foreigner })}
                    />
                </>
            )}
            actions={[
                {
                    as: 'modal',
                    type: 'add',
                    name: t('create_an_accommodation_record'),
                    modal: <PersonsForm isCreate={true} />,
                    permissions: ['accommodations.c_manage_accommodation'],
                },
                {
                    as: 'modal',
                    type: 'edit',
                    name: t('edit_an_accommodation_record'),
                    modalSize: 'small',
                    permissions: ['accommodations.c_manage_accommodation'],
                    modal: (item, setData, setTotal) => (
                        <PersonsForm data={item} setData={setData} setTotal={setTotal} />
                    ),
                },
                {
                    as: 'confirm',
                    type: 'custom',
                    permissions: ['accommodations.c_manage_accommodation'],
                    name: (item) => (item.is_archived === false ? t('archive') : t('unarchive_item')),
                    icon: (item) => (item.is_archived === false ? 'archive-outline' : 'checkmark-circle-outline'),
                    text: (item) =>
                        item.is_archived === false ? t('archive') : t('are_you_sure_that_you_want_to_unarchive'),
                    onClick: async (item, setData, setTotal) => {
                        let data = {
                            is_archived: !item.is_archived,
                        }
                        const request = await requests.patch(API.ACCOMMODATIONS + 'guests/' + item.id + '/', data)

                        if (request.status === 200) {
                            setTotal((prev) => prev - 1)
                            setData((prev) => prev.filter((data) => data.id !== item.id))
                        }
                    },
                },
                {
                    name: t('delete'),
                    type: 'delete',
                    as: 'delete',
                    text: t('are_you_sure_that_you_want_to_remove_this_guest'),
                    permissions: ['accommodations.c_delete_accommodation'],
                },
            ]}
            listAdditionActions={() => [
                {
                    as: 'filter',
                    index: 0,
                    name: t('active'),
                    filters: {
                        is_archived: false,
                    },
                },
                {
                    as: 'filter',
                    index: 1,
                    name: t('archived'),
                    filters: {
                        is_archived: true,
                    },
                },
                {
                    as: 'filter',
                    index: 2,
                    name: t('all'),
                    filters: {
                        is_archived: null,
                    },
                },
            ]}
            tableHeaders={[
                { title: t('fullname') },
                { title: t('hostel') },
                { title: t('room') },
                { title: t('validity_period') },
                { title: t('is_self_paying') },
                { title: t('price_per_night') },
                { title: t('total_price') },
                { title: t('tax') },
                { title: t('price_excluding_tax') },
                { title: t('order') },
                { title: t('attachments') },
                { title: t('status') },
            ]}
            renderCells={(guest, setData) => [
                {
                    content: guest.profile ? (
                        <Link
                            className="ref-link"
                            to={
                                guest.profile.profile_type === 1
                                    ? routes.CANDIDATES_CARD + guest.profile.id
                                    : routes.EMPLYOEES_DETAIL + guest.profile.id
                            }
                            target="_blank"
                        >
                            <ShortenText text={guest.profile?.fullname} />
                        </Link>
                    ) : (
                        <ShortenText text={(guest.firstname || '--') + ' ' + guest.lastname} />
                    ),
                },
                {
                    content: (
                        <Link className="ref-link" to={routes.ACCOMMODATIONS + guest.accommodation.id} target="_blank">
                            <ShortenText text={guest.accommodation.name} />
                        </Link>
                    ),
                },
                {
                    content: <>{guest.room?.number || ''}</>,
                },
                {
                    content: (
                        <>
                            <span>
                                {moment(guest.from_date).format(dateFormat)} -{' '}
                                {moment(guest.to_date).format(dateFormat)}
                            </span>
                            <br />
                            <span style={{ color: 'gray' }}>
                                {'(' +
                                    getDiff(guest.from_date, guest.to_date) +
                                    ' ' +
                                    (getDiff(guest.from_date, guest.to_date) === 1
                                        ? t('night_accommodation')
                                        : t('nights')) +
                                    ')'}
                            </span>
                        </>
                    ),
                },
                {
                    content: <>{guest.is_self_paying === true ? t('yes') : t('no')}</>,
                },
                {
                    content: (
                        <>
                            {guest.price_per_night_per_person} {guest.accommodation.currency}
                        </>
                    ),
                },
                {
                    content: (
                        <>
                            {guest.total_price} {guest.accommodation.currency}
                        </>
                    ),
                },
                {
                    content: (
                        <>
                            {guest.accommodation_tax} {guest.accommodation.currency}
                        </>
                    ),
                },
                {
                    content: (
                        <>
                            {parseFloat(
                                parseFloat(guest.total_price || 0) - parseFloat(guest.accommodation_tax)
                            ).toFixed(2)}{' '}
                            {guest.accommodation.currency}
                        </>
                    ),
                },
                {
                    content: guest?.order?.name ? (
                        canViewOrder ? (
                            <SuperDuperModal
                                size="large"
                                trigger={
                                    <span className="ref-link" style={{ color: 'var(--primary)' }}>
                                        <ShortenText text={guest?.order?.name} />
                                    </span>
                                }
                                content={<OrderDetail id={guest?.order?.id} />}
                            />
                        ) : (
                            <span>
                                <ShortenText text={guest?.order?.name} />
                            </span>
                        )
                    ) : (
                        '--'
                    ),
                },
                {
                    content: (
                        <Label basic>
                            <Popup
                                style={{ zIndex: '1000', minWidth: '450px' }}
                                open={attachmentsOpen === guest.id}
                                onOpen={() => setAttachmentsOpen(guest.id)}
                                hoverable
                                position="left center"
                                trigger={
                                    <div style={{ cursor: 'pointer' }}>
                                        <Icon name="reader-outline" style={{ marginRight: '0.5rem' }} />
                                        <span style={{ position: 'relative', top: '-0.1rem' }}>
                                            {guest.attachments.length}
                                        </span>
                                    </div>
                                }
                                content={
                                    <Attachments
                                        viewOnly={!canManage}
                                        record={guest}
                                        moduleName="guest"
                                        setData={setData}
                                        sourceEndpoint={API.ACCOMMODATIONS + 'guests/'}
                                        closeView={() => setAttachmentsOpen(0)}
                                    />
                                }
                            />
                        </Label>
                    ),
                },
                {
                    content: (
                        <div style={{ paddingLeft: 0 }}>
                            <Icon
                                name="ellipse"
                                style={{
                                    color: !guest.is_archived ? 'var(--success)' : 'var(--danger)',
                                    marginRight: '0.5rem',
                                }}
                            />
                            <span style={{ position: 'relative', top: '-0.1rem' }}>
                                {' '}
                                {!guest.is_archived ? t('active') : t('archived')}{' '}
                            </span>
                        </div>
                    ),
                },
            ]}
        />
    )
}

export default AccommodatedPersons
