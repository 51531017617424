import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import '@store/languages'
// store
import { tzDateTime } from '@helpers/dates'
// components
import { Segment, List, Divider, Form, Icon as SemanticIcon } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'

const HistoryOfRecord = ({ record }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)
    const [historyRecord, setHistoryRecord] = useState(record?.data?.history)
    const [isAscending, setIsAscending] = useState(true)

    const [people, setPeople] = useState([])
    const [selected, setSelected] = useState('')

    const getField = (field) => {
        if (field === 'start') return t('arrival_record')
        if (field === 'end') return t('departure_record')
        if (field === 'pause_start') return t('pause_start_record')
        if (field === 'pause_end') return t('pause_end_record')
        if (field === 'second_pause_start') return t('second_pause_start_record')
        if (field === 'second_pause_end') return t('second_pause_end_record')
    }

    const getHeader = (item) => {
        return (
            <>
                <span style={{ fontWeight: 'normal' }}>{t('change_of') + ' '}</span>
                <b>{getField(item.field)}</b>
                <span style={{ fontWeight: 'normal' }}>{' ' + t('from') + ' '}</span>
                <b>{tzDateTime(item.old_value).format(dateFormat + ' HH:mm ')}</b>
                <span style={{ fontWeight: 'normal' }}>{t('to_record') + ' '}</span>
                <b>{tzDateTime(item.new_value).format(dateFormat + ' HH:mm ')}</b>
            </>
        )
    }

    useEffect(() => {
        const getPeople = () => {
            if (historyRecord.length > 0) {
                const uniquePeople = historyRecord
                    .map((item, index) => ({
                        key: index,
                        text: item.updated_by,
                        value: item.updated_by,
                    }))
                    .filter((person, index, self) => index === self.findIndex((p) => p.value === person.value))

                setPeople(uniquePeople)
            }
        }
        getPeople()
    }, [])

    const onSort = async () => {
        const sortedData = [...historyRecord].sort((a, b) => {
            const dateA = new Date(a.updated_at)
            const dateB = new Date(b.updated_at)

            return isAscending ? dateA - dateB : dateB - dateA
        })

        setHistoryRecord(sortedData)
        setIsAscending(!isAscending)
    }

    const onFilter = async (selectedPerson) => {
        if (selectedPerson) {
            const filtered = record?.data?.history?.filter((item) => item.updated_by === selectedPerson)

            setHistoryRecord(filtered)
        }
    }

    return (
        <Segment
            style={{
                padding: 0,
                background: 'transparent',
                boxShadow: 'none',
                border: 'none',
                marginBottom: '1rem',
                paddingTop: 0,
            }}
        >
            <Form>
                <Form.Group widths="equal">
                    <SuperField
                        as="choice"
                        search
                        label={t('updated_by')}
                        value={selected}
                        customOptions={people}
                        onChange={(e, { value }) => {
                            setSelected(value)
                            onFilter(value)
                        }}
                    />
                    <Form.Field style={{ textAlign: 'right' }}>
                        <SemanticIcon
                            name={`sort amount ${isAscending ? 'up' : 'down'}`}
                            style={{
                                color: 'var(--primary)',
                                fontSize: '1.2rem',
                                paddingTop: '2rem',
                                cursor: 'pointer',
                            }}
                            onClick={() => onSort()}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
            <Divider />
            <List relaxed>
                <List.Item
                    style={{
                        backgroundColor: 'var(--light)',
                        borderRight: `10px solid var(--border)`,
                        padding: '0.5rem',
                        borderRadius: '5px',
                        marginBottom: '0.3rem',
                    }}
                >
                    <List.Content>
                        <List.Header style={{ color: 'var(--primary)' }}>
                            <span>{record?.data?.created_by?.name}</span>{' '}
                        </List.Header>
                        <List.Description style={{ paddingTop: '0.3rem' }}>
                            {t('record_was_created') + ' '}
                            <b>{tzDateTime(record?.data?.created_on).format(dateFormat + ' HH:mm')}</b>
                        </List.Description>
                    </List.Content>
                </List.Item>
                <Divider />
                {historyRecord?.map((item, index) => (
                    <List.Item
                        key={index}
                        style={{
                            backgroundColor: 'var(--light)',
                            borderRight: `10px solid var(--border)`,
                            padding: '0.5rem',
                            borderRadius: '5px',
                            marginBottom: '0.3rem',
                        }}
                    >
                        <List.Content>
                            <List.Header style={{ color: 'var(--primary)' }}>
                                <span>
                                    {item.updated_by}{' '}
                                    <small style={{ color: 'gray', paddingLeft: '0.6rem' }}>
                                        {tzDateTime(item.updated_at).format(dateFormat + ' HH:mm')}
                                    </small>
                                </span>{' '}
                            </List.Header>
                            <List.Description style={{ paddingTop: '0.3rem' }}>{getHeader(item)}</List.Description>
                        </List.Content>
                    </List.Item>
                ))}
            </List>
        </Segment>
    )
}

export default HistoryOfRecord
