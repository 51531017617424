import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import SuperField from '@components/forms/SuperField'
import NonFieldErrors from '@components/NonFieldErrors'
import { Divider, Form, Header } from 'semantic-ui-react'
import MKEditor from '@components/editors/MKEditor'
import LabelInput from './input/LabelInput'

const CaseForm = ({ data, setData, setTotal, handleRequest, onClose, setHeader }) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [subject, setSubject] = useState(1)
    const [form, setForm] = useState({
        id: data?.id || '',
        title: data?.title ? data.title : '',
        description: data?.description ? data.description : '',
        priority: data?.priority ? data.priority : '',
        type: data?.type?.id ? data.type?.id : null,
        label: data?.label ? data.label : [],
        business_detail: data?.business_detail ? data?.business_detail.id : '',
        profile: data?.profile ? data?.profile.id : '',
        unit: data?.unit ? data?.unit?.id : '',
        assigned_to: data?.assigned_to ? data.assigned_to.map((item) => item.id) : [],
        due_date: data?.due_date ? moment(data.due_date).format('YYYY-MM-DD') : '',
        status: data?.status ? data.status : '',
    })

    useEffect(() => {
        if (data?.id) {
            setHeader(t('update_case'))

            if (data?.business_detail?.is_account === true) setSubject(1)
            if (data?.business_detail?.is_supplier === true) setSubject(2)
            if (data?.profile?.profile_type === 1) setSubject(4)
            if (data?.profile?.profile_type === 2) setSubject(3)
        }
    }, [data])

    async function handleSubmit() {
        setLoading(true)

        if (!form.id || form.id === 0) {
            let new_data = {
                title: form.title,
                type: form.type,
            }
            if (form.business_detail !== '') new_data = { ...new_data, business_detail: form.business_detail }
            if (form.profile !== '') new_data = { ...new_data, profile: form.profile }
            if (form.unit !== '') new_data = { ...new_data, unit: form.unit }
            if (form.assigned_to.length > 0) new_data = { ...new_data, assigned_to: { add: form.assigned_to } }
            if (form.label.length > 0) new_data = { ...new_data, label: { add: form.label } }
            if (form.priority !== '') new_data = { ...new_data, priority: form.priority }
            if (form.description !== '') new_data = { ...new_data, description: form.description }
            if (form.due_date !== '') new_data = { ...new_data, due_date: form.due_date + 'T23:59' }
            if (form.assigned_to.length > 0) {
                new_data = { ...new_data, status: 2 }
            } else {
                new_data = { ...new_data, status: 1 }
            }

            let request = await requests.post(API.CASES, new_data)
            if (request.status === 201) {
                handleRequest(request.response, setData, setTotal)
                onClose()
            } else {
                setError(request.response)
            }
        } else {
            let new_data = {
                title: form.title,
                type: form.type,
                priority: form.priority,
                description: form.description,
                status: form.status,
                //business_detail: form.business_detail,
                //profile: form.profile,
                unit: form.unit,
            }

            if (subject === 1 || subject === 2) {
                // account or supplier
                new_data['business_detail'] = form.business_detail || null
                new_data['profile'] = null
            } else if (subject === 3 || subject === 4) {
                // candidate or employee
                new_data['business_detail'] = null
                new_data['profile'] = form.profile || null
            }

            if (form.assigned_to.length > 0) {
                new_data = {
                    ...new_data,
                    assigned_to: { remove: data.assigned_to.map((item) => item.id), add: form.assigned_to },
                }
            } else if (form.assigned_to.length === 0) {
                new_data = { ...new_data, assigned_to: { remove: data.assigned_to.map((item) => item.id) } }
            }
            if (form.due_date !== '') new_data = { ...new_data, due_date: form.due_date + 'T23:59' }

            let request = await requests.patch(`${API.CASES}${data.id}/`, new_data)

            if (request.status === 200) {
                handleRequest(request.response, setData, setTotal)
                onClose()
            } else {
                setError(request.response)
            }
        }
        setLoading(false)
    }

    async function changeAssigned(value) {
        if (form.status === 1 && value.length > 0) {
            setForm({ ...form, assigned_to: value, status: 2 })
        } else if (form.status === 2 && value.length === 0) {
            setForm({ ...form, assigned_to: value, status: 1 })
        } else {
            setForm({ ...form, assigned_to: value })
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <NonFieldErrors errors={error} />
            <SuperField
                label={t('title')}
                required
                as="input"
                value={form.title}
                onChange={(e, { value }) => setForm({ ...form, title: value })}
            />
            <MKEditor
                label={t('description')}
                markdown={form.description}
                onChange={(value) => setForm((prev) => ({ ...prev, description: value }))}
            />
            <Divider />
            <Form.Group widths="equal">
                <SuperField
                    label={t('priority')}
                    as="choice"
                    type="priority_choices"
                    value={form.priority?.toString() || ''}
                    onChange={(e, { value }) => setForm({ ...form, priority: value })}
                />
                <SuperField
                    as="choice"
                    search
                    label={t('type')}
                    endpoint={API.CASES_TYPES}
                    text="title"
                    value={form.type || ''}
                    onChange={(e, { value }) => setForm({ ...form, type: value })}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField
                    label={t('due_date')}
                    as="datepicker"
                    value={form.due_date}
                    onChange={(e, { value }) => setForm({ ...form, due_date: value })}
                />
                <SuperField
                    as="choice-select"
                    search
                    text="name"
                    label={t('unit')}
                    value={form.unit}
                    endpoint={API.UNITS}
                    additionalFilters="&query={id, name}&only_from_active_company=true"
                    onChange={(e, { value }) => setForm({ ...form, unit: value })}
                    initialOptions={{
                        attribute: 'name',
                        source: data?.unit,
                    }}
                />
            </Form.Group>

            <Divider />
            <Header as="h3" content={t('assign_to_subject')} />

            <Form.Group>
                <SuperField
                    as="radio"
                    onChange={() => {
                        setSubject(1)
                        setForm((prev) => ({ ...prev, business_detail: '', profile: '' }))
                    }}
                    checked={subject === 1}
                    label={t('account')}
                />
                <SuperField
                    as="radio"
                    onChange={() => {
                        setSubject(2)
                        setForm((prev) => ({ ...prev, business_detail: '', profile: '' }))
                    }}
                    checked={subject === 2}
                    label={t('supplier')}
                />
                <SuperField
                    as="radio"
                    onChange={() => {
                        setSubject(3)
                        setForm((prev) => ({ ...prev, business_detail: '', profile: '' }))
                    }}
                    checked={subject === 3}
                    label={t('employee')}
                />
                <SuperField
                    as="radio"
                    onChange={() => {
                        setSubject(4)
                        setForm((prev) => ({ ...prev, business_detail: '', profile: '' }))
                    }}
                    checked={subject === 4}
                    label={t('candidate')}
                />
            </Form.Group>

            {subject === 1 && (
                <SuperField
                    search
                    as="choice-select"
                    text="name"
                    //label={t('account')}
                    value={form.business_detail}
                    onChange={(e, { value }) => setForm({ ...form, business_detail: value })}
                    endpoint={API.ACCOUNTS + 'business_details/'}
                    additionalFilters={'&is_account=true&exclude_unit=true&query={id, name}&is_active=true'}
                    initialOptions={{
                        attribute: 'name',
                        source: data?.business_detail,
                    }}
                />
            )}

            {subject === 2 && (
                <SuperField
                    as="choice-select"
                    search
                    text="name"
                    value={form.business_detail}
                    //label={t('supplier')}
                    onChange={(e, { value }) =>
                        setForm({
                            ...form,
                            business_detail: value,
                        })
                    }
                    endpoint={API.ACCOUNTS + 'business_details/'}
                    additionalFilters={`&only_basic_info=true&is_supplier=true&exclude_unit=true&query={id, name}&is_active=true`}
                    initialOptions={{
                        attribute: 'name',
                        source: data?.business_detail,
                    }}
                />
            )}

            {subject === 3 && (
                <SuperField
                    as="choice-select"
                    search
                    text="fullname"
                    value={form.profile}
                    //label={t('employee')}
                    onChange={(e, { value }) =>
                        setForm({
                            ...form,
                            profile: value,
                        })
                    }
                    endpoint={API.EMPLOYEES}
                    additionalFilters={`&only_basic_info=true&is_active=true`}
                    initialOptions={{
                        attribute: 'fullname',
                        source: data?.profile,
                    }}
                />
            )}

            {subject === 4 && (
                <SuperField
                    as="choice-select"
                    search
                    //label={t('candidate')}
                    endpoint={API.CANDIDATES}
                    additionalFilters={`&only_basic_info=true&is_active=true`}
                    text="fullname"
                    value={form.profile}
                    onChange={(e, { value }) =>
                        setForm({
                            ...form,
                            profile: value,
                        })
                    }
                    initialOptions={{
                        attribute: 'fullname',
                        source: data?.profile,
                    }}
                />
            )}

            <Divider />
            <SuperField
                as="choice-select"
                multiple
                search
                text="fullname"
                label={t('responsible_persons')}
                additionalFilters="&only_basic_info=true&has_user=true&is_active=true"
                value={form.assigned_to}
                endpoint={API.EMPLOYEES}
                onChange={(e, { value }) => changeAssigned(value)}
                initialOptions={{
                    attribute: 'fullname',
                    source: data?.assigned_to,
                }}
            />

            {(form.id === 0 || !form.id) && (
                <>
                    <Divider />
                    <LabelInput value={form.label} onChange={(e) => setForm({ ...form, label: e })} />
                </>
            )}

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit text={t('confirm')} disabled={form.title === ''} loading={loading} />
            </Form.Field>
        </Form>
    )
}

export default CaseForm
