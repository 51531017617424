import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { isEmpty, isPhoneNumberValid, isEmailValid } from '@helpers/validation'
// components
import { Form, Message, Button, Divider } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

import CandidateForm from '@pages/dashboard/hr/candidates/components/forms/CandidateForm'

const NewCandidateForm = ({ roomId, onClose, setSize, onConfirm, setData, setTotal }) => {
    const { t } = useTranslation()

    const [view, setView] = useState(1)
    const [radioView, setRadioView] = useState(1)
    const [isProcessing, setIsProcessing] = useState(false)
    const [errorList, setErrorList] = useState([])
    const [selectedCandidates, setSelectedCandidates] = useState([])

    const [candidate] = useState({
        firstname: '',
        lastname: '',
        gender: 0,
        email: '',
        phone: '',
        date_of_birth: '',
    })

    const isContactInfoValid = (phone, email) => {
        let isValid = false
        if (phone !== '' && email === '') {
            if (isPhoneNumberValid(phone)) {
                isValid = true
            }
        } else if (email !== '' && phone === '') {
            if (isEmailValid(email)) {
                isValid = true
            }
        } else if (email !== '' && phone !== '') {
            if (isPhoneNumberValid(phone) && isEmailValid(email)) {
                isValid = true
            }
        }

        return isValid
    }

    const isFilledRequiredFields = () => {
        let isValid = true

        if ((view === 1 && selectedCandidates.length > 0) || (view === 3 && selectedCandidates.length > 0)) return true

        // validate if business info are filled property
        if (isEmpty(candidate.firstname) || isEmpty(candidate.lastname)) {
            return false
        }

        // validate if contact information are filled properly
        if (!isContactInfoValid(candidate.phone, candidate.email)) {
            return false
        }

        return isValid
    }

    const handleSubmit = async (profile) => {
        setIsProcessing(true)
        setErrorList([])

        let candidates = []
        if (profile && view === 2) {
            candidates = [profile.id]
        } else {
            candidates = selectedCandidates
        }
        const assignRequest = await requests.post(
            API.CANDIDATES + 'recruitment/' + roomId + '/bulk_assign_to_recruitment/',
            {
                profiles: candidates,
            }
        )

        if ([200, 201].includes(assignRequest.status)) {
            onConfirm(assignRequest.response, setData, setTotal)
            onClose()
        } else {
            const error_message = assignRequest.response.detail
            setErrorList([...errorList, error_message])
        }
        setIsProcessing(false)
    }

    function setCreateCandidate() {
        setView(2)
        setSelectedCandidates([])
    }

    return (
        <>
            <Button.Group fluid basic size="small">
                <Button
                    type="button"
                    active={view === 1}
                    onClick={() => {
                        setView(1)
                        setSize('small')
                    }}
                >
                    {t('add_candidate')}
                </Button>
                <Button type="button" active={view === 2} onClick={() => setCreateCandidate()}>
                    {t('create_candidate')}
                </Button>
            </Button.Group>
            <Divider />

            {view === 1 && (
                <Form onSubmit={handleSubmit}>
                    {errorList.length > 0 && (
                        <Message negative>
                            {errorList.map((error, index) => (
                                <p key={index}> {error} </p>
                            ))}
                        </Message>
                    )}

                    <Form.Group>
                        <SuperField
                            as="radio"
                            label={t('candidates')}
                            checked={radioView === 1}
                            onChange={() => setRadioView(1)}
                        />
                        <SuperField
                            as="radio"
                            label={t('employees')}
                            checked={radioView === 2}
                            onChange={() => setRadioView(2)}
                        />
                    </Form.Group>

                    {radioView === 1 ? (
                        <SuperField
                            as="choice-select"
                            key={radioView}
                            required
                            search
                            multiple
                            endpoint={API.CANDIDATES}
                            additionalFilters={`&only_basic_info=true&not_in_recruitment=${roomId}&is_active=true&include_admins=false&exclude_blacklisted=true`}
                            text="fullname_with_titles"
                            label={t('candidates')}
                            value={selectedCandidates}
                            onChange={(e, { value }) => setSelectedCandidates(value)}
                        />
                    ) : (
                        <SuperField
                            as="choice-select"
                            search
                            placeholder={t('employees')}
                            key={radioView}
                            required
                            multiple
                            endpoint={API.EMPLOYEES}
                            additionalFilters={`&only_basic_info=true&not_in_recruitment=${roomId}`}
                            text="fullname_with_titles"
                            label={t('employees')}
                            value={selectedCandidates}
                            onChange={(e, { value }) => setSelectedCandidates(value)}
                        />
                    )}

                    <Divider />
                    <Form.Field style={{ textAlign: 'right' }}>
                        <ModalCancel onClose={onClose} disabled={isProcessing} />
                        <ModalSubmit
                            disabled={isProcessing || !isFilledRequiredFields()}
                            loading={isProcessing}
                            text={t('confirm')}
                        />
                    </Form.Field>
                </Form>
            )}

            {view === 2 && (
                <CandidateForm
                    setSize={setSize}
                    onClose={onClose}
                    isHiringRoom={true}
                    onCreate={(profile) => handleSubmit(profile)}
                />
            )}
        </>
    )
}

export default NewCandidateForm
