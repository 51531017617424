import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests, fileDownload } from '@helpers/requests'
import { useHasPermission, useHasPermissions } from '@helpers/hooks'
import { dates as dateHelper } from '@helpers/dates'
// components
import Icon from '@components/Icon'
import UserAvatar from 'react-user-avatar'
import Action from '@components/general/Action'
import ShortenText from '@components/ShortenText'
// eslint-disable-next-line
import CanView from '@components/perms/CanView'
import DatePresets from '@components/DatePresets'
import EmptyRow from '@components/tables/EmptyRow'
import SuperField from '@components/forms/SuperField'
import SpinnerSegment from '@components/SpinnerSegment'
import TariffForm from '@components/forms/TariffForm'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { Table, Popup, Ref, Sidebar, Segment, Menu, Grid, Form, Button, Divider, Header } from 'semantic-ui-react'
// module specific components
import ActivityForm from '../components/forms/ActivityForm'

const GroupTimesheet = ({ team }) => {
    const { t } = useTranslation()
    const target = useRef()
    const canManageAll = useHasPermissions([
        'timesheets.c_manage_all_records',
        'company.c_manage_only_assigned_unit_employees',
    ])
    const canViewTariff = useHasPermission('timesheets.c_view_tariff')
    const canManageTariffs = useHasPermissions(['orders.c_view_order_tariffs', 'orders.c_manage_order_tariffs'])
    const dateFormat = useSelector((state) => state?.date_format || 'YYYY-MM-DD')
    const firstDay = moment().startOf('month').format('YYYY-MM-DD')
    const lastDay = moment().endOf('month').format('YYYY-MM-DD')

    const initialFilters = {
        category: '',
        business_detail: '',
        order: '',
        project: '',
        start_date: firstDay,
        end_date: lastDay,
        ordering: '-date',
    }

    const cellPadding = {
        paddingTop: '1rem',
        paddingBottom: '1rem',
    }

    const [orders, setOrders] = useState([])
    const [records, setRecords] = useState([])
    // eslint-disable-next-line
    const [total, setTotal] = useState(0)
    const [member, setMember] = useState(null)
    const [members, setMembers] = useState([])
    const [loading, setLoading] = useState(true)
    const [visible, setVisible] = useState(false)
    const [filters, setFilters] = useState(initialFilters)
    const [loadingRecords, setLoadingRecords] = useState(true)

    const canManageTariff = (record) => {
        if (!canManageAll) return false
        if (!canViewTariff) return false
        if (!canManageTariffs) return false
        if (record.order_fulfillment !== null) return false

        return true
    }

    useEffect(() => {
        async function fetchMembers() {
            setLoading(true)
            if (team !== null) {
                const request = await requests.get(
                    API.TEAMMEMBERS + '?query={id, profile, position, is_leader}&team=' + team
                )
                if (request.status === 200) {
                    setMembers(
                        request.response.sort((a, b) =>
                            a.is_leader > b.is_leader ? -1 : Number(a.is_leader < b.is_leader)
                        )
                    )
                }
            }
            setLoading(false)
        }

        fetchMembers()
    }, [team])

    const handleParams = (params) => {
        params = params || ''
        const filterArray = Object.keys(filters)
        for (let i = 0; i < filterArray.length; i++) {
            if (
                filters?.[filterArray[i]] !== '' ||
                (Array.isArray(filters?.[filterArray[i]]) && filters?.[filterArray[i]].length !== 0)
            ) {
                if (!params.includes(filterArray[i]) && filterArray[i] !== 'custom_filter') {
                    if (Array.isArray(filters?.[filterArray[i]]) === false) {
                        params += `&${filterArray[i]}=${filters[filterArray[i]]}`
                    } else {
                        let arr = filters?.[filterArray[i]] || []
                        for (let y = 0; y < arr.length; y++) {
                            params += `&${filterArray[i]}=${arr[y]}`
                        }
                    }
                }
            }
        }

        return params
    }

    const fetchRecords = async (id) => {
        setLoadingRecords(true)
        const request = await requests.get(API.TIMESHEETS_RECORDS + '?profile=' + id + handleParams())
        if (request.status === 200) {
            setRecords(request.response)
            setTotal(request.response.length)
        }

        setLoadingRecords(false)
    }

    const onSelection = async (item) => {
        if (member?.id === item.id) {
            setMember(null)
        } else {
            setMember(item)
        }

        if (member?.id !== item.id) {
            await fetchRecords(item.profile.id)
        }
    }

    useEffect(() => {
        async function fetchOrders() {
            const request = await requests.get(API.ORDERS + '?&only_basic_info=true')

            if (request.status === 200) {
                setOrders(request.response)
            }
        }

        fetchOrders()
    }, [])

    const handleSubmit = async () => {
        if (member === null) {
            onSelection(members[0])
        } else {
            // update existing selection
            await fetchRecords(member.profile.id)
        }
    }

    const onDelete = async (record) => {
        const request = await requests.post(API.TIMESHEETS + 'records/' + record.id + '/delete/')

        if (request.status === 200) {
            setRecords((prevState) => prevState.filter((item) => item.id !== record.id))
        }
    }

    const exportGroupData = async () => {
        const endpoint = canViewTariff
            ? API.EXPORTS + 'dynamic_timesheet_records_with_tariff/'
            : API.EXPORTS + 'dynamic_timesheets/'
        await fileDownload(
            'GET',
            `${endpoint}?team=${team}${handleParams()}`,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            `group_timesheet_${filters.start_date}__${filters.end_date}.xlsx`
        )
    }

    const exportMemberData = async (profile) => {
        const endpoint = canViewTariff
            ? API.EXPORTS + 'dynamic_timesheet_records_with_tariff/'
            : API.EXPORTS + 'dynamic_timesheets/'
        await fileDownload(
            'GET',
            `${endpoint}?profile=${profile.id}${handleParams()}`,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            `member_timesheet_${filters.start_date}__${filters.end_date}__${profile.fullname}.xlsx`
        )
    }

    return (
        <Ref innerRef={target}>
            <Sidebar.Pushable
                as={Segment}
                style={{ border: 'none', background: 'transparent', boxShadow: 'none', padding: 0, margin: 0 }}
                className={'fixed-sidebar'}
            >
                <Sidebar
                    direction="right"
                    style={{ width: '40rem', textAlign: 'left' }}
                    as={Menu}
                    animation="overlay"
                    icon="labeled"
                    vertical
                    visible={visible}
                    onHide={() => setVisible(false)}
                    target={target}
                >
                    <Form
                        style={{ paddingLeft: '1rem', paddingRight: '1rem', textAlign: 'left' }}
                        onSubmit={(e) => e.preventDefault()}
                    >
                        <Grid>
                            <Grid.Row columns={2} verticalAlign="middle" style={{ padding: 0 }}>
                                <Grid.Column>
                                    <Header
                                        as="h2"
                                        content={t('filters')}
                                        style={{ marginTop: '1rem', textAlign: 'left' }}
                                    />
                                </Grid.Column>
                                <Grid.Column textAlign="right">
                                    <Icon
                                        name="close-outline"
                                        style={{
                                            cursor: 'pointer',
                                            color: 'var(--danger)',
                                            marginTop: '1.5rem',
                                            fontSize: '1.5rem',
                                        }}
                                        onClick={() => setVisible(false)}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Divider />
                        <Form.Group widths="equal">
                            <SuperField
                                as="datepicker"
                                size="small"
                                label={t('start_date')}
                                value={filters.start_date}
                                style={{ height: '2.75rem' }}
                                onChange={(e, { value }) => setFilters({ ...filters, start_date: value })}
                            />
                            <SuperField
                                as="datepicker"
                                size="small"
                                label={t('end_date')}
                                value={filters.end_date}
                                style={{ height: '2.75rem' }}
                                onChange={(e, { value }) => setFilters({ ...filters, end_date: value })}
                            />
                        </Form.Group>
                        <DatePresets
                            from={filters.start_date}
                            to={filters.end_date}
                            onSelection={(firstMonthDay, lastMonthDay, year) => {
                                setFilters((prev) => ({
                                    ...prev,
                                    start_date: firstMonthDay,
                                    end_date: lastMonthDay,
                                }))
                            }}
                        />

                        <SuperField
                            as="choice-select"
                            search
                            text="title"
                            label={t('activity')}
                            endpoint={API.TIMESHEETS + 'categories/'}
                            value={filters.category}
                            onChange={(e, { value }) => setFilters({ ...filters, category: value })}
                        />

                        <SuperField
                            as="choice-select"
                            search
                            label={t('order')}
                            text="name"
                            endpoint={API.ORDERS}
                            additionalFilters="&query={id,name,account}&only_basic_info=true"
                            value={filters.order}
                            onChange={(e, { value }) =>
                                setFilters({
                                    ...filters,
                                    order: value,
                                })
                            }
                        />
                        <SuperField
                            as="choice-select"
                            search
                            text="name"
                            label={t('client')}
                            value={filters.business_detail}
                            endpoint={API.ACCOUNTS + 'business_details/'}
                            additionalFilters="&query={id, name}&is_account=true&exclude_unit=true"
                            onChange={(e, { value }) => setFilters({ ...filters, business_detail: value })}
                        />
                        <SuperField
                            as="choice-select"
                            search
                            text="name"
                            label={t('project')}
                            value={filters.project}
                            endpoint={API.PROJECTS}
                            additionalFilters="&query={id, name}&is_active=true"
                            onChange={(e, { value }) => setFilters({ ...filters, project: value })}
                        />
                        <Divider />
                        <Form.Field>
                            <Button type="button" content={t('reset')} onClick={() => setFilters(initialFilters)} />
                            <Button primary type="button" content={t('confirm')} onClick={() => handleSubmit()} />
                        </Form.Field>
                    </Form>
                </Sidebar>
                <SpinnerSegment loading={loading} loadingMessage={t('loading_group_members')}>
                    <Grid>
                        <Grid.Row verticalAlign="middle" columns="1" style={{ padding: 0 }}>
                            <Grid.Column textAlign="right">
                                <Action
                                    as="custom"
                                    type="icon"
                                    icon="download-outline"
                                    tooltip={t('download')}
                                    size="huge"
                                    onClick={() => exportGroupData()}
                                    iconColor="black"
                                />
                                <Action
                                    as="custom"
                                    type="icon"
                                    tooltip={t('filter')}
                                    iconColor="black"
                                    size="huge"
                                    icon="funnel-outline"
                                    paddingLeft="1rem"
                                    onClick={() => setVisible(!visible)}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <div>
                        {members.map((item) => (
                            <div
                                style={{
                                    background: 'var(--light)',
                                    border: '0.5px solid #e3e3e3',
                                    width: '100%',
                                    marginBottom: '0.5rem',
                                    padding: '0.8rem',
                                }}
                            >
                                <div
                                    key={item.id}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div style={{ width: '90%', display: 'flex', alignItems: 'center' }}>
                                        <div style={{ cursor: 'pointer' }}>
                                            <UserAvatar
                                                size={40}
                                                color="var(--variant5)"
                                                name={item?.profile?.fullname || '--'}
                                                src={item?.profile?.profile_picture || ''}
                                                style={{ paddingLeft: '0rem', fontSize: 40 / 2.5 + 'px' }}
                                            />
                                        </div>
                                        <div style={{ marginLeft: '1rem' }}>
                                            <h3 style={{ marginBottom: 0 }}>{item.profile.fullname}</h3>
                                            <span>
                                                {' '}
                                                {item.position}{' '}
                                                {item.is_leader && (
                                                    <>
                                                        {' '}
                                                        | <strong> {t('leader')} </strong>
                                                    </>
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            textAlign: 'right',
                                            width: '10%',
                                            marginRight: '0.5rem',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <Action
                                            as="custom"
                                            type="icon"
                                            icon="download-outline"
                                            tooltip={t('download') + ' - ' + item.profile.fullname}
                                            size="huge"
                                            onClick={() => exportMemberData(item.profile)}
                                            iconColor="black"
                                        />
                                        <CanView
                                            permissions={[
                                                'timesheets.c_manage_user_records',
                                                'timesheets.c_manage_user_records',
                                                'company.c_manage_only_assigned_unit_employees',
                                            ]}
                                        >
                                            <Action
                                                as="modal"
                                                type="icon"
                                                tooltip={t('add')}
                                                size="huge"
                                                iconColor="var(--dark)"
                                                icon="add-outline"
                                                modal={
                                                    <ActivityForm
                                                        setTotal={setTotal}
                                                        setData={setRecords}
                                                        orders={orders}
                                                        profile={item.profile}
                                                    />
                                                }
                                            />
                                        </CanView>
                                        <Icon
                                            name={`chevron-${member?.id === item.id ? 'up' : 'down'}-outline`}
                                            style={{ fontSize: '1.2rem', marginLeft: '1rem' }}
                                            onClick={() => onSelection(item)}
                                        />
                                    </div>
                                </div>

                                {/* Member timesheet */}
                                {member?.id === item.id && (
                                    <div
                                        style={{
                                            width: '100%',
                                            background: 'var(--white)',
                                            border: records.length > 0 ? 'none' : '1px solid #e3e3e3',
                                            padding: loadingRecords ? '0.5rem' : '0',
                                            marginTop: '1rem',
                                        }}
                                    >
                                        <SpinnerSegment
                                            loading={loadingRecords}
                                            loadingMessage={t('loading_member_records')}
                                            marginBottom={loadingRecords ? undefined : '0'}
                                        >
                                            <EmptyRow length={records.length} />
                                            {records.length > 0 && (
                                                <div style={{ padding: 0, overflow: 'auto', width: '100%' }}>
                                                    <Table striped selectable unstackable className="table responsive">
                                                        <Table.Header>
                                                            <Table.Row verticalAlign="middle">
                                                                <Table.HeaderCell>{t('date')}</Table.HeaderCell>
                                                                <Table.HeaderCell>{t('activity')}</Table.HeaderCell>
                                                                <Table.HeaderCell>
                                                                    {t('hours') + ' / ' + t('amount')}
                                                                </Table.HeaderCell>
                                                                <Table.HeaderCell>{t('client')}</Table.HeaderCell>
                                                                <Table.HeaderCell>{t('project')}</Table.HeaderCell>
                                                                <Table.HeaderCell>{t('order')}</Table.HeaderCell>
                                                                {canViewTariff && (
                                                                    <Table.HeaderCell>{t('sum')}</Table.HeaderCell>
                                                                )}
                                                                <CanView
                                                                    permissions={[
                                                                        'timesheets.c_manage_user_records',
                                                                        'timesheets.c_manage_user_records',
                                                                        'company.c_manage_only_assigned_unit_employees',
                                                                    ]}
                                                                >
                                                                    <Table.HeaderCell>{t('actions')}</Table.HeaderCell>
                                                                </CanView>
                                                            </Table.Row>
                                                        </Table.Header>
                                                        <Table.Body>
                                                            {records.map((record) => (
                                                                <Table.Row verticalAlign="middle" key={record.id}>
                                                                    <Table.Cell
                                                                        singleLine
                                                                        style={{
                                                                            paddingTop: '1rem',
                                                                            paddingBottom: '1rem',
                                                                        }}
                                                                    >
                                                                        <strong>
                                                                            {moment(record.date).format(dateFormat)}
                                                                        </strong>{' '}
                                                                        <br />
                                                                        <small>
                                                                            {t('week')} {record.week}
                                                                        </small>
                                                                    </Table.Cell>
                                                                    <Table.Cell
                                                                        singleLine
                                                                        width="4"
                                                                        style={cellPadding}
                                                                    >
                                                                        <strong>{record?.category?.title}</strong>{' '}
                                                                        <br />
                                                                        <small>
                                                                            <ShortenText
                                                                                text={record.activity}
                                                                                textLength={50}
                                                                            />
                                                                        </small>
                                                                    </Table.Cell>
                                                                    <Table.Cell
                                                                        singleLine
                                                                        width="2"
                                                                        style={cellPadding}
                                                                    >
                                                                        <strong style={{ textTransform: 'lowercase' }}>
                                                                            {`${record.hours} ${t('hours_shortcut')}.`}{' '}
                                                                            {record.piece_work_count > 0 &&
                                                                                ` / ${record.piece_work_count}x`}
                                                                        </strong>{' '}
                                                                        <br />
                                                                        <small>
                                                                            {record.datetime_from !== null &&
                                                                                record.datetime_to !== null &&
                                                                                `${moment(
                                                                                    dateHelper.convertUTCDateToLocalDate(
                                                                                        record?.datetime_from
                                                                                    )
                                                                                ).format('HH:mm')} - ${moment(
                                                                                    dateHelper.convertUTCDateToLocalDate(
                                                                                        record?.datetime_to
                                                                                    )
                                                                                ).format('HH:mm')}`}
                                                                        </small>
                                                                    </Table.Cell>
                                                                    <Table.Cell singleLine style={cellPadding}>
                                                                        <span>{record?.business_detail?.name}</span>
                                                                    </Table.Cell>
                                                                    <Table.Cell singleLine style={cellPadding}>
                                                                        <span>{record?.project?.name}</span>
                                                                        <br />
                                                                        {record?.milestone?.name}
                                                                    </Table.Cell>
                                                                    <Table.Cell singleLine style={cellPadding}>
                                                                        <span>{record?.order?.name}</span>
                                                                    </Table.Cell>
                                                                    {canViewTariff && (
                                                                        <Table.Cell
                                                                            singleLine
                                                                            style={{
                                                                                paddingTop: '1rem',
                                                                                paddingBottom: '1rem',
                                                                            }}
                                                                        >
                                                                            {record?.tariff?.id ? (
                                                                                <div style={{ display: 'flex' }}>
                                                                                    <Popup
                                                                                        position="left center"
                                                                                        trigger={
                                                                                            <span>
                                                                                                {record?.total_fee ||
                                                                                                    ''}{' '}
                                                                                                {record?.tariff
                                                                                                    ?.currency || ''}
                                                                                            </span>
                                                                                        }
                                                                                        content={`${record.tariff.type_display} - ${record.tariff.amount} ${record.tariff.currency}`}
                                                                                    />
                                                                                    {canManageTariff(record) && (
                                                                                        <SuperDuperModal
                                                                                            size="tiny"
                                                                                            trigger={
                                                                                                <Icon
                                                                                                    className="action-hover"
                                                                                                    name="pencil-outline"
                                                                                                    style={{
                                                                                                        color: 'var(--dark)',
                                                                                                        marginLeft:
                                                                                                            '1rem',
                                                                                                        marginTop:
                                                                                                            '0.25rem',
                                                                                                    }}
                                                                                                />
                                                                                            }
                                                                                            content={
                                                                                                <TariffForm
                                                                                                    record={record}
                                                                                                    currentTariff={
                                                                                                        record?.tariff
                                                                                                    }
                                                                                                    activity={
                                                                                                        record?.category
                                                                                                            ?.id
                                                                                                    }
                                                                                                    profile={
                                                                                                        record.profile
                                                                                                            .id
                                                                                                    }
                                                                                                    setData={setRecords}
                                                                                                />
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                            ) : (
                                                                                '--'
                                                                            )}
                                                                        </Table.Cell>
                                                                    )}
                                                                    <CanView
                                                                        permissions={[
                                                                            'timesheets.c_manage_user_records',
                                                                            'timesheets.c_manage_user_records',
                                                                            'company.c_manage_only_assigned_unit_employees',
                                                                        ]}
                                                                    >
                                                                        <Table.Cell
                                                                            width="1"
                                                                            singleLine
                                                                            style={cellPadding}
                                                                        >
                                                                            {record.latitude !== null &&
                                                                                record.longitude !== null && (
                                                                                    <Action
                                                                                        as="custom"
                                                                                        type="icon"
                                                                                        tooltip={t('show_location')}
                                                                                        iconColor="black"
                                                                                        size="huge"
                                                                                        icon="location-outline"
                                                                                        paddingRight="1rem"
                                                                                        onClick={() =>
                                                                                            window.open(
                                                                                                `https://www.google.com/maps/search/?api=1&query=${record.latitude}%2C${record.longitude}`
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            {record.order_fulfillment === null && (
                                                                                <>
                                                                                    <Action
                                                                                        as="modal"
                                                                                        type="icon"
                                                                                        tooltip={t('update')}
                                                                                        size="huge"
                                                                                        iconColor="var(--dark)"
                                                                                        icon="pencil-outline"
                                                                                        modal={
                                                                                            <ActivityForm
                                                                                                record={record}
                                                                                                setTotal={setTotal}
                                                                                                setData={setRecords}
                                                                                                orders={orders}
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                    <Action
                                                                                        as="delete"
                                                                                        size="huge"
                                                                                        tooltip={t('delete')}
                                                                                        text={t(
                                                                                            'delete_record_message'
                                                                                        )}
                                                                                        onClick={() => onDelete(record)}
                                                                                    />
                                                                                </>
                                                                            )}
                                                                        </Table.Cell>
                                                                    </CanView>
                                                                </Table.Row>
                                                            ))}
                                                        </Table.Body>
                                                    </Table>
                                                </div>
                                            )}
                                        </SpinnerSegment>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </SpinnerSegment>
            </Sidebar.Pushable>
        </Ref>
    )
}

export default GroupTimesheet
