import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
import moment from 'moment'
// store
import { routes } from '@routes'
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { useHasPermission } from '@helpers/hooks'
import { renderAddress } from '@helpers/functions'
// components
import Icon from '@components/Icon'
import ListView from '@components/ListView'
import AvatarList from '@components/AvatarList'
import Attachments from '@components/Attachments'
import SuperField from '@components/forms/SuperField'
import LabelsList from '@components/lists/LabelsList'
import { Form, Label, Button, Popup, Icon as SemanticIcon } from 'semantic-ui-react'
import GenerateDocuments from '@components/GenerateDocuments'
// import SuperDuperModal from '@components/modals/SuperDuperModal';
// shared components
import OrderState from '../shared/OrderState'
// specific components
import EmployedPersons from './components/EmployedPersons'
import EmploymentOrderForm from './components/EmploymentOrderForm'
import EmploymentPersonsForm from './components/EmploymentPersonsForm'

const EmploymentOrder = ({ isPurchase }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector((store) => store.date_format)
    const [open, setOpen] = useState(null)
    const [action, setAction] = useState(null)

    const canManage = useHasPermission('orders.c_manage_order')
    const [attachmentsOpen, setAttachmentsOpen] = useState(0)

    const onStatusChange = async (state, order, setData, setTotal, filters) => {
        const request = await requests.patch(API.ORDERS + order.id + '/', {
            is_active: state,
            resourcetype: 'EmploymentOrder',
        })
        if (request.status === 200) {
            if (filters.is_active.length === 0) {
                setData((prev) =>
                    prev.filter((item) => {
                        if (item.id === order.id) {
                            item.is_active = state
                        }

                        return item
                    })
                )
            } else {
                setData((prev) => prev.filter((item) => item.id !== order.id))
                setTotal((prev) => prev - 1)
            }

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('order_status_changed'),
                animation: 'pulse',
                time: 2000,
            })
        }
    }

    const OrderDateRange = ({ order, lineBreak }) => {
        const { t } = useTranslation()
        return (
            <>
                {lineBreak && <br />}
                {order.date_from !== null && (
                    <Popup
                        position="top center"
                        trigger={
                            <span>
                                {' '}
                                <Icon name="calendar-outline" /> {moment(order.date_from).format(dateFormat)}{' '}
                            </span>
                        }
                        content={t('date_from')}
                    />
                )}
                {order.date_from !== null && order.date_to !== null && ' - '}
                {order.date_to !== null && (
                    <Popup
                        position="top center"
                        trigger={
                            <span>
                                {' '}
                                <Icon name="calendar-outline" /> {moment(order.date_to).format(dateFormat)}{' '}
                            </span>
                        }
                        content={t('date_to')}
                    />
                )}
            </>
        )
    }

    return (
        <ListView
            as="table"
            allowSearch
            allowSelection
            actionsCellWidth="2"
            isSoftDeleteAllowed
            endpoint={API.ORDERS}
            initialFilters={{
                is_purchase_order: isPurchase,
                ordering: '-created_on',
                account: '',
                labels: [],
                assigned_to: [],
                order_manager: '',
                order_status: '',
                is_active: true,
                resource_type: 'employmentorder',
            }}
            exports={[
                {
                    endpoint: API.EXPORTS + 'dynamic_employment_order/',
                    permissions: ['orders.c_view_order'],
                    apply_filters: true,
                    type: 'xlsx',
                    filename: 'employment_orders',
                },
            ]}
            listAdditionActions={() => [
                {
                    as: 'filter',
                    index: 0,
                    name: t('received_orders'),
                    filters: {
                        is_purchase_order: false,
                    },
                },
            ]}
            bulkActions={(selected) => [
                {
                    as: 'modal',
                    name: t('generate_documents'),
                    icon: 'document-text-outline',
                    modal: <GenerateDocuments source="orders.EmploymentOrder" selected={selected} />,
                },
            ]}
            tableHeaders={[
                { title: t('order_num'), orderBy: 'order_number' },
                { title: t('name'), orderBy: 'name' },
                { title: t('supplier'), orderBy: 'owner__name' },
                { title: t('account'), orderBy: 'business_detail__name' },
                { title: t('labels') },
                { title: t('workplace') },
                { title: t('fee') },
                { title: t('employed_persons') },
                { title: t('order_manager'), orderBy: 'order_manager__fullname' },
                { title: t('responsible_persons') },
                { title: t('state'), orderBy: 'order_status' },
                { title: t('documents') },
                { title: t('status') },
            ]}
            renderFilterFields={(filters, setFilters) => (
                <>
                    <Form.Group widths="equal">
                        <SuperField
                            as="choice-select"
                            search
                            text="name"
                            label={t('account')}
                            value={filters.account}
                            endpoint={API.ACCOUNTS + 'business_details'}
                            additionalFilters="&query={id, name}&is_account=true&is_active=true&exclude_unit=true"
                            onChange={(e, { value }) => setFilters({ ...filters, account: value })}
                        />
                        <SuperField
                            as="choice-select"
                            search
                            text="fulname_with_titles"
                            endpoint={API.EMPLOYEES}
                            label={t('order_manager')}
                            value={filters.order_manager}
                            additionalFilters="&only_basic_info=true&is_active=true"
                            onChange={(e, { value }) => setFilters({ ...filters, order_manager: value })}
                        />
                    </Form.Group>
                    <SuperField
                        as="choice-select"
                        search
                        multiple
                        label={t('responsible_persons')}
                        value={filters.assigned_to}
                        endpoint={API.EMPLOYEES}
                        text="fulname_with_titles"
                        additionalFilters="&only_basic_info=true&is_active=true"
                        onChange={(e, { value }) => setFilters({ ...filters, assigned_to: value })}
                    />

                    <SuperField
                        as="choice-select"
                        search
                        multiple
                        text="name"
                        label={t('labels')}
                        value={filters.labels}
                        endpoint={API.ORDERS + 'labels/'}
                        additionalFilters="&query={id, name}"
                        onChange={(e, { value }) => setFilters({ ...filters, labels: value })}
                    />

                    <Form.Group widths="equal">
                        <SuperField
                            as="choice"
                            label={t('state')}
                            value={filters.order_status}
                            type="order_status_choices"
                            onChange={(e, { value }) => setFilters({ ...filters, order_status: value })}
                        />
                        <SuperField
                            as="choice"
                            label={t('status')}
                            value={filters.is_active}
                            customOptions={[
                                { key: 1, value: true, text: t('active_order') },
                                { key: 2, value: false, text: t('closed_order') },
                            ]}
                            onChange={(e, { value }) => setFilters({ ...filters, is_active: value })}
                        />
                    </Form.Group>
                </>
            )}
            actions={[
                {
                    as: 'modal',
                    type: 'add',
                    name: t('create_order'),
                    modal: <EmploymentOrderForm />,
                    permissions: ['orders.c_manage_order'],
                },
                {
                    as: 'modal',
                    type: 'custom',
                    icon: 'eye-outline',
                    name: t('order_details'),
                    modalSize: 'large',
                    customIconColor: 'var(--primary)',
                    modal: (record, setData) => <EmployedPersons order={record} />,
                    permissions: ['orders.c_manage_order'],
                },
                {
                    as: 'modal',
                    type: 'custom',
                    icon: 'person-add-outline',
                    name: t('assign_persons_for_employment'),
                    text: t('assign_persons_for_employment'),
                    modalSize: 'tiny',
                    customIconColor: 'var(--dark)',
                    modal: <EmploymentPersonsForm />,
                    permissions: ['orders.c_manage_order'],
                },
                // {
                //     as: "modal",
                //     type: "custom",
                //     icon: "add-circle-outline",
                //     name: (!canManageFulfillments || !canManageAllRecords) ? t('fulfillment_limited_access_hint') : t('create_fulfillment'),
                //     modalSize: "tiny",
                //     disabled: (!canManageAllRecords || !canManageFulfillments),
                //     customIconColor: "var(--dark)",
                //     modal: <ServiceOrderFulfillment/>,
                //     permissions: ['orders.c_manage_order_fulfillment']
                // },
                {
                    as: 'modal',
                    type: 'edit',
                    name: t('edit'),
                    permissions: ['orders.c_manage_order'],
                    modal: <EmploymentOrderForm />,
                },
                {
                    name: t('delete'),
                    type: 'delete',
                    as: 'delete',
                    text: t('delete_order'),
                    permissions: ['orders.c_delete_order'],
                },
            ]}
            renderCells={(order, setData, setTotal, idx, filters) => [
                { width: 1, content: order.order_number },
                {
                    customWidth: '150px',
                    content: (
                        <>
                            <strong title={order.name}> {order.name} </strong>
                            <small>
                                <OrderDateRange order={order} lineBreak />
                            </small>
                        </>
                    ),
                },
                { content: order.owner?.name || '' },
                {
                    content: (
                        <>
                            {order?.business_detail?.id ? (
                                <Link target="_blank" to={routes.ACCOUNTS + order.business_detail.id}>
                                    {order.business_detail?.name}
                                </Link>
                            ) : (
                                '--'
                            )}
                        </>
                    ),
                },
                {
                    customWidth: '200px',
                    content: (
                        <LabelsList
                            key={order.id}
                            rowID={order.id}
                            labels={order.labels}
                            endpoint={API.ORDERS}
                            additionalFilters="&lead_tag=false"
                            resourcetype={order.resourcetype}
                            endpointLabels={API.ORDERS + 'labels/'}
                            label_key={'labels'}
                        />
                    ),
                },
                { content: `${renderAddress(order.workplace_address)}` },
                { content: `${order.fee_per_hour} ${order.currency}` },
                {
                    content: `${order.active_employed_person_count} / ${order.amount || 0} (${
                        order.amount > 0 && order.active_employed_person_count > 0
                            ? parseFloat((order.active_employed_person_count / order.amount) * 100)
                                  .toFixed(2)
                                  .replace('.00', '')
                            : '0'
                    }%)`,
                },
                {
                    width: 2,
                    content: (
                        <>
                            {order?.order_manager?.id ? (
                                <Link target="_blank" to={routes.EMPLYOEES_DETAIL + order?.order_manager?.id}>
                                    <span>{order?.order_manager?.fullname}</span>
                                </Link>
                            ) : (
                                '--'
                            )}
                        </>
                    ),
                },
                {
                    width: 2,
                    content: (
                        <AvatarList
                            size="25"
                            alignDirection="start"
                            persons={order.assigned_to.map((item) => ({
                                id: item.id,
                                name: item.fullname,
                                avatar: item.profile_picture,
                                displayName: item?.fullname_with_titles || item.fullname,
                            }))}
                        />
                    ),
                },
                {
                    content: (
                        <OrderState
                            order={order}
                            setData={setData}
                            setTotal={setTotal}
                            filters={filters}
                            type={'EmploymentOrder'}
                        />
                    ),
                },
                {
                    content: (
                        <Label basic>
                            <Popup
                                style={{ zIndex: '1000', minWidth: '450px' }}
                                open={attachmentsOpen === order.id}
                                onOpen={() => setAttachmentsOpen(order.id)}
                                hoverable
                                position="left center"
                                trigger={
                                    <div style={{ cursor: 'pointer' }}>
                                        <Icon name="reader-outline" style={{ marginRight: '0.5rem' }} />
                                        <span style={{ position: 'relative', top: '-0.1rem' }}>
                                            {order.attachments.length}
                                        </span>
                                    </div>
                                }
                                content={
                                    <Attachments
                                        viewOnly={!canManage}
                                        record={order}
                                        allowFolders
                                        moduleName={'EmploymentOrder'}
                                        setData={setData}
                                        sourceEndpoint={API.ORDERS}
                                        closeView={() => setAttachmentsOpen(0)}
                                        additionalData={{ resourcetype: 'EmploymentOrder' }}
                                    />
                                }
                            />
                        </Label>
                    ),
                },
                {
                    content: canManage ? (
                        <Popup
                            basic
                            hoverable
                            position="bottom center"
                            style={{ padding: 0 }}
                            open={open === order?.id}
                            onOpen={() => setOpen(order?.id)}
                            onClose={() => {
                                if (open === order?.id && action === 'edit_status') {
                                    setOpen(order?.id)
                                } else {
                                    setOpen(null)
                                    setAction(null)
                                }
                            }}
                            trigger={
                                <div>
                                    <SemanticIcon
                                        size="small"
                                        name="circle"
                                        style={{
                                            color: order.is_active ? 'green' : 'red',
                                            position: 'relative',
                                            top: '-0.1rem',
                                        }}
                                    />
                                    {order.is_active ? t('active') : t('closed')}
                                    <Icon
                                        name="chevron-down"
                                        style={{
                                            marginLeft: '0.4rem',
                                            position: 'relative',
                                            top: '0.2rem',
                                        }}
                                    />
                                </div>
                            }
                            content={
                                <div
                                    style={{
                                        width: '100%',
                                        textAlign: 'left',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Button
                                        basic
                                        size="tiny"
                                        type="button"
                                        style={{ marginRight: 0 }}
                                        active={order.is_active}
                                        key={order.is_active}
                                        content={
                                            <>
                                                <SemanticIcon
                                                    size="small"
                                                    name="circle"
                                                    style={{
                                                        color: 'green',
                                                        position: 'relative',
                                                        top: '-0.1rem',
                                                    }}
                                                />
                                                {t('active')}
                                            </>
                                        }
                                        onClick={() => onStatusChange(true, order, setData, setTotal, filters)}
                                    />
                                    <Button
                                        basic
                                        size="tiny"
                                        type="button"
                                        style={{ marginRight: 0 }}
                                        active={order.is_active === false}
                                        key={order.is_active}
                                        content={
                                            <>
                                                <SemanticIcon
                                                    size="small"
                                                    name="circle"
                                                    style={{
                                                        color: 'red',
                                                        position: 'relative',
                                                        top: '-0.1rem',
                                                    }}
                                                />
                                                {t('closed')}
                                            </>
                                        }
                                        onClick={() => onStatusChange(false, order, setData, setTotal, filters)}
                                    />
                                </div>
                            }
                        />
                    ) : (
                        <Label
                            style={{
                                color: 'var(--light)',
                                background: order.is_active ? 'green' : 'red',
                                cursor: 'pointer',
                            }}
                        >
                            <span style={{ position: 'relative', top: '0.1rem' }}>
                                {order.is_active ? t('active') : t('closed')}
                            </span>
                        </Label>
                    ),
                },
            ]}
        />
    )
}

export default EmploymentOrder
