import React, { useState, useEffect } from 'react'
// store
import { requests } from '@helpers/requests'
import { API } from '@store/config'
// components
import AbroadForm from './AbroadForm'
import SpinnerSegment from '@components/SpinnerSegment'

const AbroadFormEdit = ({ setData, setTotal, item, setHeader, onClose }) => {
    const [dataAbroad, setDataAbroad] = useState([])
    const [loading, setLoading] = useState([])

    useEffect(() => {
        async function getAbroad() {
            setLoading(true)
            const request = await requests.get(API.ABROADS + item.id + '/')
            if (request.status === 200) {
                setDataAbroad(request.response)
            }
            setLoading(false)
        }
        getAbroad()
        // eslint-disable-next-line
    }, [])

    return (
        <SpinnerSegment loading={loading}>
            {!loading && (
                <AbroadForm
                    item={dataAbroad}
                    setData={setData}
                    setTotal={setTotal}
                    setHeader={setHeader}
                    onClose={onClose}
                />
            )}
        </SpinnerSegment>
    )
}

export default AbroadFormEdit
