import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
// store
import { APIRequest, requests } from '@helpers/requests'
import { useIsMount } from '@helpers/hooks'

// components
import { Form, Label } from 'semantic-ui-react'
import { AsyncPaginate, withAsyncPaginate } from 'react-select-async-paginate'
import Creatable from 'react-select/creatable'

const CreatableAsyncPaginate = withAsyncPaginate(Creatable)

async function fetchData(endpoint, search, query, limit, page) {
    page = page || 1
    limit = limit || 10
    query = query || ''

    let pageQuery = `&page=${page}`
    if (search !== '') {
        pageQuery = ''
    }

    return await APIRequest({
        url: endpoint + '?search=' + search + `&paginate=true${pageQuery}&limit=` + limit + query,
        method: 'GET',
        data: {},
        private: true,
    })
}

const transformData = (data) => {
    return Object.entries(data).map(([key, value]) => ({
        value: key,
        label: value,
    }))
}

// eslint-disable-next-line
const putLabel = (color, text) => {
    return (
        <span>
            <Label size="tiny" circular style={{ backgroundColor: color, minWidth: '1em', minHeight: '1em' }} />
            <span>{text}</span>
        </span>
    )
}

const transformCustomData = (data) => {
    return Object.entries(data).map(([key, value, text]) => ({
        value: value.value,
        //label: value.color ? putLabel(value.color, value.text) : value.text
        label: value.text,
    }))
}

function mergeAndRemoveDuplicates(prevOptions, newOption) {
    // Merge the two arrays
    let combinedOptions = [...prevOptions] //[...prevOptions, ...newOption];

    for (let i = 0; i < newOption.length; i++) {
        if (combinedOptions.filter((item) => item.value === newOption[i].value).length === 0) {
            combinedOptions.push(newOption[i])
        }
    }

    return combinedOptions
}

const parseNestedData = (data, text) => {
    return data?.results?.map((item, index) => {
        let textValue
        if (typeof text === 'string') {
            const splitText = text.split('.')
            textValue = item
            splitText.forEach((key) => {
                textValue = textValue[key]
            })
        } else if (typeof text === 'function') {
            textValue = text(item)
        }
        return {
            label: textValue,
            value: item.id || item.value,
        }
    })
}

function generateOptions(source, attribute, as_display) {
    if ([undefined, null, ''].includes(source)) return []

    if (as_display) {
        // check if foo_display value exists
        if (source?.[attribute + '_display']) {
            return [
                {
                    label: source[attribute + '_display'],
                    value: source[attribute],
                },
            ]
        } else {
            return [
                {
                    label: source[attribute],
                    value: source[attribute],
                },
            ]
        }
    } else {
        if (typeof source === 'object' && Array.isArray(source)) {
            // array of objects
            return source.map((option) => ({
                label: option[attribute],
                value: option.id,
            }))
        }

        if (typeof source === 'object' && !Array.isArray(source)) {
            // single object
            return [
                {
                    label: source[attribute],
                    value: source.id,
                },
            ]
        }
    }

    return []
}

const calculatePageNumber = (total, loadedOptions, initialOptionsCount) => {
    // Assuming a default page size of 10 items per page
    const pageSize = 10

    // Calculate the number of items that have been loaded
    const loadedItems = loadedOptions.length - (initialOptionsCount || 0)

    // Calculate the current page based on loaded items
    const currentPage = Math.ceil(loadedItems / pageSize)

    // Determine the next page number to load
    const nextPage = currentPage + 1

    // Ensure the next page does not exceed the maximum number of pages
    const maxPage = Math.ceil(total / pageSize)

    return nextPage > maxPage ? null : nextPage
}

const ChoiceField2 = ({
    label,
    value,
    onChange,
    placeholder,
    customOptions,
    endpoint,
    text,
    type,
    exclude,
    addOptions,
    initialOptions,
    disabled,
    clearable,
    limit,
    multiple,
    additionalFilters,
    className,
    allowAdditions,
    additionsConfig,
    ...rest
}) => {
    const { t } = useTranslation()
    const isMount = useIsMount()
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState([])
    const [total, setTotal] = useState(undefined)
    const [excludeValues] = useState(exclude || [])
    const [initialOptionsCount, setInitalOptionsCount] = useState(0)
    const [selectedValue, setSelectedValue] = useState(multiple ? [] : '')

    limit = limit || 10

    const choices = useSelector((state) => state.choices)

    const getChoices = async (search, loadedOptions) => {
        setLoading(true)
        let result = null

        if (customOptions) {
            result = {
                status: 200,
                response: transformCustomData(customOptions),
            }
        } else if (type && choices[type]) {
            result = {
                status: 200,
                response: { [type]: choices[type] },
            }
        } else if (endpoint) {
            let page = total === undefined ? 1 : calculatePageNumber(total, options, initialOptionsCount)
            result = await fetchData(endpoint, search, additionalFilters, limit, page)
        }

        if (result && result.response && result.status === 200) {
            let optionsArray = []

            if (customOptions) {
                optionsArray = result.response
            } else if (type) {
                optionsArray = transformData(result.response[type], type)
            } else if (endpoint && text) {
                const data = result.response
                optionsArray = parseNestedData(data, text).filter((item) => !excludeValues.includes(item.value))
            }

            if (search) {
                if (typeof search === 'string') {
                    const searchLower = await search.toLowerCase()

                    optionsArray = optionsArray.filter(({ label }) => label.toLowerCase().includes(searchLower))
                } else if (typeof search === 'number') {
                    optionsArray = optionsArray.filter(({ value }) => value === search)
                }
            }

            let hasMore = false
            let slicedOptions = []
            if (endpoint) {
                slicedOptions = optionsArray
                if (options.length + result.response.results.length < result.response.count) {
                    hasMore = true
                }
            } else {
                slicedOptions = optionsArray.slice(loadedOptions.length, loadedOptions.length + 10)
            }

            setLoading(false)
            setOptions((prevOptions) => mergeAndRemoveDuplicates(prevOptions, optionsArray))
            if (endpoint) {
                if (total === undefined) {
                    setTotal(result.response.count)
                }
            }

            return { options: slicedOptions, hasMore: hasMore }
        }

        setLoading(false)
        return { options: [], hasMore: false }
    }

    const handleChange = (selectedOption) => {
        if (multiple) {
            const selectedValues = selectedOption ? selectedOption.map((opt) => opt.value) : []
            onChange(null, { value: selectedValues, label: '' })
        } else {
            onChange(null, { value: selectedOption ? selectedOption.value : '', label: '' })
        }
    }

    const handleCreate = async (inputValue) => {
        let createEndpoint = additionsConfig?.endpoint || endpoint
        let attribute = additionsConfig?.attribute || null
        setLoading(true)
        if (attribute) {
            const request = await requests.post(createEndpoint, {
                [attribute]: inputValue,
            })

            if (request.status === 201) {
                const response = request.response
                const newOption = { label: response[attribute], value: response.id }
                setOptions((prev) => [...prev, newOption])
                handleChange(multiple ? [...value, newOption.value] : newOption)
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        if (addOptions && addOptions.length > 0 && isMount) {
            setOptions((prevOptions) => mergeAndRemoveDuplicates(prevOptions, addOptions))
            setInitalOptionsCount(addOptions.length)
        } else if (initialOptions && isMount) {
            const newOptions = generateOptions(
                initialOptions.source,
                initialOptions.attribute,
                initialOptions.as_display
            )
            setOptions((prevOptions) => mergeAndRemoveDuplicates(prevOptions, newOptions))
            setInitalOptionsCount(newOptions.length)
        }
        // eslint-disable-next-line
    }, [addOptions])

    useEffect(() => {
        setLoading(true)
        setSelectedValue(value)
        setLoading(false)
    }, [value])

    useEffect(() => {
        if (additionalFilters && !isMount) {
            getChoices('', [])
        }
        // eslint-disable-next-line
    }, [additionalFilters])

    useEffect(() => {
        if (options.length === 1 && !multiple) {
            handleChange(options[0])
        }
        // eslint-disable-next-line
    }, [options])

    return (
        <Form.Field>
            <div style={{ marginBottom: '.28571429rem', marginTop: '-0.04rem' }}>{label}</div>
            {allowAdditions ? (
                <CreatableAsyncPaginate
                    debounceTimeout={300}
                    value={
                        multiple
                            ? options.filter((opt) => selectedValue?.includes(opt.value))
                            : options.find((option) => {
                                  if (typeof option.value === 'string') return option.value === value?.toString?.()
                                  if (typeof option.value === 'number') return option.value === parseInt(value)

                                  return option.value === value
                              })
                    }
                    loadOptions={getChoices}
                    onChange={handleChange}
                    onCreateOption={handleCreate}
                    isClearable={clearable === undefined ? true : clearable}
                    isLoading={loading}
                    isDisabled={disabled}
                    autoload={false}
                    options={options}
                    isMulti={multiple}
                    className={className}
                    placeholder={placeholder ? placeholder : t('select_option')}
                    closeMenuOnSelect={multiple ? false : true}
                    loadingMessage={() => t('loading')}
                    noOptionsMessage={() => t('no_options')}
                    {...rest}
                />
            ) : (
                <AsyncPaginate
                    debounceTimeout={300}
                    value={
                        multiple
                            ? options?.filter((opt) => selectedValue?.includes(opt.value))
                            : options?.find((option) => {
                                  if (typeof option.value === 'string') return option.value === value?.toString?.()
                                  if (typeof option.value === 'number') return option.value === parseInt(value)

                                  return option.value === value
                              })
                    }
                    loadOptions={getChoices}
                    onChange={handleChange}
                    isClearable={clearable === undefined ? true : clearable}
                    isLoading={loading}
                    isDisabled={disabled}
                    autoload={false}
                    options={options}
                    isMulti={multiple}
                    className={className}
                    placeholder={placeholder ? placeholder : t('select_option')}
                    closeMenuOnSelect={multiple ? false : true}
                    loadingMessage={() => t('loading')}
                    noOptionsMessage={() => t('no_options')}
                    {...rest}
                />
            )}
            {/* value !== null && total > options.length &&
                <small style={{ opacity: "0.9", color: "var(--black)" }}>{t('there_are_more_options_to_load')} {options.length} / {total}</small>
            */}
        </Form.Field>
    )
}

export default ChoiceField2
