import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { icons } from '@store/icons'
// components
import Icon from '@components/Icon'
import CanView from '@components/perms/CanView'
import BreadcrumbNav from '@components/BreadcrumbNav'
import { Container, Button, Divider } from 'semantic-ui-react'
// components
import Accommodations from './views/Accommodations'
import AccommodatedPersons from './views/AccommodatedPersons'

const Accommodation = () => {
    const { t } = useTranslation()
    const [view, setView] = useState(2)

    return (
        <CanView permissions={['accommodations.c_view_accommodation']} redirect>
            <BreadcrumbNav items={[{ name: t('accommodation') }]} />
            <Container fluid>
                <Button.Group basic size="small" style={{ borderRadius: '0' }} className="custom-filter-button-group">
                    <Button active={view === 2} onClick={() => setView(2)}>
                        <Icon name={'people-outline'} style={{ marginRight: '0.5rem' }} />
                        <span style={{ position: 'relative', top: '-0.1rem' }}>{t('accommodated_persons')}</span>
                    </Button>
                    <Button active={view === 1} onClick={() => setView(1)}>
                        <Icon name={icons.ACCOMMODATIONS} style={{ marginRight: '0.5rem' }} />
                        <span style={{ position: 'relative', top: '-0.1rem' }}>{t('hostels')}</span>
                    </Button>
                </Button.Group>
                <Divider style={{ marginBottom: 0 }} />
            </Container>

            {view === 1 && <Accommodations />}
            {view === 2 && <AccommodatedPersons />}
        </CanView>
    )
}

export default Accommodation
