import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { toast } from 'react-semantic-toasts'
// store
import { routes } from '@routes'
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { useHasPermission } from '@helpers/hooks'
import { thousandsSeparators } from '@helpers/functions'
// components
import Icon from '@components/Icon'
import ListView from '@components/ListView'
import AvatarList from '@components/AvatarList'
import Attachments from '@components/Attachments'
import SuperField from '@components/forms/SuperField'
import LabelsList from '@components/lists/LabelsList'
import { Form, Button, Popup, Label, Icon as SemanticIcon } from 'semantic-ui-react'
import GenerateDocuments from '@components/GenerateDocuments'
// shared components
import OrderState from '../shared/OrderState'
import OrderDetail from '../shared/OrderDetail'
// specific components
import HROrderForm from './components/HROrderForm'
import HROrderFulfillment from './components/HROrderFulfillment'

const HrOrder = ({ isPurchase }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector((store) => store.date_format)

    const [attachmentsOpen, setAttachmentsOpen] = useState(0)
    const canManage = useHasPermission('orders.c_manage_order')
    const [open, setOpen] = useState(null)
    const [action, setAction] = useState(null)

    const OrderDateRange = ({ order, lineBreak }) => {
        const { t } = useTranslation()
        return (
            <>
                {lineBreak && <br />}
                {order.date_from !== null && (
                    <Popup
                        position="top center"
                        trigger={
                            <span>
                                {' '}
                                <Icon name="calendar-outline" /> {moment(order.date_from).format(dateFormat)}{' '}
                            </span>
                        }
                        content={t('date_from')}
                    />
                )}
                {order.date_from !== null && order.date_to !== null && ' - '}
                {order.date_to !== null && (
                    <Popup
                        position="top center"
                        trigger={
                            <span>
                                {' '}
                                <Icon name="calendar-outline" /> {moment(order.date_to).format(dateFormat)}{' '}
                            </span>
                        }
                        content={t('date_to')}
                    />
                )}
            </>
        )
    }

    const onStatusChange = async (state, order, setData, setTotal, filters) => {
        const request = await requests.patch(API.ORDERS + order.id + '/', {
            is_active: state,
            resourcetype: 'HROrder',
        })
        if (request.status === 200) {
            if (filters.is_active.length === 0) {
                setData((prev) =>
                    prev.filter((item) => {
                        if (item.id === order.id) {
                            item.is_active = state
                        }

                        return item
                    })
                )
            } else {
                setData((prev) => prev.filter((item) => item.id !== order.id))
                setTotal((prev) => prev - 1)
            }

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('order_status_changed'),
                animation: 'pulse',
                time: 2000,
            })
        }
    }

    return (
        <ListView
            as="table"
            allowSearch
            allowSelection
            actionsCellWidth="2"
            isSoftDeleteAllowed
            endpoint={API.ORDERS}
            initialFilters={{
                is_purchase_order: isPurchase,
                ordering: '-created_on',
                account: '',
                labels: [],
                assigned_to: [],
                order_manager: '',
                owner: '',
                order_status: '',
                is_active: true,
                resource_type: 'hrorder',
            }}
            // onResponse={(response, filters) => {
            //     setFilters(filters)
            // }}
            listAdditionActions={() => [
                {
                    as: 'filter',
                    index: 0,
                    name: t('received_orders'),
                    filters: {
                        is_purchase_order: false,
                    },
                },
            ]}
            bulkActions={(selected) => [
                {
                    as: 'modal',
                    name: t('generate_documents'),
                    icon: 'document-text-outline',
                    modal: <GenerateDocuments source="orders.HrOrder" selected={selected} />,
                },
            ]}
            tableHeaders={[
                { title: t('order_num'), orderBy: 'order_number' },
                { title: t('name'), orderBy: 'name' },
                { title: t('account'), orderBy: 'business_detail__name' },
                { title: 'TTL/FLF/LFT' },
                { title: t('price_without_tax') },
                { title: t('labels') },
                { title: t('order_manager'), orderBy: 'order_manager__fullname' },
                { title: t('responsible_persons') },
                { title: t('state'), orderBy: 'order_status' },
                { title: t('documents') },
                { title: t('status') },
            ]}
            exports={[
                {
                    endpoint: API.EXPORTS + 'dynamic_hr_order/',
                    permissions: ['orders.c_view_order'],
                    apply_filters: true,
                    type: 'xlsx',
                    filename: 'hr_orders',
                },
            ]}
            renderFilterFields={(filters, setFilters) => (
                <>
                    <SuperField
                        as="choice-select"
                        search
                        text="name"
                        label={t('account')}
                        value={filters.account}
                        endpoint={API.ACCOUNTS + 'business_details/'}
                        additionalFilters={'&query={id, name}&is_account=true&is_active=true&exclude_unit=true'}
                        onChange={(e, { value }) => setFilters({ ...filters, account: value })}
                    />
                    <Form.Group widths="equal">
                        <SuperField
                            as="choice-select"
                            search
                            endpoint={API.EMPLOYEES}
                            label={t('order_manager')}
                            value={filters.order_manager}
                            text="fullname_with_titles"
                            additionalFilters="&only_basic_info=true&is_active=true"
                            onChange={(e, { value }) => setFilters({ ...filters, order_manager: value })}
                        />
                        <SuperField
                            as="choice-select"
                            search
                            text="name"
                            label={t('owner')}
                            value={filters.owner}
                            endpoint={API.UNITS}
                            additionalFilters={'&only_from_active_company=true&is_business=true&query={id, name}'}
                            onChange={(e, { value }) => setFilters({ ...filters, owner: value })}
                        />
                    </Form.Group>
                    <SuperField
                        as="choice-select"
                        search
                        multiple
                        label={t('responsible_persons')}
                        value={filters.assigned_to}
                        endpoint={API.EMPLOYEES}
                        text="fullname_with_titles"
                        additionalFilters={'&only_basic_info=true&is_active=true'}
                        onChange={(e, { value }) => setFilters({ ...filters, assigned_to: value })}
                    />

                    <SuperField
                        as="choice-select"
                        search
                        multiple
                        text="name"
                        label={t('labels')}
                        value={filters.labels}
                        endpoint={API.ORDERS + 'labels/'}
                        additionalFilters={'&query={id, name}'}
                        onChange={(e, { value }) => setFilters({ ...filters, labels: value })}
                    />
                    <Form.Group widths="equal">
                        <SuperField
                            as="choice-select"
                            label={t('state')}
                            value={filters.order_status}
                            type="order_status_choices"
                            onChange={(e, { value }) => setFilters({ ...filters, order_status: value })}
                        />
                        <SuperField
                            as="choice"
                            label={t('status')}
                            value={filters.is_active}
                            customOptions={[
                                { key: 1, value: true, text: t('active_order') },
                                { key: 2, value: false, text: t('closed_order') },
                            ]}
                            onChange={(e, { value }) => setFilters({ ...filters, is_active: value })}
                        />
                    </Form.Group>
                </>
            )}
            actions={[
                {
                    as: 'modal',
                    type: 'add',
                    name: t('create_order'),
                    modal: <HROrderForm />,
                    permissions: ['orders.c_manage_order'],
                },
                {
                    as: 'modal',
                    type: 'custom',
                    icon: 'add-circle-outline',
                    name: t('create_fulfillment'),
                    modalSize: 'small',
                    //disabled: true,
                    customIconColor: 'var(--dark)',
                    modal: <HROrderFulfillment />,
                    permissions: ['orders.c_manage_order_fulfillment'],
                },
                {
                    as: 'modal',
                    type: 'custom',
                    modalSize: 'large',
                    icon: 'eye-outline',
                    name: t('order_detail'),
                    customIconColor: 'var(--primary)',
                    modal: (item) => <OrderDetail id={item.id} />,
                    permissions: ['orders.c_view_order'],
                },
                {
                    as: 'modal',
                    type: 'edit',
                    name: t('edit'),
                    permissions: ['orders.c_manage_order'],
                    modal: <HROrderForm />,
                },
                {
                    name: t('delete'),
                    type: 'delete',
                    as: 'delete',
                    text: t('delete_order'),
                    permissions: ['orders.c_delete_order'],
                },
            ]}
            renderCells={(order, setData, setTotal, idx, filters) => [
                { width: 1, content: order.order_number },
                {
                    customWidth: '150px',
                    content: (
                        <>
                            <strong title={order.name}> {order.name} </strong>
                            <small>
                                <OrderDateRange order={order} lineBreak />
                            </small>
                        </>
                    ),
                },
                {
                    width: 1,
                    content: (
                        <>
                            {order?.business_detail?.id ? (
                                <>
                                    <Link target="_blank" to={routes.ACCOUNTS + order.business_detail.id}>
                                        {order.business_detail?.name}
                                    </Link>
                                </>
                            ) : (
                                '--'
                            )}
                        </>
                    ),
                },
                {
                    content: (
                        <>
                            {order?.fulfillment_stats ? (
                                <span style={{ fontSize: '1.2rem' }}>
                                    {order.fulfillment_stats.total_amount}
                                    {' / '}
                                    {order.fulfillment_stats.already_fulfilled}
                                    {' / '}
                                    {order.fulfillment_stats.remaining_amount}
                                </span>
                            ) : (
                                order?.amount
                            )}
                            <br />
                            <span>
                                {t('fee')}: <strong>{order?.fee?.sum + ' ' + order?.fee?.currency}</strong>
                            </span>
                        </>
                    ),
                },
                {
                    content: (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {thousandsSeparators(parseFloat((order?.fee?.sum || 0) * order.amount)?.toFixed(2) || 0) +
                                ' ' +
                                order?.fee?.currency}
                            <sup style={{ marginLeft: '0.5rem' }}>
                                <Popup
                                    basic
                                    trigger={<Icon name="information-circle" style={{ fontSize: '1rem' }} />}
                                    content={
                                        <div>
                                            <strong>{t('total_fulfilled')}: </strong> <br />
                                            {thousandsSeparators(
                                                parseFloat(
                                                    (order?.fee?.sum || 0) * (order?.total_fulfillments || 0)
                                                ).toFixed(2)
                                            ) +
                                                ' ' +
                                                order?.fee?.currency}
                                        </div>
                                    }
                                />
                            </sup>
                        </div>
                    ),
                },
                {
                    customWidth: '200px',
                    content: (
                        <LabelsList
                            key={order.id}
                            rowID={order.id}
                            labels={order.labels}
                            endpoint={API.ORDERS}
                            additionalFilters="&lead_tag=false"
                            resourcetype={order.resourcetype}
                            endpointLabels={API.ORDERS + 'labels/'}
                            label_key={'labels'}
                        />
                    ),
                },
                {
                    width: 2,
                    content: (
                        <>
                            {order?.order_manager?.id ? (
                                <Link target="_blank" to={routes.EMPLYOEES_DETAIL + order?.order_manager?.id}>
                                    <span>{order?.order_manager?.fullname}</span>
                                </Link>
                            ) : (
                                '--'
                            )}
                        </>
                    ),
                },
                {
                    width: 2,
                    content: (
                        <AvatarList
                            size="25"
                            alignDirection="start"
                            persons={order.assigned_to.map((item) => ({
                                id: item.id,
                                name: item.fullname,
                                avatar: item.profile_picture,
                                displayName: item?.fullname_with_titles || item.fullname,
                            }))}
                        />
                    ),
                },
                {
                    width: 2,
                    content: (
                        <OrderState
                            order={order}
                            setData={setData}
                            setTotal={setTotal}
                            filters={filters}
                            type={'HROrder'}
                        />
                    ),
                },
                {
                    content: (
                        <Label basic>
                            <Popup
                                style={{ zIndex: '1000', minWidth: '450px' }}
                                open={attachmentsOpen === order.id}
                                onOpen={() => setAttachmentsOpen(order.id)}
                                hoverable
                                position="left center"
                                trigger={
                                    <div style={{ cursor: 'pointer' }}>
                                        <Icon name="reader-outline" style={{ marginRight: '0.5rem' }} />
                                        <span style={{ position: 'relative', top: '-0.1rem' }}>
                                            {order.attachments.length}
                                        </span>
                                    </div>
                                }
                                content={
                                    <Attachments
                                        viewOnly={!canManage}
                                        record={order}
                                        setData={setData}
                                        allowFolders
                                        moduleName={'HROrder'}
                                        sourceEndpoint={API.ORDERS}
                                        closeView={() => setAttachmentsOpen(0)}
                                        additionalData={{ resourcetype: 'HROrder' }}
                                    />
                                }
                            />
                        </Label>
                    ),
                },
                {
                    content: canManage ? (
                        <Popup
                            basic
                            hoverable
                            position="bottom center"
                            style={{ padding: 0 }}
                            open={open === order?.id}
                            onOpen={() => setOpen(order?.id)}
                            onClose={() => {
                                if (open === order?.id && action === 'edit_status') {
                                    setOpen(order?.id)
                                } else {
                                    setOpen(null)
                                    setAction(null)
                                }
                            }}
                            trigger={
                                <div>
                                    <SemanticIcon
                                        size="small"
                                        name="circle"
                                        style={{
                                            color: order.is_active ? 'green' : 'red',
                                            position: 'relative',
                                            top: '-0.1rem',
                                        }}
                                    />
                                    {order.is_active ? t('active') : t('closed')}
                                    <Icon
                                        name="chevron-down"
                                        style={{
                                            marginLeft: '0.4rem',
                                            position: 'relative',
                                            top: '0.2rem',
                                        }}
                                    />
                                </div>
                            }
                            content={
                                <div
                                    style={{
                                        width: '100%',
                                        textAlign: 'left',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Button
                                        basic
                                        size="tiny"
                                        type="button"
                                        style={{ marginRight: 0 }}
                                        active={order.is_active}
                                        key={order.is_active}
                                        content={
                                            <>
                                                <SemanticIcon
                                                    size="small"
                                                    name="circle"
                                                    style={{
                                                        color: 'green',
                                                        position: 'relative',
                                                        top: '-0.1rem',
                                                    }}
                                                />
                                                {t('active')}
                                            </>
                                        }
                                        onClick={() => onStatusChange(true, order, setData, setTotal, filters)}
                                    />
                                    <Button
                                        basic
                                        size="tiny"
                                        type="button"
                                        style={{ marginRight: 0 }}
                                        active={order.is_active === false}
                                        key={order.is_active}
                                        content={
                                            <>
                                                <SemanticIcon
                                                    size="small"
                                                    name="circle"
                                                    style={{
                                                        color: 'red',
                                                        position: 'relative',
                                                        top: '-0.1rem',
                                                    }}
                                                />
                                                {t('closed')}
                                            </>
                                        }
                                        onClick={() => onStatusChange(false, order, setData, setTotal, filters)}
                                    />
                                </div>
                            }
                        />
                    ) : (
                        <Label
                            style={{
                                color: 'var(--light)',
                                background: order.is_active ? 'green' : 'red',
                                cursor: 'pointer',
                            }}
                        >
                            <span style={{ position: 'relative', top: '0.1rem' }}>
                                {order.is_active ? t('active') : t('closed')}
                            </span>
                        </Label>
                    ),
                },
            ]}
        />
    )
}

export default HrOrder
