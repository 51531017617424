import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { createRequest } from '@services/ServiceCommon'
// components
import { Form } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const AgreementForm = ({ onClose, onConfirm, setData, setTotal }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)
    const [radioView, setRadioView] = useState(1)
    const [form, setForm] = useState({
        profile: '',
        gdpr: '',
    })

    const handleSubmit = async () => {
        setIsProcessing(true)
        const request = await createRequest(API.API_URL + '/common/gdpr_agreements_manual/', form)
        if (request.status === 201) {
            onConfirm(request.response, setData, setTotal)
            onClose()
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group>
                <SuperField
                    as="radio"
                    label={t('candidates')}
                    checked={radioView === 1}
                    onChange={() => setRadioView(1)}
                />
                <SuperField
                    as="radio"
                    label={t('employees')}
                    checked={radioView === 2}
                    onChange={() => setRadioView(2)}
                />
            </Form.Group>
            {radioView === 1 ? (
                <SuperField
                    as="choice-select"
                    key={radioView}
                    required
                    search
                    endpoint={API.CANDIDATES}
                    additionalFilters={`&only_basic_info=true`}
                    text="fullname_with_titles"
                    label={t('candidates')}
                    value={form.person}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, profile: value }))}
                />
            ) : (
                <SuperField
                    as="choice-select"
                    search
                    placeholder={t('employees')}
                    key={radioView}
                    required
                    endpoint={API.EMPLOYEES}
                    additionalFilters={`&only_basic_info=true`}
                    text="fullname_with_titles"
                    label={t('employees')}
                    value={form.person}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, profile: value }))}
                />
            )}
            <SuperField
                as="choice-select"
                required
                label={t('gdpr')}
                endpoint={API.API_URL + '/common/gdprs/'}
                additionalFilters={`&query={id, title}`}
                text="title"
                value={form.gdpr}
                onChange={(e, { value }) => setForm({ ...form, gdpr: value })}
            />

            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel disabled={isProcessing} onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing || form.profile === '' || form.gdpr === ''}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    )
}

export default AgreementForm
