import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { requests } from '@helpers/requests'
import { API } from '@store/config'
// components
import { Divider, Form } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const BulkSecondPauseForm = ({ selected, setSelected, setData, onClose }) => {
    const { t } = useTranslation()

    const [isProcessing, setIsProcessing] = useState(false)
    const [allowSecondBreak, setAllowSecondBreak] = useState(true)

    const onSubmit = async () => {
        setIsProcessing(true)
        let updated = []

        for (let i = 0; i < selected.length; i++) {
            const request = await requests.patch(API.CONTRACTS + selected[i].id + '/', {
                allow_second_break: allowSecondBreak,
            })
            if (request.status === 200) {
                updated.push(request.response)
            }
        }

        setData((prev) =>
            prev.filter((item) => {
                const itemToUpdate = updated.find((updatedItem) => updatedItem.id === item.id)
                if (item.id === itemToUpdate?.id) {
                    item.allow_second_break = itemToUpdate.allow_second_break
                }
                return item
            })
        )

        toast({
            type: 'success',
            icon: 'check circle',
            title: t('second_pause_setting_changed'),
            animation: 'pulse',
            time: 2000,
        })

        onClose()
        setSelected([])
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={onSubmit}>
            <div style={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>{t('select_action_to_perform')}</div>
            <SuperField
                as="checkbox"
                label={t('allow_second_break_for_attendance_tracking')}
                checked={allowSecondBreak === true}
                onChange={() => setAllowSecondBreak(true)}
            />
            <SuperField
                as="checkbox"
                label={t('disallow_second_break_for_attendance_tracking')}
                checked={allowSecondBreak === false}
                onChange={() => setAllowSecondBreak(false)}
            />
            <br />
            <strong>{t('selected_records')}:</strong> {selected.length}
            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit disabled={isProcessing} loading={isProcessing} text={t('confirm')} />
            </Form.Field>
        </Form>
    )
}

export default BulkSecondPauseForm
