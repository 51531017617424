import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { requests } from '@helpers/requests'
import { isEmpty } from '@helpers/validation'
import { API } from '@store/config'
// components
import { Form, Divider, Checkbox, Header, Segment } from 'semantic-ui-react'
import Icon from '@components/Icon'
import SuperField from '@components/forms/SuperField'
import EmailField from '@components/forms/common/EmailField'
import PhoneField from '@components/forms/common/PhoneField'
import Address from '@components/forms/Address'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const AbroadForm = ({ onClose, setData, setTotal, item, setHeader }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)
    const [showAdditionalInfo, setShowAdditionalInfo] = useState(false)
    const [showAdditionalInfoB, setShowAdditionalInfoB] = useState(false)
    const [showWorkPosition, setShowWorkPosition] = useState(false)

    const [contract, setContract] = useState(item?.contract || null)

    const [workAddress, setWorkAddress] = useState({
        street: item?.work_address?.street || '',
        city: item?.work_address?.city || '',
        number: item?.work_address?.number || '',
        orientation_number: item?.work_address?.orientation_number || '',
        postcode: item?.work_address?.postcode || '',
        country: item?.work_address?.country || null,
        state: item?.work_address?.state || '',
        address_line: item?.work_address?.address_line || '',
    })
    const [businessDetail, setBusinessDetail] = useState({
        name: '',
        identification_number: '',
        dit_number: '',
        vat_number: '',
        business_register_folder: '',
        nace_code: '',
        is_account: true,
    })
    const [viewAbroadEmployer, setViewAbroadEmployer] = useState(false)

    const [form, setForm] = useState({
        id: item?.id || 0,
        employee: item?.employee?.id || '',
        work_position_month_before_sending: item?.work_position_month_before_sending || '',
        abroad_country: item?.abroad_country || '',
        employment_type: item?.employment_type || null,
        inland_employer: item?.inland_employer?.id || '',
        inland_employer_contact_person: item?.inland_employer_contact_person || '',
        inland_employer_contact_person_phone: item?.inland_employer_contact_person_phone || '',
        inland_employer_contact_person_email: item?.inland_employer_contact_person_email || '',
        employee_declaration_of_honor_at: item?.employee_declaration_of_honor_at || null,
        employee_declaration_of_honor_place: item?.employee_declaration_of_honor_place || '',
        employer_declaration_of_honor_place: item?.employer_declaration_of_honor_place || '',
        employer_declaration_of_honor_at: item?.employer_declaration_of_honor_at || null,
        abroad_employer: item?.abroad_employer?.id || '',
        contract: item?.contract?.id || '',
        // is_draft: item?.is_draft || false,
        is_agenture: item?.is_agenture || false,
        employer_follows_slovak_policies: item?.employer_follows_slovak_policies || false,
        working_in_benefit_of_sender: item?.working_in_benefit_of_sender || false,
        assigned_to_different_subject: item?.assigned_to_different_subject || false,
        rewarded_by_sending_employer: item?.rewarded_by_sending_employer || false,
        rewarded_by: item?.rewarded_by || '',
        work_nature_decided_by_sending_employer: item?.work_nature_decided_by_sending_employer || false,
        work_nature_decided_by: item?.work_nature_decided_by || '',
        is_replacement: item?.is_replacement || false,
        replacement_reason: item?.replacement_reason || '',
        work_abroad_from: item?.work_abroad_from || null,
        work_abroad_to: item?.work_abroad_to || null,
        employees_count_all: item?.employees_count_all || 0,
        employees_count_inland: item?.employees_count_inland || 0,
        employees_count_abroad: item?.employees_count_abroad || 0,
        administrative_employees_count: item?.administrative_employees_count || 0,
        given_employees_to_another_subject_count: item?.given_employees_to_another_subject_count || 0,
        expected_turnover_abroad: item?.expected_turnover_abroad || 0,
        expected_turnover_in_sk: item?.expected_turnover_in_sk || 0,
        business_count_inland: item?.business_count_inland || 0,
        business_count_abroad: item?.business_count_abroad || 0,
        work_address: workAddress,
        work_abroad_contact_person: item?.work_abroad_contact_person || '',
        work_abroad_contact_person_phone: item?.work_abroad_contact_person_phone || '',
        work_abroad_contact_person_email: item?.work_abroad_contact_person_email || '',
        work_activity_abroad: item?.work_activity_abroad || '',
        work_activity_nace_code: item?.work_activity_nace_code || '',
        boat_information: item?.boat_information || '',
        worked_abroad_in_past_in_that_country: item?.worked_abroad_in_past_in_that_country || false,
        worked_abroad_in_past_from: item?.worked_abroad_in_past_from || null,
        worked_abroad_in_past_to: item?.worked_abroad_in_past_to || null,
        document_was_issued_in_other_country: item?.document_was_issued_in_other_country || false,
        document_was_issued_in_other_country_at: item?.document_was_issued_in_other_country_at || null,
        document_was_issued_in_other_country_from: item?.document_was_issued_in_other_country_from || null,
        document_was_issued_in_other_country_to: item?.document_was_issued_in_other_country_to || null,
        document_was_issued_in_other_country_by: item?.document_was_issued_in_other_country_by || '',
        additional_info: item?.additional_info || '',
        unit: item?.unit?.id || '',
    })

    useEffect(() => {
        async function fetchData() {
            if (form.inland_employer) {
                let employer = ''
                let rewardedBy = ''
                let decidedBy = ''

                const request = await requests.get(API.BUSINESS_DETAIL + form.inland_employer + '/')
                if (request.status === 200) {
                    employer = request.response.name

                    if (!form.rewarded_by_sending_employer) rewardedBy = employer
                    if (!form.work_nature_decided_by_sending_employer) decidedBy = employer
                }
                setForm({ ...form, rewarded_by: rewardedBy, work_nature_decided_by: decidedBy })
            } else {
                setForm({ ...form, work_nature_decided_by: '', rewarded_by: '' })
            }
        }
        fetchData()
        // eslint-disable-next-line
    }, [form.inland_employer])

    useEffect(() => {
        async function fetchData() {
            if (form.contract) {
                const request = await requests.get(
                    API.CONTRACTS +
                        form.contract +
                        '/?query={id,employee{id,fullname_with_titles},employer{id,name,identification_number},unit{id,name}}'
                )
                if (request.status === 200) {
                    const contract = request.response
                    setContract(contract)
                    setForm({
                        ...form,
                        employee: contract?.employee?.id,
                        inland_employer: contract?.employer?.id,
                        employment_type: '1',
                        work_position_month_before_sending: contract?.work_position?.title,
                        unit: contract?.unit?.id,
                    })
                }
            }
        }
        fetchData()
        // eslint-disable-next-line
    }, [form.contract])

    useEffect(() => {
        async function fetchData() {
            if (form.abroad_employer) {
                const request = await requests.get(API.BUSINESS_DETAIL + form.abroad_employer + '/')
                if (request.status === 200) {
                    const employer = request.response

                    // if(employer.billing_address) setWorkAddress(employer.billing_address)
                    // else if(employer.shipping_address) setWorkAddress(employer.shipping_address)

                    const naceCode = employer.nace_code ? employer.nace_code.id : ''
                    const primaryContact =
                        employer?.contacts?.find((item) => item.is_primary === true) || employer.contacts[0]

                    setForm({
                        ...form,
                        work_activity_nace_code: naceCode,
                        work_abroad_contact_person: primaryContact?.contact_person_name || '',
                        work_abroad_contact_person_email: primaryContact?.email || '',
                        work_abroad_contact_person_phone: primaryContact?.phone || '',
                    })
                }
            }
        }
        fetchData()
        // eslint-disable-next-line
    }, [form.abroad_employer])

    useEffect(() => {
        async function fetchData() {
            if (workAddress.country) {
                setForm({ ...form, abroad_country: workAddress.country })
            }
        }
        fetchData()
        // eslint-disable-next-line
    }, [workAddress.country])

    const onCreate = (response, setData, setTotal) => {
        setData((prev) => [response, ...prev])
        setTotal((prev) => prev + 1)
        onClose()
    }
    const onUpdate = async (response, setData) => {
        setData((prev) =>
            prev.map((branche) => {
                if (branche.id === response.id) {
                    branche = response
                }
                return branche
            })
        )
        onClose()
    }

    const handleSubmit = async () => {
        setIsProcessing(true)

        let abroadEmployer = form.abroad_employer
        if (businessDetail.name && businessDetail.identification_number) {
            const request = await requests.post(API.ACCOUNTS + 'business_details/', businessDetail)
            if (request.status === 201) {
                abroadEmployer = request.response.id
            }
        }
        if (form?.id) {
            const request = await requests.patch(API.ABROADS + form.id + '/', {
                ...form,
                abroad_employer: abroadEmployer,
                work_address: workAddress,
                is_draft: false,
            })
            if (request.status === 200) {
                onUpdate(request.response, setData)
            }
        } else {
            const request = await requests.post(API.ABROADS, {
                ...form,
                abroad_employer: abroadEmployer,
                work_address: workAddress,
                is_draft: false,
            })
            if (request.status === 201) {
                onCreate(request.response, setData, setTotal)
            }
        }

        setIsProcessing(false)
        onClose()
    }

    useEffect(() => {
        if (item?.id !== undefined) setHeader(t('update'))
        // eslint-disable-next-line
    }, [])

    return (
        <Form onSubmit={handleSubmit}>
            {item?.id === undefined ? (
                <SuperField
                    as="choice-select"
                    search
                    required
                    label={t('posting_employee')}
                    value={form.contract}
                    endpoint={API.CONTRACTS}
                    additionalFilters={'&status=1&status=3&status=6&only_basic_info=true'}
                    text={(item) =>
                        `${item.employee.fullname_with_titles} - ${item.employer.name} - ${item.employer.identification_number}`
                    }
                    onChange={(e, { value }) => setForm({ ...form, contract: value })}
                />
            ) : (
                <Header
                    as="h4"
                    content={`${contract?.employee?.fullname_with_titles} - ${contract?.employer?.name} - ${contract?.employer?.identification_number}`}
                />
            )}

            <Header
                as="h5"
                style={{
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem',
                    cursor: 'pointer',
                    opacity: '0.8',
                    color: 'var(--primary)',
                }}
                onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}
            >
                <Icon
                    name={`chevron-${showAdditionalInfo ? 'down' : 'forward'}-outline`}
                    style={{ marginRight: '0.5rem', position: 'relative', top: '0.2rem' }}
                />
                <span>{t('additional_information')}</span>
            </Header>

            {showAdditionalInfo && (
                <Segment>
                    <SuperField
                        as="choice-select"
                        search
                        required
                        text="name"
                        label={t('inland_employer')}
                        value={form.inland_employer}
                        endpoint={API.BUSINESS_DETAIL}
                        additionalFilters="&is_employer=true&query={id,name}"
                        onChange={(e, { value }) => setForm({ ...form, inland_employer: value })}
                        initialOptions={{
                            attribute: 'name',
                            source: contract?.employer,
                        }}
                    />

                    <Form.Group widths={'equal'}>
                        <SuperField
                            as="input"
                            placeholder={t('contact_person')}
                            label={t('contact_person')}
                            value={form.inland_employer_contact_person}
                            onChange={(e, { value }) => setForm({ ...form, inland_employer_contact_person: value })}
                        />
                        <PhoneField
                            hideType
                            placeholder={t('phone')}
                            value={form.inland_employer_contact_person_phone}
                            setValue={(e, { value }) =>
                                setForm({ ...form, inland_employer_contact_person_phone: value })
                            }
                        />
                        <EmailField
                            label={t('email')}
                            placeholder={t('email')}
                            value={form.inland_employer_contact_person_email}
                            setValue={(e, { value }) =>
                                setForm({ ...form, inland_employer_contact_person_email: value })
                            }
                        />
                    </Form.Group>

                    <Divider />

                    <SuperField
                        as="choice-select"
                        search
                        required
                        text="fullname_with_titles"
                        label={t('employee')}
                        value={form.employee}
                        endpoint={API.EMPLOYEES}
                        additionalFilters={'&only_basic_info=true'}
                        onChange={(e, { value }) => setForm({ ...form, employee: value })}
                        initialOptions={{
                            attribute: 'fullname_with_titles',
                            source: contract?.employee,
                        }}
                    />
                    <Form.Group widths={'equal'}>
                        <SuperField
                            as="choice-select"
                            type="employment_type"
                            label={t('employment_type')}
                            value={form.employment_type}
                            onChange={(e, { value }) => setForm({ ...form, employment_type: value })}
                            initialOptions={{
                                attribute: 'employment_type',
                                as_display: true,
                                source: item,
                            }}
                        />
                        <SuperField
                            as="choice-select"
                            search
                            text="name"
                            value={form.unit}
                            label={t('unit')}
                            endpoint={API.UNITS}
                            onChange={(e, { value }) => setForm({ ...form, unit: value })}
                            additionalFilters={'&query={id, name}&only_from_active_company=true'}
                            initialOptions={{
                                attribute: 'name',
                                source: contract?.unit,
                            }}
                        />
                    </Form.Group>
                </Segment>
            )}

            <Divider />

            <Header as={'h4'} content={t('abroad_employer')} />
            <Form.Group>
                <Form.Field>
                    <Checkbox
                        radio
                        label={t('choose_from_existing')}
                        checked={!viewAbroadEmployer}
                        onChange={() => setViewAbroadEmployer(false)}
                    />
                </Form.Field>
                <Form.Field>
                    <Checkbox
                        radio
                        label={t('create_new_one')}
                        checked={viewAbroadEmployer}
                        onChange={() => setViewAbroadEmployer(true)}
                    />
                </Form.Field>
            </Form.Group>

            {!viewAbroadEmployer ? (
                <SuperField
                    as="choice-select"
                    search
                    required
                    label={t('abroad_employer')}
                    value={form.abroad_employer}
                    endpoint={API.BUSINESS_DETAIL}
                    text={(item) => item?.name}
                    additionalFilters="&is_account=true&query={id,name}"
                    initialOptions={{
                        attribute: 'name',
                        source: item?.abroad_employer,
                    }}
                    onChange={(e, { value }) => setForm({ ...form, abroad_employer: value })}
                />
            ) : (
                <>
                    <SuperField
                        required
                        as="input"
                        placeholder={t('name')}
                        label={t('name')}
                        value={businessDetail.name}
                        onChange={(e, { value }) => setBusinessDetail({ ...businessDetail, name: value })}
                    />
                    <Form.Group widths={'equal'}>
                        <SuperField
                            required
                            as="input"
                            placeholder={t('id_number')}
                            label={t('id_number')}
                            value={businessDetail.identification_number}
                            onChange={(e, { value }) =>
                                setBusinessDetail({ ...businessDetail, identification_number: value })
                            }
                        />
                        <SuperField
                            as="input"
                            placeholder={t('dit_number')}
                            label={t('dit_number')}
                            value={businessDetail.dit_number}
                            onChange={(e, { value }) => setBusinessDetail({ ...businessDetail, dit_number: value })}
                        />
                        <SuperField
                            as="input"
                            placeholder={t('vat_number')}
                            label={t('vat_number')}
                            value={businessDetail.vat_number}
                            onChange={(e, { value }) => setBusinessDetail({ ...businessDetail, vat_number: value })}
                        />
                    </Form.Group>
                    <Form.Group widths={'equal'}>
                        <SuperField
                            as="input"
                            placeholder={t('business_register_folder')}
                            label={t('business_register_folder')}
                            value={businessDetail.business_register_folder}
                            onChange={(e, { value }) =>
                                setBusinessDetail({ ...businessDetail, business_register_folder: value })
                            }
                        />
                    </Form.Group>
                </>
            )}
            <Divider />

            <div style={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>{t('work_abroad_address')}</div>
            <Address isForm slashPushFromTop="0.5rem" address={workAddress} setAddress={setWorkAddress} />

            <Form.Group widths={'equal'}>
                <SuperField
                    as="input"
                    placeholder={t('contact_person')}
                    label={t('contact_person')}
                    value={form.work_abroad_contact_person}
                    onChange={(e, { value }) => setForm({ ...form, work_abroad_contact_person: value })}
                />
                <PhoneField
                    hideType
                    placeholder={t('phone')}
                    value={form.work_abroad_contact_person_phone}
                    setValue={(e, { value }) => setForm({ ...form, work_abroad_contact_person_phone: value })}
                />
                <EmailField
                    label={t('email')}
                    placeholder={t('email')}
                    value={form.work_abroad_contact_person_email}
                    setValue={(e, { value }) => setForm({ ...form, work_abroad_contact_person_email: value })}
                />
            </Form.Group>

            <SuperField
                as="input"
                placeholder={t('boat_information')}
                label={t('boat_information')}
                value={form.boat_information}
                onChange={(e, { value }) => setForm({ ...form, boat_information: value })}
            />

            <Form.Group widths={'equal'}>
                <SuperField
                    as="datepicker"
                    size="small"
                    label={t('work_abroad_from')}
                    value={form.work_abroad_from}
                    style={{ height: '2.75rem' }}
                    onChange={(e, { value }) => setForm({ ...form, work_abroad_from: value })}
                />
                <SuperField
                    as="datepicker"
                    size="small"
                    label={t('work_abroad_to')}
                    value={form.work_abroad_to}
                    style={{ height: '2.75rem' }}
                    onChange={(e, { value }) => setForm({ ...form, work_abroad_to: value })}
                />
            </Form.Group>

            <Form.Group widths={'equal'}>
                <SuperField
                    as="input"
                    placeholder={t('work_activity_abroad')}
                    label={t('work_activity_abroad')}
                    value={form.work_activity_abroad}
                    onChange={(e, { value }) => setForm({ ...form, work_activity_abroad: value })}
                />
                <SuperField
                    as="choice-select"
                    search
                    placeholder={t('nace_code')}
                    label={t('nace_code')}
                    endpoint={API.NACE_CODE}
                    text={(item) => item?.code + ' - ' + item?.name}
                    additionalFilters="&query={id, code, name}"
                    value={form.work_activity_nace_code}
                    onChange={(e, { value }) => setForm({ ...form, work_activity_nace_code: value })}
                />
            </Form.Group>

            <Divider />

            <Header
                as="h5"
                style={{
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem',
                    cursor: 'pointer',
                    opacity: '0.8',
                    color: 'var(--primary)',
                }}
                onClick={() => setShowAdditionalInfoB(!showAdditionalInfoB)}
            >
                <Icon
                    name={`chevron-${showAdditionalInfoB ? 'down' : 'forward'}-outline`}
                    style={{ marginRight: '0.5rem', position: 'relative', top: '0.2rem' }}
                />
                <span>{t('additional_information_document')}</span>
            </Header>

            {showAdditionalInfoB && (
                <Segment>
                    <Header as="h5" content={t('worked_abroad_in_past_in_that_country')} />
                    <Form.Group>
                        <Form.Field>
                            <Checkbox
                                radio
                                label={t('yes')}
                                checked={form.worked_abroad_in_past_in_that_country}
                                onChange={() => setForm({ ...form, worked_abroad_in_past_in_that_country: true })}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                radio
                                label={t('no')}
                                checked={!form.worked_abroad_in_past_in_that_country}
                                onChange={() => setForm({ ...form, worked_abroad_in_past_in_that_country: false })}
                            />
                        </Form.Field>
                    </Form.Group>
                    {form.worked_abroad_in_past_in_that_country && (
                        <Form.Group widths={'equal'}>
                            <SuperField
                                as="datepicker"
                                size="small"
                                label={t('date_from')}
                                value={form.worked_abroad_in_past_from}
                                style={{ height: '2.75rem' }}
                                onChange={(e, { value }) => setForm({ ...form, worked_abroad_in_past_from: value })}
                            />
                            <SuperField
                                as="datepicker"
                                size="small"
                                label={t('date_to')}
                                value={form.worked_abroad_in_past_to}
                                style={{ height: '2.75rem' }}
                                onChange={(e, { value }) => setForm({ ...form, worked_abroad_in_past_to: value })}
                            />
                        </Form.Group>
                    )}

                    <Divider />

                    <Header as={'h5'} content={t('document_was_issued_in_other_country')} style={{ marginTop: 0 }} />
                    <Form.Group>
                        <Form.Field>
                            <Checkbox
                                radio
                                label={t('yes')}
                                checked={form.document_was_issued_in_other_country}
                                onChange={() => setForm({ ...form, document_was_issued_in_other_country: true })}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                radio
                                label={t('no')}
                                checked={!form.document_was_issued_in_other_country}
                                onChange={() => setForm({ ...form, document_was_issued_in_other_country: false })}
                            />
                        </Form.Field>
                    </Form.Group>
                    {form.document_was_issued_in_other_country && (
                        <>
                            <Form.Group widths={'equal'}>
                                <SuperField
                                    as="datepicker"
                                    size="small"
                                    label={t('issued_date')}
                                    value={form.document_was_issued_in_other_country_at}
                                    style={{ height: '2.75rem' }}
                                    onChange={(e, { value }) =>
                                        setForm({ ...form, document_was_issued_in_other_country_at: value })
                                    }
                                />
                                <SuperField
                                    as="input"
                                    placeholder={t('issued_by_employer')}
                                    label={t('issued_by_employer')}
                                    value={form.document_was_issued_in_other_country_by}
                                    onChange={(e, { value }) =>
                                        setForm({ ...form, document_was_issued_in_other_country_by: value })
                                    }
                                />
                            </Form.Group>
                            <Form.Group widths={'equal'}>
                                <SuperField
                                    as="datepicker"
                                    size="small"
                                    label={t('date_from')}
                                    value={form.document_was_issued_in_other_country_from}
                                    style={{ height: '2.75rem' }}
                                    onChange={(e, { value }) =>
                                        setForm({ ...form, document_was_issued_in_other_country_from: value })
                                    }
                                />
                                <SuperField
                                    as="datepicker"
                                    size="small"
                                    label={t('date_to')}
                                    value={form.document_was_issued_in_other_country_to}
                                    style={{ height: '2.75rem' }}
                                    onChange={(e, { value }) =>
                                        setForm({ ...form, document_was_issued_in_other_country_to: value })
                                    }
                                />
                            </Form.Group>
                        </>
                    )}

                    <Divider />
                    <Header as={'h5'} content={t('work_position_month_before_sending')} style={{ marginTop: 0 }} />
                    <Form.Group>
                        <Form.Field>
                            <Checkbox
                                radio
                                label={t('yes')}
                                checked={showWorkPosition}
                                onChange={() => setShowWorkPosition(true)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                radio
                                label={t('no')}
                                checked={!showWorkPosition}
                                onChange={() => {
                                    setShowWorkPosition(false)
                                    setForm({ ...form, work_position_month_before_sending: '' })
                                }}
                            />
                        </Form.Field>
                    </Form.Group>
                    {showWorkPosition && (
                        <SuperField
                            as="input"
                            placeholder={t('type_of_activity')}
                            label={t('type_of_activity')}
                            value={form.work_position_month_before_sending}
                            onChange={(e, { value }) => setForm({ ...form, work_position_month_before_sending: value })}
                        />
                    )}

                    <Divider />

                    <Header as={'h5'} content={t('working_in_benefit_of_sender')} style={{ marginTop: 0 }} />
                    <Form.Group>
                        <Form.Field>
                            <Checkbox
                                radio
                                label={t('yes')}
                                checked={form.working_in_benefit_of_sender}
                                onChange={() => setForm({ ...form, working_in_benefit_of_sender: true })}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                radio
                                label={t('no')}
                                checked={!form.working_in_benefit_of_sender}
                                onChange={() => setForm({ ...form, working_in_benefit_of_sender: false })}
                            />
                        </Form.Field>
                    </Form.Group>

                    <Divider />

                    <Header as={'h5'} content={t('assigned_to_different_subject')} style={{ marginTop: 0 }} />
                    <Form.Group>
                        <Form.Field>
                            <Checkbox
                                radio
                                label={t('yes')}
                                checked={form.assigned_to_different_subject}
                                onChange={() => setForm({ ...form, assigned_to_different_subject: true })}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                radio
                                label={t('no')}
                                checked={!form.assigned_to_different_subject}
                                onChange={() => setForm({ ...form, assigned_to_different_subject: false })}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Divider />

                    <Header as={'h5'} content={t('rewarded_by_sending_employer')} style={{ marginTop: 0 }} />
                    <Form.Group>
                        <Form.Field>
                            <Checkbox
                                radio
                                label={t('yes')}
                                checked={form.rewarded_by_sending_employer}
                                onChange={() => setForm({ ...form, rewarded_by_sending_employer: true })}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                radio
                                label={t('no')}
                                checked={!form.rewarded_by_sending_employer}
                                onChange={() => setForm({ ...form, rewarded_by_sending_employer: false })}
                            />
                        </Form.Field>
                    </Form.Group>

                    {!form.rewarded_by_sending_employer && (
                        <SuperField
                            as="input"
                            label={t('employer')}
                            value={form.rewarded_by}
                            onChange={(e, { value }) => setForm({ ...form, rewarded_by: value })}
                        />
                    )}
                    <Divider />

                    <Header as={'h5'} content={t('work_nature_decided_by_sending_employer')} style={{ marginTop: 0 }} />
                    <Form.Group>
                        <Form.Field>
                            <Checkbox
                                radio
                                label={t('yes')}
                                checked={form.work_nature_decided_by_sending_employer}
                                onChange={() => setForm({ ...form, work_nature_decided_by_sending_employer: true })}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                radio
                                label={t('no')}
                                checked={!form.work_nature_decided_by_sending_employer}
                                onChange={() => setForm({ ...form, work_nature_decided_by_sending_employer: false })}
                            />
                        </Form.Field>
                    </Form.Group>

                    {!form.work_nature_decided_by_sending_employer && (
                        <SuperField
                            as="input"
                            label={t('employer')}
                            value={form.work_nature_decided_by}
                            onChange={(e, { value }) => setForm({ ...form, work_nature_decided_by: value })}
                        />
                    )}

                    <Divider />

                    <Header as={'h5'} content={t('is_replacement')} style={{ marginTop: 0 }} />
                    <Form.Group>
                        <Form.Field>
                            <Checkbox
                                radio
                                label={t('yes')}
                                checked={form.is_replacement}
                                onChange={() => setForm({ ...form, is_replacement: true })}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                radio
                                label={t('no')}
                                checked={!form.is_replacement}
                                onChange={() => setForm({ ...form, is_replacement: false })}
                            />
                        </Form.Field>
                    </Form.Group>

                    {form.is_replacement && (
                        <SuperField
                            as="input"
                            label={t('replacement_reason')}
                            value={form.replacement_reason}
                            onChange={(e, { value }) => setForm({ ...form, replacement_reason: value })}
                        />
                    )}
                    <Divider />

                    <Header as={'h5'} content={t('is_agenture')} style={{ marginTop: 0 }} />
                    <Form.Group>
                        <Form.Field>
                            <Checkbox
                                radio
                                label={t('yes')}
                                checked={form.is_agenture}
                                onChange={() => setForm({ ...form, is_agenture: true })}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                radio
                                label={t('no')}
                                checked={!form.is_agenture}
                                onChange={() => setForm({ ...form, is_agenture: false })}
                            />
                        </Form.Field>
                    </Form.Group>

                    <Divider />
                    <Header as={'h4'} content={t('num_of_employees_during_application')} />

                    <Form.Group widths={'equal'}>
                        <SuperField
                            as="input"
                            type="number"
                            label={t('all_employees')}
                            placeholder={t('all_employees')}
                            min="0"
                            value={form.employees_count_all}
                            onChange={(e, { value }) => setForm({ ...form, employees_count_all: value })}
                        />
                        <SuperField
                            as="input"
                            type="number"
                            label={t('inland_employees')}
                            placeholder={t('inland_employees')}
                            min="0"
                            value={form.employees_count_inland}
                            onChange={(e, { value }) => setForm({ ...form, employees_count_inland: value })}
                        />
                        <SuperField
                            as="input"
                            type="number"
                            label={t('abroad_employees')}
                            placeholder={t('abroad_employees')}
                            min="0"
                            value={form.employees_count_abroad}
                            onChange={(e, { value }) => setForm({ ...form, employees_count_abroad: value })}
                        />
                    </Form.Group>
                    <Form.Group widths={'equal'}>
                        <SuperField
                            as="input"
                            type="number"
                            label={t('administrative_employees')}
                            placeholder={t('administrative_employees')}
                            min="0"
                            value={form.administrative_employees_count}
                            onChange={(e, { value }) => setForm({ ...form, administrative_employees_count: value })}
                        />
                        <SuperField
                            as="input"
                            type="number"
                            label={t('given_employees_to_another_subject')}
                            placeholder={t('given_employees_to_another_subject')}
                            min="0"
                            value={form.given_employees_to_another_subject_count}
                            onChange={(e, { value }) =>
                                setForm({ ...form, given_employees_to_another_subject_count: value })
                            }
                        />
                    </Form.Group>

                    <Divider />

                    <SuperField
                        as="checkbox"
                        label={t('employer_follows_slovak_policies')}
                        checked={form.employer_follows_slovak_policies}
                        onChange={() =>
                            setForm({
                                ...form,
                                employer_follows_slovak_policies: !form.employer_follows_slovak_policies,
                            })
                        }
                    />

                    <Form.Group widths={'equal'}>
                        <SuperField
                            as="input"
                            type="number"
                            label={t('expected_turnover_in_sk')}
                            placeholder={t('expected_turnover_in_sk')}
                            min="0"
                            value={form.expected_turnover_in_sk}
                            onChange={(e, { value }) => setForm({ ...form, expected_turnover_in_sk: value })}
                        />
                        <SuperField as="input" label={t('country')} value={t('slovakia')} />
                    </Form.Group>

                    <Form.Group widths={'equal'}>
                        <SuperField
                            as="input"
                            type="number"
                            label={t('expected_turnover_abroad')}
                            placeholder={t('expected_turnover_abroad')}
                            min="0"
                            value={form.expected_turnover_abroad}
                            onChange={(e, { value }) => setForm({ ...form, expected_turnover_abroad: value })}
                        />
                        <SuperField
                            as="choice-select"
                            search
                            label={t('abroad_country')}
                            type="countries"
                            value={form.abroad_country}
                            onChange={(e, { value }) => setForm({ ...form, abroad_country: value })}
                            initialOptions={{
                                attribute: 'abroad_country',
                                as_display: true,
                                source: item,
                            }}
                        />
                    </Form.Group>

                    <Form.Group widths={'equal'}>
                        <SuperField
                            as="input"
                            type="number"
                            label={t('business_count_inland')}
                            placeholder={t('business_count_inland')}
                            min="0"
                            value={form.business_count_inland}
                            onChange={(e, { value }) => setForm({ ...form, business_count_inland: value })}
                        />
                        <SuperField
                            as="input"
                            type="number"
                            label={t('business_count_abroad')}
                            placeholder={t('business_count_abroad')}
                            min="0"
                            value={form.business_count_abroad}
                            onChange={(e, { value }) => setForm({ ...form, business_count_abroad: value })}
                        />
                    </Form.Group>

                    <Divider />

                    <SuperField
                        as="textarea"
                        label={t('additional_info')}
                        value={form.additional_info}
                        onChange={(e, { value }) => setForm({ ...form, additional_info: value })}
                    />
                </Segment>
            )}

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={
                        isProcessing ||
                        isEmpty(form.contract) ||
                        (viewAbroadEmployer &&
                            isEmpty(businessDetail.name) &&
                            isEmpty(businessDetail.identification_number))
                    }
                />
            </Form.Field>
        </Form>
    )
}

export default AbroadForm
