import React from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
//components
import SuperField from '@components/forms/SuperField'
import DatePresets from '@components/DatePresets'
import { Form } from 'semantic-ui-react'

const AbroadsFilters = ({ filters, setFilters }) => {
    const { t } = useTranslation()

    return (
        <Form>
            <Form.Group widths="equal">
                <SuperField
                    search
                    as="datepicker"
                    label={t('date_from')}
                    value={filters.date_from}
                    onChange={(e, { value }) => {
                        setFilters({ ...filters, date_from: value })
                    }}
                />
                <SuperField
                    search
                    as="datepicker"
                    label={t('date_to')}
                    value={filters.date_to}
                    onChange={(e, { value }) => {
                        setFilters({ ...filters, date_to: value })
                    }}
                />
            </Form.Group>
            <DatePresets
                from={filters.date_from}
                to={filters.date_to}
                onSelection={(firstMonthDay, lastMonthDay, year) => {
                    setFilters({ ...filters, date_from: firstMonthDay, date_to: lastMonthDay })
                }}
            />

            <Form.Group widths={'equal'}>
                <SuperField
                    as="choice-select"
                    search
                    label={t('posting_employee')}
                    endpoint={API.EMPLOYEES}
                    additionalFilters={'&only_basic_info=true'}
                    text="fullname_with_titles"
                    value={filters.employee}
                    onChange={(e, { value }) => setFilters({ ...filters, employee: value })}
                />
                <SuperField
                    as="choice-select"
                    type="employment_type"
                    label={t('employment_type')}
                    value={filters.employment_type}
                    onChange={(e, { value }) => setFilters({ ...filters, employment_type: value })}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField
                    as="choice-select"
                    search
                    type="countries"
                    label={t('country')}
                    value={filters.abroad_country}
                    onChange={(e, { value }) => setFilters({ ...filters, abroad_country: value })}
                />

                <SuperField
                    as="choice-select"
                    search
                    text="name"
                    value={filters.unit}
                    label={t('unit')}
                    onChange={(e, { value }) => setFilters({ ...filters, unit: value })}
                    endpoint={API.UNITS}
                    additionalFilters={'&query={id, name}&only_basic_info=true&only_from_active_company=true'}
                />
            </Form.Group>

            <Form.Group widths={'equal'}>
                <SuperField
                    search
                    as="choice-select"
                    label={t('added_employer')}
                    endpoint={API.BUSINESS_DETAIL}
                    additionalFilters={'&query={id, name}&is_account=true'}
                    text="name"
                    value={filters.abroad_employer}
                    onChange={(e, { value }) => {
                        setFilters({ ...filters, abroad_employer: value })
                    }}
                />
                <SuperField
                    as="choice-select"
                    search
                    label={t('employer')}
                    value={filters.inland_employer}
                    text="name"
                    endpoint={API.BUSINESS_DETAIL}
                    additionalFilters={'&query={id, name}&is_employer=true'}
                    onChange={(e, { value }) => setFilters({ ...filters, inland_employer: value })}
                />
            </Form.Group>

            {/* <SuperField
                as="checkbox"
                label={t('is_draft')}
                checked={filters.is_draft}
                onChange={() => setFilters({ ...filters, is_draft: !filters.is_draft })}
            />      
            <SuperField
                as="checkbox"
                label={t('is_active')}
                checked={filters.is_active}
                onChange={() => setFilters({ ...filters, is_active: !filters.is_active })}
            /> */}
        </Form>
    )
}

export default AbroadsFilters
