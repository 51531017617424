import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { routes } from '@routes'
import { API } from '@store/config'
import { icons } from '@store/icons'
import { requests } from '@helpers/requests'
import { useHasPermissions } from '@helpers/hooks'
import { getRandomColor } from '@helpers/functions'
// components
import Icon from '@components/Icon'
import UserAvatar from 'react-user-avatar'
import ListView from '@components/ListView'
import AvatarList from '@components/AvatarList'
import { Form, Divider, List, Popup, Label } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import BreadcrumbNav from '@components/BreadcrumbNav'
import LabelsList from '@components/lists/LabelsList'
import ConfirmModal from '@components/modals/ConfrimModal'
import GenerateDocuments from '@components/GenerateDocuments'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import Attachments from '@components/Attachments'
// specific components
import QuickNotes from './components/QuickNotes'
import AccountForm from './components/AccountForm'

const Accounts = ({ isSuppliers }) => {
    const { t } = useTranslation()

    const canManage = useHasPermissions([
        'accounts.c_manage_all_accounts',
        'accounts.c_manage_user_accounts',
        'accounts.c_manage_assigned_accounts',
    ])

    const [focusedPopup, setFocusedPopup] = useState(0)
    const [openPopup, setOpenPopup] = useState(0)
    const [attachmentsOpen, setAttachmentsOpen] = useState(0)

    const getAccountShortName = (name) => {
        let splitName = name.split(' ')
        if (splitName[0] !== '') return splitName[0] + ' '

        return name
    }

    const getAccountType = (account) => {
        if (!account.is_non_business_client && !account.is_freelancer && !account.is_account_employer)
            return t('business_person')
        if (account.is_non_business_client && !account.is_freelancer && !account.is_account_employer)
            return t('individual_person')
        if (!account.is_non_business_client && account.is_freelancer && !account.is_account_employer)
            return t('self_employed_singular')
        if (!account.is_non_business_client && !account.is_freelancer && account.is_account_employer)
            return t('account_employer')

        return '--'
    }

    const onStateChange = async (state, account, setData, setTotal, filters) => {
        if (account.is_active !== state) {
            const request = await requests.patch(API.ACCOUNTS + 'business_details/' + account.id + '/', {
                is_active: state,
            })
            if (request.status === 200) {
                if (filters.is_active === true) {
                    setData((prev) =>
                        prev.map((item) => {
                            if (item.id === account.id) {
                                item = request.response
                            }
                            return item
                        })
                    )
                } else {
                    setTotal((prev) => prev - 1)
                    setData((prev) => prev.filter((item) => item.id !== account.id))
                }
            }
        }
    }

    return (
        <>
            <BreadcrumbNav
                style={{ marginBottom: 0 }}
                items={[{ name: isSuppliers ? t('suppliers') : t('accounts'), icon: icons.ACCOUNTS, href: '' }]}
            />
            <ListView
                as="table"
                allowSearch
                allowSelection
                actionsCellWidth="2"
                isSoftDeleteAllowed
                endpoint={API.ACCOUNTS + 'business_details/'}
                initialFilters={{
                    is_active: true,
                    exclude_unit: true,
                    [isSuppliers ? 'is_supplier' : 'is_account']: true,
                    ordering: ['-created_on', '-name'],
                    identification_number: '',
                    dit_number: '',
                    vat_number: '',
                    unit: '',
                    tax_mode: '',
                    assigned_to: [],
                }}
                renderFilterFields={(filters, setFilters) => (
                    <>
                        <Form.Group widths="equal">
                            <SuperField
                                as="input"
                                label={t('id_number')}
                                value={filters.identification_number}
                                onChange={(e, { value }) =>
                                    setFilters({
                                        ...filters,
                                        identification_number: value,
                                    })
                                }
                            />
                            <SuperField
                                as="choice-select"
                                type="tax_mode"
                                label={t('tax_mode')}
                                value={filters.tax_mode}
                                onChange={(e, { value }) => setFilters({ ...filters, tax_mode: value })}
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <SuperField
                                as="input"
                                label={t('dit_number')}
                                value={filters.dit_number}
                                onChange={(e, { value }) =>
                                    setFilters({
                                        ...filters,
                                        dit_number: value,
                                    })
                                }
                            />
                            <SuperField
                                as="input"
                                label={t('vat_number')}
                                value={filters.vat_number}
                                onChange={(e, { value }) =>
                                    setFilters({
                                        ...filters,
                                        vat_number: value,
                                    })
                                }
                            />
                        </Form.Group>
                        <SuperField
                            as="choice-select"
                            search
                            multiple
                            label={t('assigned_to')}
                            endpoint={API.EMPLOYEES}
                            additionalFilters="&only_basic_info=true&is_active=true"
                            text="fullname"
                            value={filters.assigned_to}
                            onChange={(e, { value }) =>
                                setFilters({
                                    ...filters,
                                    assigned_to: value,
                                })
                            }
                        />

                        <SuperField
                            as="choice-select"
                            search
                            text="name"
                            label={t('unit')}
                            value={filters.unit}
                            onChange={(e, { value }) => setFilters({ ...filters, unit: value })}
                            endpoint={API.UNITS}
                            additionalFilters="&query={id, name}&only_from_active_company=true"
                        />
                        <SuperField
                            as="choice-select"
                            search
                            multiple
                            endpoint={API.ACCOUNTS + 'labels/'}
                            additionalFilters="&query={id, name}"
                            text="name"
                            label={t('labels')}
                            value={filters.tags}
                            onChange={(e, { value }) =>
                                setFilters({
                                    ...filters,
                                    tags: value,
                                })
                            }
                        />

                        <SuperField
                            as="checkbox"
                            checked={filters.is_active === false}
                            label={t('show_only_inactive')}
                            onChange={() =>
                                setFilters((prev) => ({
                                    ...prev,
                                    is_active: filters.is_active === false ? true : false,
                                }))
                            }
                        />
                    </>
                )}
                listAdditionActions={() => [
                    {
                        as: 'filter',
                        index: 0,
                        name: t('all'),
                        filters: {
                            is_account_employer: '',
                            is_freelancer: '',
                            is_non_business_client: '',
                        },
                    },
                    {
                        as: 'filter',
                        index: 1,
                        name: t('business_person'),
                        filters: {
                            is_account_employer: false,
                            is_freelancer: false,
                            is_non_business_client: false,
                        },
                    },
                    {
                        as: 'filter',
                        index: 2,
                        name: t('self_employed_singular'),
                        filters: {
                            is_freelancer: true,
                            is_account_employer: false,
                            is_non_business_client: false,
                        },
                    },
                    !isSuppliers && {
                        as: 'filter',
                        index: 3,
                        name: t('employer'),
                        filters: {
                            is_account_employer: true,
                            is_freelancer: false,
                            is_non_business_client: false,
                        },
                    },
                    !isSuppliers && {
                        as: 'filter',
                        index: 4,
                        name: t('individual_person'),
                        filters: {
                            is_freelancer: false,
                            is_account_employer: false,
                            is_non_business_client: true,
                        },
                    },
                ]}
                bulkActions={(selected) => [
                    {
                        as: 'modal',
                        name: t('generate_documents'),
                        icon: 'document-text-outline',
                        modal: <GenerateDocuments source="common.BusinessDetail" selected={selected} />,
                    },
                ]}
                exports={[
                    {
                        endpoint: API.EXPORTS + 'dynamic_business_detail_accounts/',
                        permissions: [], // !! TODO: doplniť práva pre export
                        apply_filters: true,
                        type: 'xlsx',
                        filename: isSuppliers ? 'suppliers' : 'accounts',
                    },
                ]}
                tableHeaders={[
                    { title: t('name'), orderBy: 'name' },
                    { title: t('business_detail') },
                    { title: t('type') },
                    { title: t('unit') },
                    { title: t('contacts') },
                    { title: t('tags') },
                    { title: t('responsible_persons') },
                    { title: t('notes') },
                    { title: t('attachments') },
                    { title: t('state') },
                ]}
                renderCells={(account, setData, setTotal, idx, filters) => [
                    {
                        content: (
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ marginRight: '1rem' }}>
                                    <UserAvatar
                                        size={30}
                                        color={account.color + 'B3'}
                                        src={account?.logo || ''}
                                        name={getAccountShortName(account.name)}
                                    />
                                </div>
                                <div>
                                    <strong>{account.name}</strong>
                                    <br />
                                    {account.tax_mode !== null && account.is_non_business_client === false ? (
                                        <span>{account.tax_mode_display}</span>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        ),
                    },
                    {
                        content: (
                            <div>
                                {account?.identification_number ? (
                                    <>
                                        {t('id_number')}:{' '}
                                        <strong>
                                            {account?.identification_number}
                                            <br />
                                        </strong>
                                    </>
                                ) : (
                                    ''
                                )}
                                {account?.dit_number ? (
                                    <>
                                        {t('dit_number')}:{' '}
                                        <strong>
                                            {account?.dit_number}
                                            <br />
                                        </strong>
                                    </>
                                ) : (
                                    ''
                                )}
                                {account?.vat_number ? (
                                    <>
                                        {t('vat_number')}:{' '}
                                        <strong>
                                            {account?.vat_number}
                                            <br />
                                        </strong>
                                    </>
                                ) : (
                                    ''
                                )}
                            </div>
                        ),
                    },
                    {
                        content: <div style={{ opacity: '0.9' }}> {getAccountType(account)}</div>,
                    },
                    {
                        content: account.unit?.name || t('unclassified'),
                    },
                    {
                        content: (
                            <div>
                                {account.contacts.length === 0 ? (
                                    <strong style={{ color: 'var(--danger)' }}> {t('no_active_contact')} </strong>
                                ) : (
                                    <>
                                        {account.contacts[0]?.contact_person_name ? (
                                            <strong>{account.contacts[0]?.contact_person_name}</strong>
                                        ) : (
                                            ''
                                        )}
                                        {account.contacts[0]?.position && (
                                            <span>
                                                {!account.contacts[0]?.contact_person_name ? '' : ' • '}{' '}
                                                {account.contacts[0]?.position}
                                            </span>
                                        )}
                                        {account.contacts[0]?.note && (
                                            <span style={{ marginLeft: '0.5rem' }}>
                                                <Popup
                                                    trigger={<Icon name="information-circle-outline" />}
                                                    content={account.contacts[0]?.note}
                                                />
                                            </span>
                                        )}
                                        {account.contacts[0]?.phone ? (
                                            <>
                                                <br />
                                                <a href={'callto:' + account.contacts[0]?.phone}>
                                                    {' '}
                                                    <Icon name="call-outline" style={{ marginRight: '0.2rem' }} />{' '}
                                                    <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                        {account.contacts[0]?.phone}
                                                    </span>{' '}
                                                </a>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                        {account.contacts[0]?.email ? (
                                            <>
                                                <br />
                                                <a href={'mailto:' + account.contacts[0]?.email}>
                                                    {' '}
                                                    <Icon name="mail-outline" style={{ marginRight: '0.2rem' }} />{' '}
                                                    <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                        {account.contacts[0]?.email}
                                                    </span>{' '}
                                                </a>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                        {account.contacts.length > 1 && (
                                            <div>
                                                <SuperDuperModal
                                                    size="tiny"
                                                    content={
                                                        <div>
                                                            {account.contacts.map((contact, idx) => (
                                                                <div key={idx}>
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            itemsAlign: 'start',
                                                                            justifyContent: 'space-between',
                                                                        }}
                                                                    >
                                                                        <div>
                                                                            {contact.contact_person_name !== '' && (
                                                                                <strong>
                                                                                    {contact.contact_person_name}
                                                                                </strong>
                                                                            )}
                                                                            {contact.position && (
                                                                                <span>
                                                                                    {!contact.contact_person_name
                                                                                        ? ''
                                                                                        : ' • '}{' '}
                                                                                    {contact.position}
                                                                                </span>
                                                                            )}
                                                                            {contact.note && (
                                                                                <span style={{ marginLeft: '0.5rem' }}>
                                                                                    <Popup
                                                                                        trigger={
                                                                                            <Icon name="information-circle-outline" />
                                                                                        }
                                                                                        content={contact.note}
                                                                                    />
                                                                                </span>
                                                                            )}
                                                                            {contact.phone !== '' ? (
                                                                                <>
                                                                                    <br />
                                                                                    <a href={'callto:' + contact.phone}>
                                                                                        {' '}
                                                                                        <Icon
                                                                                            name="call-outline"
                                                                                            style={{
                                                                                                marginRight: '0.2rem',
                                                                                            }}
                                                                                        />{' '}
                                                                                        <span
                                                                                            style={{
                                                                                                position: 'relative',
                                                                                                top: '-0.2rem',
                                                                                            }}
                                                                                        >
                                                                                            {contact.phone}
                                                                                        </span>{' '}
                                                                                    </a>
                                                                                </>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                            {contact?.email !== '' ? (
                                                                                <>
                                                                                    <br />
                                                                                    <a href={'mailto:' + contact.email}>
                                                                                        {' '}
                                                                                        <Icon
                                                                                            name="mail-outline"
                                                                                            style={{
                                                                                                marginRight: '0.2rem',
                                                                                            }}
                                                                                        />{' '}
                                                                                        <span
                                                                                            style={{
                                                                                                position: 'relative',
                                                                                                top: '-0.2rem',
                                                                                            }}
                                                                                        >
                                                                                            {contact.email}
                                                                                        </span>{' '}
                                                                                    </a>{' '}
                                                                                </>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                            {contact?.is_invoicing && (
                                                                                <div
                                                                                    style={{
                                                                                        opacity: 0.8,
                                                                                        fontSize: '0.9rem',
                                                                                        fontWeight: 'bold',
                                                                                        marginTop: '0.2rem',
                                                                                    }}
                                                                                >
                                                                                    {t('used_for_invoicing')}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div style={{ textAlign: 'right' }}>
                                                                            <span
                                                                                style={{
                                                                                    fontWeight: 'bold',
                                                                                    opacity: 0.5,
                                                                                    marginRight: '1rem',
                                                                                    position: 'relative',
                                                                                    top: '-0.1rem',
                                                                                }}
                                                                            >
                                                                                #{idx + 1}.
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <Divider />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    }
                                                    trigger={
                                                        <span className="ref-link" style={{ color: 'var(--primary)' }}>
                                                            {t('show_more_contacts')}
                                                        </span>
                                                    }
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        ),
                    },
                    {
                        content: (
                            <LabelsList
                                key={getRandomColor()}
                                rowID={account.id}
                                labels={account.tags}
                                endpoint={API.ACCOUNTS + 'business_details/'}
                                endpointLabels={API.ACCOUNTS + 'labels/'}
                                label_key={'tags'}
                            />
                        ),
                    },
                    {
                        content: (
                            <AvatarList
                                size="25"
                                alignDirection="start"
                                persons={account.assigned_to.map((item) => ({
                                    id: item.id,
                                    name: item.fullname,
                                    avatar: item.profile_picture,
                                    displayName: item?.fullname_with_titles || item.fullname,
                                }))}
                            />
                        ),
                    },
                    {
                        content: <QuickNotes record={account} setData={setData} />,
                    },
                    {
                        content: (
                            <Label basic>
                                <Popup
                                    basic
                                    hoverable
                                    position="left center"
                                    open={attachmentsOpen === account.id}
                                    style={{ zIndex: '1000', minWidth: '450px' }}
                                    onOpen={() => setAttachmentsOpen(account.id)}
                                    trigger={
                                        <div style={{ cursor: 'pointer' }}>
                                            <Icon name="reader-outline" style={{ marginRight: '0.5rem' }} />
                                            <span style={{ position: 'relative', top: '-0.1rem' }}>
                                                {account.attachments.length}
                                            </span>
                                        </div>
                                    }
                                    content={
                                        <Attachments
                                            viewOnly={!canManage}
                                            allowFolders
                                            record={account}
                                            moduleName={'accounts'}
                                            setData={setData}
                                            sourceEndpoint={API.ACCOUNTS + 'business_details/'}
                                            closeView={() => setAttachmentsOpen(0)}
                                        />
                                    }
                                />
                            </Label>
                        ),
                    },
                    {
                        content: (
                            <>
                                {canManage ? (
                                    <>
                                        <Popup
                                            hoverable
                                            position="bottom left"
                                            open={openPopup === account.id || focusedPopup === account.id}
                                            onOpen={() => setOpenPopup(account.id)}
                                            onClose={() => setOpenPopup(0)}
                                            trigger={
                                                <div style={{ display: 'flex' }}>
                                                    <Icon
                                                        name="ellipse"
                                                        style={{
                                                            color: account.is_active
                                                                ? 'var(--success)'
                                                                : 'var(--danger)',
                                                            marginRight: '0.5rem',
                                                        }}
                                                    />
                                                    <span style={{ position: 'relative', top: '-0.3rem' }}>
                                                        {' '}
                                                        {account.is_active ? t('active_him') : t('inactive_him')}{' '}
                                                    </span>
                                                    <Icon
                                                        name="chevron-down-outline"
                                                        style={{
                                                            marginLeft: '0.5rem',
                                                            position: 'relative',
                                                            top: '0.1rem',
                                                        }}
                                                    />
                                                </div>
                                            }
                                            content={
                                                <div>
                                                    <strong>{t('change_state')}:</strong>
                                                    <List divided selection style={{ width: '250px' }}>
                                                        <List.Item
                                                            active={account.is_active === true}
                                                            onClick={() =>
                                                                onStateChange(true, account, setData, setTotal, filters)
                                                            }
                                                        >
                                                            <Icon
                                                                name="ellipse"
                                                                style={{ marginRight: '0.5rem', color: 'green' }}
                                                            />
                                                            <span style={{ position: 'relative', top: '-0.10rem' }}>
                                                                {t('active_him')}
                                                            </span>
                                                        </List.Item>
                                                        <ConfirmModal
                                                            description={t('set_to_inactive')}
                                                            button={
                                                                <List.Item
                                                                    disabled={account.is_active === false}
                                                                    active={account.is_active === false}
                                                                    onClick={() => setFocusedPopup(account.id)}
                                                                >
                                                                    <Icon
                                                                        name="ellipse"
                                                                        style={{ marginRight: '0.5rem', color: 'red' }}
                                                                    />
                                                                    <span
                                                                        style={{
                                                                            position: 'relative',
                                                                            top: '-0.10rem',
                                                                        }}
                                                                    >
                                                                        {t('inactive_him')}
                                                                    </span>
                                                                </List.Item>
                                                            }
                                                            onConfirm={async () => {
                                                                const request = await requests.patch(
                                                                    API.ACCOUNTS +
                                                                        'business_details/' +
                                                                        account.id +
                                                                        '/',
                                                                    {
                                                                        is_active: false,
                                                                    }
                                                                )

                                                                if (request.status === 200) {
                                                                    toast({
                                                                        type: 'success',
                                                                        icon: 'check circle',
                                                                        title: t('set_as_inactive'),
                                                                        animation: 'pulse',
                                                                        time: 2000,
                                                                    })

                                                                    if (filters.is_active === true) {
                                                                        // remove
                                                                        setTotal((prev) => prev - 1)
                                                                        setData((prev) =>
                                                                            prev.filter(
                                                                                (item) =>
                                                                                    item.id !== request.response.id
                                                                            )
                                                                        )
                                                                    } else {
                                                                        // refresh
                                                                        setData((prev) =>
                                                                            prev.map((item) => {
                                                                                if (item.id === account.id) {
                                                                                    item = request.response
                                                                                }

                                                                                return item
                                                                            })
                                                                        )
                                                                    }
                                                                    setFocusedPopup(0)
                                                                }
                                                            }}
                                                            onRefuse={() => setFocusedPopup(0)}
                                                        />
                                                    </List>
                                                </div>
                                            }
                                        />
                                    </>
                                ) : (
                                    <div style={{ display: 'flex' }}>
                                        <Icon
                                            name="ellipse"
                                            style={{
                                                color: account.is_active ? 'var(--success)' : 'var(--danger)',
                                                marginRight: '0.5rem',
                                            }}
                                        />
                                        <span style={{ position: 'relative', top: '-0.3rem' }}>
                                            {' '}
                                            {account.is_active ? t('active_him') : t('inactive_him')}{' '}
                                        </span>
                                    </div>
                                )}
                            </>
                        ),
                    },
                ]}
                actions={[
                    {
                        as: 'modal',
                        type: 'add',
                        name: isSuppliers ? t('supplier_create') : t('account_create'),
                        modal: <AccountForm isSupplier={isSuppliers} />,
                        permissions: [
                            'accounts.c_manage_all_accounts',
                            'accounts.c_manage_user_accounts',
                            'accounts.c_manage_assigned_accounts',
                        ],
                    },
                    {
                        as: 'link',
                        type: 'custom',
                        icon: 'eye-outline',
                        customIconColor: 'var(--primary)',
                        name: t('show_detail'),
                        redirect: (item) => (isSuppliers ? routes.SUPPLIERS + item.id : routes.ACCOUNTS + item.id),
                        permissions: ['accounts.c_view_all_accounts', 'accounts.c_view_assigned_accounts'],
                    },
                    {
                        as: 'modal',
                        type: 'edit',
                        name: t('edit'),
                        permissions: [
                            'accounts.c_manage_all_accounts',
                            'accounts.c_manage_user_accounts',
                            'accounts.c_manage_assigned_accounts',
                        ],
                        modal: <AccountForm isSupplier={isSuppliers} />,
                    },
                    {
                        name: t('delete'),
                        type: 'delete',
                        as: 'delete',
                        text: isSuppliers ? t('delete_supplier') : t('delete_account'),
                        permissions: ['accounts.c_delete_all_accounts'],
                    },
                ]}
            />
        </>
    )
}

export default Accounts
