import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import moment from 'moment'
// store
import { routes } from '@routes'
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import Action from '@components/general/Action'
import CanView from '@components/perms/CanView'
import ShortenText from '@components/ShortenText'
import { Grid, Header, Card, Label } from 'semantic-ui-react'
import { PieChart, Pie, Legend, Sector, ResponsiveContainer } from 'recharts'

const HiringRoomCard = ({ item, actions, setData, setTotal }) => {
    const { t } = useTranslation()
    const room = item
    const tableActions = actions?.filter((action) => action.type !== 'add') || []

    const [activeIndex, setActiveIndex] = useState(item.hired_candidate_count > 0 ? 0 : 1)

    const getDays = (date) => {
        var m = moment(date)
        var today = moment().startOf('day')

        var days = Math.round(moment.duration(m - today).asDays())

        return days
    }

    const closeRoom = async (id) => {
        const request = await requests.patch(API.HIRING_ROOM + id + '/', { is_active: false })
        if (request.status === 200) {
            setTotal((prev) => prev - 1)
            setData((prev) => prev.filter((data) => data.id !== id))
        }
    }

    const deleteRoom = async (id) => {
        const request = await requests.post(API.HIRING_ROOMS + id + '/delete/')
        if (request.status === 200) {
            setTotal((prev) => prev - 1)
            setData((prev) => prev.filter((data) => data.id !== id))
        }
    }

    const reOpenRoom = async (id) => {
        const request = await requests.patch(API.HIRING_ROOM + id + '/', { is_active: true })
        if (request.status === 200) {
            setTotal((prev) => prev - 1)
            setData((prev) => prev.filter((data) => data.id !== id))
        }
    }

    const onPieEnter = (_, index) => {
        setActiveIndex(index)
    }

    const renderActiveShape = (props) => {
        const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props

        return (
            <g>
                <text style={{ fontWeight: 'bold' }} x={cx} y={cy - 10} dy={8} textAnchor="middle">
                    {value || 0}
                </text>
                <text style={{ fontSize: '0.9rem' }} x={cx} y={cy + 10} dy={8} textAnchor="middle">
                    {payload.name}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
            </g>
        )
    }

    return (
        <>
            {room && (
                <Card style={{ marginBottom: 0, padding: 0 }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                            padding: '1rem',
                            flexWrap: 'wrap',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                //height: '100%',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Header
                                as="h3"
                                style={{
                                    fontWeight: 'bold',
                                    height: '60px',
                                    maxHeight: '60px',
                                    paddingTop: '1rem',
                                }}
                                className="ref-link"
                            >
                                <Link to={routes.HIRING_ROOMS + item.id}>
                                    <ShortenText text={room.name} textLength={27} />
                                </Link>
                            </Header>

                            <div>
                                <div>
                                    <div style={{ alignContent: 'center', color: 'black' }}>{t('due_date')}</div>

                                    {
                                        <strong style={{ marginTop: 0, alignContent: 'center', color: 'black' }}>
                                            {room.date_to ? (
                                                moment(room.date_to).format('DD.MM.YYYY') +
                                                (getDays(room.date_to) >= 0
                                                    ? ', ' + t('in') + ' ' + getDays(room.date_to) + ' ' + t('days')
                                                    : ', ' +
                                                      t('before') +
                                                      ' ' +
                                                      Math.abs(getDays(room.date_to)) +
                                                      ' ' +
                                                      t('dayss'))
                                            ) : (
                                                <span style={{ opacity: '0.5' }}>{t('not_specified')}</span>
                                            )}
                                        </strong>
                                    }
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '1rem',
                                        marginTop: '1rem',
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: 0,
                                            textAlign: 'left',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <div style={{ color: 'var(--variant1)' }}> {t('hired_only')} </div>
                                        {room.hired_candidate_count ? (
                                            <Header
                                                as="h4"
                                                content={room.hired_candidate_count}
                                                style={{ marginTop: 0 }}
                                            />
                                        ) : (
                                            <div>0</div>
                                        )}
                                    </div>
                                    <div
                                        style={{
                                            padding: 0,
                                            textAlign: 'left',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <div style={{ color: 'var(--variant1)' }}> {t('left')} </div>
                                        <Header
                                            as="h4"
                                            content={
                                                (room.target || 0) - (room?.hired_candidate_count || 0) < 0
                                                    ? 0
                                                    : room.target - room.hired_candidate_count
                                            }
                                            style={{ marginTop: 0 }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            padding: 0,
                                            textAlign: 'left',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <div style={{ color: 'var(--variant1)' }}> {t('target')} </div>
                                        {room.target ? (
                                            <Header as="h4" content={room.target} style={{ marginTop: 0 }} />
                                        ) : (
                                            <div>0</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: '130px' }}>
                            <ResponsiveContainer width="100%" height={160}>
                                <PieChart>
                                    <Pie
                                        activeIndex={activeIndex}
                                        activeShape={renderActiveShape}
                                        data={[
                                            {
                                                id: 'hired',
                                                name: t('hired_only'),
                                                value: room.hired_candidate_count,
                                                fill: 'var(--success)',
                                            },
                                            {
                                                id: 'left',
                                                name: t('left'),
                                                value:
                                                    room.target && room.hired_candidate_count <= room.target
                                                        ? room.target - room.hired_candidate_count
                                                        : 0,
                                                fill: 'var(--danger)',
                                            },
                                        ]}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={40}
                                        outerRadius={50}
                                        fill="#8884d8"
                                        dataKey="value"
                                        onMouseEnter={onPieEnter}
                                    />
                                    <Legend
                                        content={
                                            <>
                                                <div
                                                    style={{
                                                        textAlign: 'center',
                                                        width: '100%',
                                                        fontWeight: 'bold',
                                                        fontSize: '1.5rem',
                                                        marginTop: '0.5rem',
                                                    }}
                                                >
                                                    {(room.target
                                                        ? (room.hired_candidate_count / room.target) * 100
                                                        : 0
                                                    ).toFixed(0)}{' '}
                                                    %
                                                </div>
                                            </>
                                        }
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                    <div style={{ padding: '0 1rem', height: '50px', overflow: 'auto' }}>
                        {room?.labels?.map((label, idx) => (
                            <Label
                                basic
                                key={label.id}
                                style={{
                                    background: label.color === '' ? 'var(--dark)' : label.color + '40',
                                    padding: '0.5rem',
                                    marginTop: '0.5rem',
                                    marginLeft: idx === 0 ? 'unset' : '0.25rem',
                                }}
                            >
                                <span
                                    style={{
                                        position: 'relative',
                                        top: '-0.05rem',
                                        fontSize: '0.8rem',
                                        color: 'var(--dark)',
                                    }}
                                >
                                    {label.name}
                                </span>
                            </Label>
                        ))}
                    </div>
                    <Grid
                        style={{
                            position: 'relative',
                            bottom: 0,
                            backgroundColor: 'var(--light)',
                            padding: '0.5rem',
                            paddingTop: '1rem',
                        }}
                    >
                        <Grid.Row style={{ padding: 0, alignContent: 'center' }} columns={2} verticalAlign="middle">
                            <Grid.Column style={{ padding: 0, textAlign: 'left' }}>
                                <>
                                    {room.is_active ? (
                                        <>
                                            {' '}
                                            <Icon
                                                style={{ marginRight: '0.8rem', color: 'var(--success)' }}
                                                name="ellipse"
                                            />{' '}
                                            <strong
                                                style={{ position: 'relative', top: '-0.2rem', color: 'var(--dark)' }}
                                            >
                                                {t('active')}
                                            </strong>
                                        </>
                                    ) : (
                                        <>
                                            {' '}
                                            <Icon
                                                style={{ marginRight: '0.8rem', color: 'var(--danger)' }}
                                                name="ellipse"
                                            />{' '}
                                            <strong
                                                style={{ position: 'relative', top: '-0.2rem', color: 'var(--dark)' }}
                                            >
                                                {t('inactive')}
                                            </strong>
                                        </>
                                    )}
                                </>
                            </Grid.Column>
                            <Grid.Column style={{ padding: 0, textAlign: 'right' }}>
                                <>
                                    {tableActions.map((action, index) => (
                                        <CanView permissions={action.permissions || []} key={index}>
                                            {action?.isHidden?.(item) !== true && (
                                                <Action
                                                    type="icon"
                                                    size="huge"
                                                    icon={action.icon}
                                                    as={action.as}
                                                    paddingTop="0"
                                                    paddingBottom="0"
                                                    paddingLeft="0"
                                                    paddingRight="0.5rem"
                                                    to={action.redirect !== undefined ? action.redirect(item) : null}
                                                    tooltip={action.name}
                                                    iconColor={'var(--dark)'}
                                                />
                                            )}
                                        </CanView>
                                    ))}
                                    {room.is_active ? (
                                        <Action
                                            as="confirm"
                                            size="huge"
                                            type="icon"
                                            iconColor="var(--danger)"
                                            tooltip={t('close_room')}
                                            icon="close-circle-outline"
                                            text={t('are_you_sure_that_you_want_to_close_this_room')}
                                            paddingRight="0"
                                            paddingLeft="0"
                                            onClick={() => closeRoom(room.id)}
                                        />
                                    ) : (
                                        <>
                                            <Action
                                                as="confirm"
                                                type="icon"
                                                size="huge"
                                                iconColor="var(--dark)"
                                                tooltip={t('reopen_room')}
                                                icon="arrow-undo-circle-outline"
                                                text={t('are_you_sure_that_you_want_to_reopen_this_room')}
                                                paddingRight="0"
                                                paddingLeft="0"
                                                onClick={() => reOpenRoom(room.id)}
                                            />
                                        </>
                                    )}
                                    <CanView permissions={['candidates.c_manage_recruitment']}>
                                        <Action
                                            as="delete"
                                            size="huge"
                                            icon="close-outline"
                                            iconColor="var(--danger)"
                                            tooltip={t('delete')}
                                            text={t('delete_hiring_room')}
                                            paddingRight="0"
                                            paddingTop="0"
                                            paddingBottom="0"
                                            paddingLeft="0.5rem"
                                            onClick={() => deleteRoom(room.id)}
                                        />
                                    </CanView>
                                </>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Card>
            )}
        </>
    )
}

export default HiringRoomCard
