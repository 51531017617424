import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import DocPreview from '@components/DocPreview'
import ModalCancel from '@components/buttons/ModalCancel'
import {
    Form,
    Divider,
    Message,
    Header,
    Label,
    Popup,
    Icon as SemanticIcon,
    Button,
    Loader,
    Grid,
    Checkbox,
    Tab,
} from 'semantic-ui-react'
// custom components
import CandidateFormx from './forms/CandidateFormx'

const FileIndicator = ({ isFilled }) => {
    return (
        <>
            {isFilled && (
                <SemanticIcon
                    name="circle"
                    size="small"
                    style={{ position: 'relative', top: '-0.1rem', marginLeft: '0.5rem', color: 'var(--success)' }}
                />
            )}
        </>
    )
}

const CandidateUsingAI = ({ room, onClose, onCreate, setData, setTotal, setSize, setAllowClose }) => {
    const { t } = useTranslation()

    const [file, setFile] = useState(null)
    const [frontFile, setFrontFile] = useState(null)
    const [backFile, setBackFile] = useState(null)
    const [view, setView] = useState(0)
    const [processing, setProcessing] = useState(false)
    const [parsedData, setParsedData] = useState([]) // multiple files are being processed
    const [bothSides, setBothSides] = useState(false)
    const [storeFiles, setStoreFiles] = useState(true)

    const [processingFile, setProcessingFile] = useState(null)

    const [process, setProcess] = useState('OCR')

    const [lockID, setLockID] = useState(null)
    const [processView, setProcessView] = useState('cv')

    const hiddenFileInput = React.useRef(null)
    const handleResumeClick = (event) => {
        hiddenFileInput.current.click()
    }

    const hiddenFileFrontInput = React.useRef(null)
    const handleFrontClick = (event) => {
        hiddenFileFrontInput.current.click()
    }

    const hiddenFileBackInput = React.useRef(null)
    const handleBackClick = (event) => {
        hiddenFileBackInput.current.click()
    }

    function generateUniqueKey() {
        return Date.now().toString(36) + Math.random().toString(36).substr(2, 9)
    }

    const handleFileInputChange = (event, callback) => {
        const selectedFiles = event.target.files

        // Allowed file types
        const allowedTypes = ['image/png', 'image/jpeg', 'application/pdf']

        const newFiles = Array.from(selectedFiles).filter((file) => {
            const isValidSize = file.size <= 2 * 1024 * 1024 // 2 MB
            const isValidType = allowedTypes.includes(file.type)
            return isValidSize && isValidType
        })

        if (newFiles.length > 0) {
            callback(newFiles[0])
        } else {
            toast({
                type: 'error',
                icon: 'warning circle',
                title: t('Please select files with a size of 2 MB or less and of type PNG, JPG, or PDF.'),
                description: '',
                animation: 'bounce',
                time: 5000,
            })
        }
    }

    const prepareData = () => {
        let queue = []
        if (file) {
            queue.push({
                type: 'resume',
                file: file,
                file_url: null,
                result: null,
                content: null,
            })
        }

        if (frontFile || backFile) {
            let type = lockID

            if (frontFile) {
                queue.push({
                    type: type + '__frontfile',
                    file: frontFile,
                    file_url: null,
                    result: null,
                    content: null,
                })

                if (backFile) {
                    queue.push({
                        type: type + '__backfile',
                        file: backFile,
                        file_url: null,
                        result: null,
                        content: null,
                    })
                }
            } else {
                toast({
                    type: 'error',
                    icon: 'warning circle',
                    title: t('frontside_is_required_to_process_document'),
                    description: '',
                    animation: 'bounce',
                    time: 5000,
                })
                return []
            }
        }

        return queue
    }

    const performOCR = async (queueItem) => {
        setProcessingFile(queueItem.file)

        const queueItemCopy = queueItem

        const formData = new FormData()
        formData.append('request', queueItem.file)
        const request = await requests.post(API.AZURE_PARSER + 'read/', formData)

        let ocr_processing = false
        let parsed_document = null
        let parsed_document_url = null

        if (request.status !== 200) {
            // !! TODO: add logging using state management?
            toast({
                type: 'warning',
                icon: 'warning circle',
                title: t('the_file_is_either_corrupted_or_in_an_incorrect_format'),
                description: queueItem.file?.name,
                animation: 'bounce',
                time: 5000,
            })
        } else {
            ocr_processing = true
        }

        while (ocr_processing === true) {
            if (ocr_processing === true) {
                await new Promise((resolve) => setTimeout(resolve, 3000))
            }

            if (request?.response?.response) {
                const verify_parsed_document = await requests.get(
                    API.AZURE_PARSER + `result/?url=${request.response.response}`
                )
                if (verify_parsed_document.status === 200) {
                    // verify if response contain any content
                    if (verify_parsed_document.response?.response?.status === 'succeeded') {
                        ocr_processing = false
                        parsed_document_url = verify_parsed_document.response?.file
                        parsed_document = verify_parsed_document.response?.response?.analyzeResult
                    }
                }
            }
        }

        if (parsed_document && parsed_document_url && parsed_document?.content?.length > 0) {
            queueItemCopy.content = parsed_document?.content || null
            queueItemCopy.file_url = parsed_document_url
        }

        setProcessingFile(null)
        return queueItemCopy
    }

    const handleSubmit = async () => {
        let queue = prepareData()
        if (queue.length === 0) return

        setProcessing(true)
        setSize('tiny')
        setAllowClose(false)
        //setProcessedFiles(queue) // now loop over queue to handle stuff

        let processedItems = []
        for (let i = 0; i < queue.length; i++) {
            let processedItem = await performOCR(queue[i])
            processedItems.push(processedItem)
        }

        let analysedItems = []
        if (processedItems.filter((item) => item.content !== null).length > 0) {
            setProcess('AI')
            // Resume first:
            const resume = processedItems.find((item) => item.type === 'resume')
            if (resume?.content?.length > 0) {
                // perform resume extraction
                const extractResume = await requests.post(API.OPENAI + `ask-chatty/`, {
                    instruction: `
                        Extract data from the given text and return it in JSON format. Use the following rules:
                        - Extract only English/Slovak characters, ignoring Cyrillic ones.
                        - Convert dates to 'YYYY-MM-DD' format.
                        - For country, citizenship, and nationality, convert to ISO 3166-1 alpha-2 codes.
                        - For gender: use 1 for Male, 2 for Female, 3 for Unrecognized.
                        - Normalize names to capitalize the first letter and lowercase the rest (e.g., "JAKUB" should become "Jakub").
                        - Return the data in this structure: {firstname,lastname,middlename,gender,email,phone,driver_licence,address{street,number,orientation_number,postcode,city,country},date_of_birth,skills{name,seniority},languages{name,level},work_experiences{position,employer,job_description,date_from,date_to},educations{school_name,date_from,date_to},interests{name}}`,
                    prompt: resume.content,
                })

                if (extractResume.status === 200) {
                    resume.result = extractResume.response
                    analysedItems.push(resume)
                }
            }

            // identity document (merge into one if there are multiple)
            const frontSide = processedItems.find((item) => item.type === lockID + '__frontfile')
            const backSide = processedItems.find((item) => item.type === lockID + '__backfile')

            let identityDocument = {
                type: lockID,
                result: null,
                content: null,
                frontfile: null,
                backfile: null,
                frontfile_url: null,
                backfile_url: null,
            }

            if (frontSide) {
                identityDocument.frontfile = frontSide.file
                identityDocument.frontfile_url = frontSide.file_url
                identityDocument.content = 'Document frontside: ' + frontSide.content
            }

            if (backSide) {
                identityDocument.backfile = backSide.file
                identityDocument.backfile_url = backSide.file_url
                identityDocument.content += ' Document backside: ' + backSide.content
            }

            if (identityDocument?.content?.length > 0) {
                const extractIdentityDocument = await requests.post(API.OPENAI + `ask-chatty/`, {
                    instruction: `
                        Extract data from the given text and return it in JSON format. Use the following rules:
                        - Extract only English/Slovak characters, ignoring Cyrillic ones.
                        - Convert dates to 'YYYY-MM-DD' format.
                        - For country, citizenship, and nationality, convert to ISO 3166-1 alpha-2 codes.
                        - For gender: use 1 for Male, 2 for Female, 3 for Unrecognized.
                        - Normalize names to capitalize the first letter and lowercase the rest (e.g., "JAKUB" should become "Jakub").
                        - Return the data in this structure: {personal_number,firstname,lastname,gender,date_of_birth,place_of_birth,nationality,citizenship,document_number,document_type,date_of_issue,date_of_expiration,country_of_issue,address{street,number,orientation_number,postcode,city,country}}`,
                    prompt: identityDocument.content,
                })

                if (extractIdentityDocument.status === 200) {
                    identityDocument.result = extractIdentityDocument.response
                    analysedItems.push(identityDocument)
                }
            }

            setParsedData(analysedItems)
            setSize('fullscreen')
            setView(1)
        } else {
            // TODO: maybe some info about that there is no content to analyze?
            setSize('small')
            setAllowClose(true)
        }

        setProcessing(false)
    }

    useEffect(() => {
        if (frontFile || backFile) {
            setLockID(processView)
        } else {
            setLockID(null)
        }
    }, [frontFile, backFile])

    const getIsFilled = (id) => {
        if (lockID === id) {
            if (frontFile || backFile) return true
        }

        return false
    }

    const getFiles = () => {
        let files = []

        let resume = parsedData.find((item) => item.type === 'resume')
        if (resume) {
            files.push({
                type: 'resume',
                filename: resume.file?.name,
                file_url: resume.file_url,
            })
        }

        let identityDocument = parsedData.find((item) => item.type === lockID)
        if (identityDocument?.frontfile_url) {
            files.push({
                type: 'frontside',
                filename: identityDocument.frontfile?.name,
                file_url: identityDocument.frontfile_url,
            })
        }

        if (identityDocument?.backfile_url) {
            files.push({
                type: 'frontside',
                filename: identityDocument.backfile?.name,
                file_url: identityDocument.backfile_url,
            })
        }

        return files
    }

    return (
        <>
            {view === 0 ? (
                <>
                    {processing ? (
                        <>
                            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                {process === 'OCR' ? processingFile?.name : null}
                            </div>
                            <div class="scanner-container">
                                <Icon name="document-text-outline" />
                                <div class="scanner-overlay">
                                    <div class="scanner-line"></div>
                                </div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <strong>
                                    <Loader
                                        size="tiny"
                                        className="dark-loader"
                                        active
                                        inline
                                        style={{ marginRight: '0.5rem' }}
                                    />
                                    <span style={{ position: 'relative', top: '0.2rem' }}>
                                        {process === 'OCR' ? t('ocr_processing') + '...' : t('ai_processing') + '...'} (
                                        {process === 'OCR' ? '1' : '2'}/2)
                                    </span>
                                </strong>
                            </div>
                        </>
                    ) : (
                        <Form onSubmit={handleSubmit}>
                            <InformationHeader />
                            <Divider />
                            <strong>{t('select_processing_type')}</strong>
                            <Popup
                                trigger={<Icon name="information-circle-outline" style={{ marginLeft: '0.5rem' }} />}
                                content={t(
                                    'create_profile_by_uploading_resume_and_identity_document_only_one_identity_document_is_allowed'
                                )}
                            />
                            <Button.Group
                                size="small"
                                style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }}
                                fluid
                                basic
                            >
                                <Button
                                    type="button"
                                    active={processView === 'cv'}
                                    onClick={() => setProcessView('cv')}
                                >
                                    {t('resume')}
                                    <FileIndicator isFilled={file !== null} />
                                </Button>
                                <Button
                                    type="button"
                                    disabled={lockID !== 'passport' && lockID}
                                    active={processView === 'passport'}
                                    onClick={() => setProcessView('passport')}
                                >
                                    {t('passport')}
                                    <FileIndicator isFilled={getIsFilled('passport')} />
                                </Button>
                                <Button
                                    type="button"
                                    disabled={lockID !== 'id_card' && lockID}
                                    active={processView === 'id_card'}
                                    onClick={() => setProcessView('id_card')}
                                >
                                    {t('id_card')}
                                    <FileIndicator isFilled={getIsFilled('id_card')} />
                                </Button>

                                <Button
                                    type="button"
                                    disabled={lockID !== 'driver_licence' && lockID}
                                    active={processView === 'driver_licence'}
                                    onClick={() => setProcessView('driver_licence')}
                                >
                                    {t('driver_licence')}
                                    <FileIndicator isFilled={getIsFilled('driver_licence')} />
                                </Button>
                                <Button
                                    type="button"
                                    disabled={lockID !== 'residence_card' && lockID}
                                    active={processView === 'residence_card'}
                                    onClick={() => setProcessView('residence_card')}
                                >
                                    {t('residence_card')}
                                    <FileIndicator isFilled={getIsFilled('residence_card')} />
                                </Button>
                            </Button.Group>
                            <Divider style={{ marginTop: '0.2rem' }} />
                            {processView === 'cv' && (
                                <>
                                    <div style={{ textAlign: 'center' }}>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            ref={hiddenFileInput}
                                            onChange={(e) => handleFileInputChange(e, (file) => setFile(file))}
                                            style={{ display: 'none' }}
                                            accept=".png, .jpg, .jpeg, .pdf"
                                        />
                                        <div
                                            style={{
                                                padding: '0.7rem',
                                                paddingTop: '2rem',
                                                paddingBottom: '2rem',
                                                cursor: 'pointer',
                                                background: 'var(--light-grey)',
                                                border: '1px dashed var(--dark)',
                                                borderRadius: 'var(--border-radius)',
                                            }}
                                            onClick={handleResumeClick}
                                        >
                                            <Header
                                                as="h4"
                                                style={{
                                                    marginTop: '0.5rem',
                                                    marginBottom: '0.5rem',
                                                    fontSize: '15px',
                                                }}
                                            >
                                                <span>
                                                    <SemanticIcon
                                                        name="upload"
                                                        size="tiny"
                                                        style={{
                                                            color: 'var(--primary)',
                                                            fontSize: '15px',
                                                            marginRight: '0.4rem',
                                                        }}
                                                    />
                                                    {t('upload_file')}
                                                </span>
                                                {file ? (
                                                    <>
                                                        <Header.Subheader
                                                            content={file?.name || t('one_file_selected')}
                                                        />
                                                    </>
                                                ) : (
                                                    <Header.Subheader content={t('no_file_selected')} />
                                                )}
                                            </Header>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: '0.5rem',
                                        }}
                                    >
                                        <div>
                                            {t('allowed_format')}: <strong>PDF, JPG, JPEG, PNG</strong>,{' '}
                                            {t('max_file_size')}: <strong>2 mb</strong>
                                        </div>
                                        {file && (
                                            <Button
                                                size="small"
                                                type="button"
                                                style={{ background: 'var(--danger)', color: 'var(--white)' }}
                                                onClick={() => {
                                                    setFile(null)
                                                    document.getElementById('fileInput').value = null
                                                }}
                                            >
                                                {t('remove')}
                                            </Button>
                                        )}
                                    </div>
                                </>
                            )}
                            {processView !== 'cv' && (
                                <>
                                    <Form.Field>
                                        <Checkbox
                                            checked={bothSides}
                                            onClick={() => {
                                                if (!bothSides === false) {
                                                    if (document.getElementById('fileBackInput')) {
                                                        setBackFile(null)
                                                        document.getElementById('fileBackInput').value = null
                                                    }
                                                }
                                                setBothSides(!bothSides)
                                            }}
                                            label={t('allow_to_upload_the_back_side_of_document')}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <Checkbox
                                            checked={storeFiles}
                                            onClick={() => setStoreFiles(!storeFiles)}
                                            label={t('allow_to_save_attached_files_to_profile')}
                                        />
                                    </Form.Field>

                                    {bothSides && <Divider />}

                                    <Grid style={{ marginTop: '0.5rem' }}>
                                        <Grid.Row columns={bothSides ? 2 : 1} style={{ padding: '0' }}>
                                            <Grid.Column style={bothSides ? { paddingLeft: 0 } : { padding: 0 }}>
                                                {bothSides && (
                                                    <p
                                                        style={{
                                                            textAlign: 'center',
                                                            fontWeight: 'bold',
                                                            marginBottom: '0.5rem',
                                                        }}
                                                    >
                                                        {t('front_side')}{' '}
                                                        <sup style={{ color: 'var(--danger)' }}>*</sup>
                                                    </p>
                                                )}
                                                <div style={{ textAlign: 'center' }}>
                                                    <input
                                                        type="file"
                                                        id="fileFrontInput"
                                                        ref={hiddenFileFrontInput}
                                                        onChange={(e) =>
                                                            handleFileInputChange(e, (file) => setFrontFile(file))
                                                        }
                                                        style={{ display: 'none' }}
                                                        accept=".png, .jpg, .jpeg, .pdf"
                                                    />
                                                    <div
                                                        style={{
                                                            padding: '0.7rem',
                                                            paddingTop: '2rem',
                                                            paddingBottom: '2rem',
                                                            cursor: 'pointer',
                                                            background: 'var(--light-grey)',
                                                            border: '1px dashed var(--dark)',
                                                            borderRadius: 'var(--border-radius)',
                                                        }}
                                                        onClick={handleFrontClick}
                                                    >
                                                        <Header
                                                            as="h4"
                                                            style={{
                                                                marginTop: '0.5rem',
                                                                marginBottom: '0.5rem',
                                                                fontSize: '15px',
                                                            }}
                                                        >
                                                            <span>
                                                                <SemanticIcon
                                                                    name="upload"
                                                                    size="tiny"
                                                                    style={{
                                                                        color: 'var(--primary)',
                                                                        fontSize: '15px',
                                                                        marginRight: '0.4rem',
                                                                    }}
                                                                />
                                                                {t('upload_file')}
                                                            </span>
                                                            {frontFile ? (
                                                                <Header.Subheader
                                                                    content={frontFile?.name || t('one_file_selected')}
                                                                />
                                                            ) : (
                                                                <Header.Subheader content={t('no_file_selected')} />
                                                            )}
                                                        </Header>
                                                    </div>
                                                </div>
                                            </Grid.Column>
                                            {bothSides && (
                                                <Grid.Column style={bothSides ? { paddingRight: 0 } : { padding: 0 }}>
                                                    {bothSides && (
                                                        <p
                                                            style={{
                                                                textAlign: 'center',
                                                                fontWeight: 'bold',
                                                                marginBottom: '0.5rem',
                                                            }}
                                                        >
                                                            {t('back_side')}
                                                        </p>
                                                    )}
                                                    <div style={{ textAlign: 'center' }}>
                                                        <input
                                                            type="file"
                                                            id="fileBackInput"
                                                            ref={hiddenFileBackInput}
                                                            onChange={(e) =>
                                                                handleFileInputChange(e, (file) => setBackFile(file))
                                                            }
                                                            style={{ display: 'none' }}
                                                            accept=".png, .jpg, .jpeg, .pdf"
                                                        />
                                                        <div
                                                            style={{
                                                                padding: '0.7rem',
                                                                paddingTop: '2rem',
                                                                paddingBottom: '2rem',
                                                                cursor: 'pointer',
                                                                background: 'var(--light-grey)',
                                                                border: '1px dashed var(--dark)',
                                                                borderRadius: 'var(--border-radius)',
                                                            }}
                                                            onClick={handleBackClick}
                                                        >
                                                            <Header
                                                                as="h4"
                                                                style={{
                                                                    marginTop: '0.5rem',
                                                                    marginBottom: '0.5rem',
                                                                    fontSize: '15px',
                                                                }}
                                                            >
                                                                <span>
                                                                    <SemanticIcon
                                                                        name="upload"
                                                                        size="tiny"
                                                                        style={{
                                                                            color: 'var(--primary)',
                                                                            fontSize: '15px',
                                                                            marginRight: '0.4rem',
                                                                        }}
                                                                    />
                                                                    {t('upload_file')}
                                                                </span>
                                                                {backFile ? (
                                                                    <Header.Subheader
                                                                        content={
                                                                            backFile?.name || t('one_file_selected')
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <Header.Subheader content={t('no_file_selected')} />
                                                                )}
                                                            </Header>
                                                        </div>
                                                    </div>
                                                </Grid.Column>
                                            )}
                                        </Grid.Row>
                                    </Grid>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: '0.5rem',
                                        }}
                                    >
                                        <div>
                                            {t('allowed_format')}: <strong>PDF, JPG, JPEG, PNG</strong>,{' '}
                                            {t('max_file_size')}: <strong>2 mb</strong>
                                        </div>
                                        {(backFile || frontFile) && (
                                            <Button
                                                size="small"
                                                type="button"
                                                style={{ background: 'var(--danger)', color: 'var(--white)' }}
                                                onClick={() => {
                                                    setFrontFile(null)
                                                    document.getElementById('fileFrontInput').value = null
                                                    if (document.getElementById('fileBackInput')) {
                                                        setBackFile(null)
                                                        document.getElementById('fileBackInput').value = null
                                                    }
                                                }}
                                            >
                                                {t('remove')}
                                            </Button>
                                        )}
                                    </div>
                                </>
                            )}
                            <Divider />
                            <Form.Field style={{ textAlign: 'right' }}>
                                {!processing && <ModalCancel onClose={onClose} />}
                                <Button
                                    primary
                                    disabled={(file === null && frontFile === null && backFile === null) || processing}
                                    loading={processing}
                                >
                                    {t('confirm')}
                                </Button>
                            </Form.Field>
                        </Form>
                    )}
                </>
            ) : (
                <Grid>
                    <Grid.Row style={{ paddingTop: 0, borderBottom: '1px solid var(--light)' }}>
                        <Grid.Column
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <div>
                                <Icon
                                    name="close-outline"
                                    style={{
                                        cursor: 'pointer',
                                        color: 'var(--danger)',
                                        fontWeight: 'bold',
                                        fontSize: '1.5rem',
                                    }}
                                    onClick={() => onClose()}
                                />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns="2" divided>
                        <Grid.Column width={7}>
                            <Tab
                                panes={getFiles().map((item) => ({
                                    menuItem: {
                                        key: item.type,
                                        icon: 'file alternate outline',
                                        content: item.filename,
                                    },
                                    render: () => (
                                        <Tab.Pane>
                                            <DocPreview key={generateUniqueKey()} uri={item.file_url} />
                                        </Tab.Pane>
                                    ),
                                }))}
                            />
                        </Grid.Column>
                        <Grid.Column width={9}>
                            <CandidateFormx
                                parsedData={parsedData}
                                isHiringRoom={room ? true : false}
                                onCreate={onCreate}
                                onClose={onClose}
                                setData={setData}
                                setTotal={setTotal}
                                storeIdentityDocuments={storeFiles}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </>
    )
}

const InformationHeader = () => {
    const { t } = useTranslation()

    return (
        <Message visible>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '0.5rem',
                }}
            >
                <Header as="h3" style={{ marginTop: '0.1rem' }}>
                    {t('upload_your_file_and_let_ai_handle_the_rest')}
                </Header>
                <Popup
                    flowing
                    hoverable
                    position="left center"
                    trigger={
                        <Label
                            style={{
                                fontWeight: 'bold',
                                background: 'var(--blue)',
                                color: 'var(--light)',
                                cursor: 'default',
                            }}
                        >
                            BETA
                        </Label>
                    }
                    content={
                        <div>
                            <Header as="h4" style={{ marginBottom: 0 }}>
                                {t('testing_mode')}
                            </Header>
                            <span>{t('one_file_at_a_time_bulk_processing_is_not_available')}</span> <br />
                        </div>
                    }
                />
            </div>
            <p>
                {t('allow_us_to_perform')}{' '}
                <Popup
                    hoverable
                    position="bottom center"
                    content={
                        <div>
                            <strong>OCR</strong> (Optical Character Recognition) {t('ocr_definition')}
                            <br />
                            <br />
                            <strong>AI</strong> (Artificial Intelligence) {t('ai_definition')}
                            <br />
                        </div>
                    }
                    trigger={
                        <strong style={{ cursor: 'default', textDecoration: 'underline' }}>
                            {t('ocr_and_ai_processing')}
                        </strong>
                    }
                />{' '}
                {t('on_your_file_to_extract_relevant_information')}.
            </p>
            <Divider style={{ marginBottom: '0.5rem' }} />
            <span style={{ color: 'var(--danger)', fontWeight: 'bold' }}>
                <Icon name="warning-outline" style={{ marginRight: '0.5rem', position: 'relative', top: '0.1rem' }} />
                {t('make_sure_to_review_the_results_before_making_any_decisions')}
            </span>
        </Message>
    )
}

export default CandidateUsingAI
