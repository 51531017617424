import React, { useMemo } from 'react'
import {
    MDXEditor,
    headingsPlugin,
    listsPlugin,
    linkPlugin,
    quotePlugin,
    toolbarPlugin,
    UndoRedo,
    BlockTypeSelect,
    BoldItalicUnderlineToggles,
    ListsToggle,
    linkDialogPlugin,
    CreateLink,
    CodeToggle,
    DiffSourceToggleWrapper,
    diffSourcePlugin,
    markdownShortcutPlugin,
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import { Form } from 'semantic-ui-react'

const MKEditor = ({ readOnly, label, markdown, onChange, excludeHeaders, excludeDiffSource, excludeCheckList }) => {
    const plugins = useMemo(
        () => [
            linkPlugin(),
            linkDialogPlugin(),
            headingsPlugin(),
            listsPlugin(),
            quotePlugin(),
            diffSourcePlugin(),
            markdownShortcutPlugin(),
            toolbarPlugin({
                toolbarContents: () => (
                    <>
                        {' '}
                        <UndoRedo />
                        <BoldItalicUnderlineToggles />
                        {excludeHeaders ? null : <BlockTypeSelect />}
                        <ListsToggle
                            options={excludeCheckList ? ['bullet', 'number'] : ['bullet', 'number', 'check']}
                        />
                        <CreateLink />
                        <CodeToggle />
                        {excludeDiffSource ? null : <DiffSourceToggleWrapper />}
                    </>
                ),
            }),
        ],
        []
    )

    return (
        <Form.Field onClick={(e) => e.stopPropagation()} onFocus={(e) => e.stopPropagation()}>
            <label style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>{label}</label>
            <MDXEditor
                onClick={(e) => e.stopPropagation()}
                onFocus={(e) => e.stopPropagation()}
                readOnly={readOnly || false}
                contentEditableClassName="mdxeditor"
                markdown={markdown || ''}
                onChange={onChange}
                plugins={plugins}
            />
        </Form.Field>
    )
}

export default MKEditor
