import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import { Header, Form, Grid, Button } from 'semantic-ui-react'
import { FlexRow, FlexItem } from '@components/tables/FlexTable'
import ListView from '@components/ListView'
import SuperField from '@components/forms/SuperField'
import DatePresets from '@components/DatePresets'
import SpinnerSegment from '@components/SpinnerSegment'

const RecruiterStats = () => {
    const { t } = useTranslation()
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [tableFilters, setTableFilters] = useState({
        start_date: '',
        end_date: '',
    })

    async function fetchRecruitingStats() {
        setIsLoading(true)
        const query = tableFilters.start_date
            ? '?start_date=' + tableFilters.start_date
            : '' + tableFilters.end_date
            ? '&end_date=' + tableFilters.end_date
            : ''
        const request = await requests.get(API.RECRUITER_STATS + query)

        if (request.status === 200) {
            setData(request.response)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        fetchRecruitingStats()
    }, [])

    return (
        <>
            <Header as="h2" textAlign="left" content={t('rectruiters_stats')} />
            <Grid stackable>
                <Grid.Row verticalAlign="middle" columns={2}>
                    <Grid.Column width={10}>
                        <Form onSubmit={fetchRecruitingStats}>
                            <Form.Group widths="equal">
                                <SuperField
                                    search
                                    as="datepicker"
                                    label={t('date_from')}
                                    value={tableFilters.start_date}
                                    onChange={(e, { value }) => {
                                        setTableFilters({ ...tableFilters, start_date: value })
                                    }}
                                />
                                <SuperField
                                    search
                                    as="datepicker"
                                    label={t('date_to')}
                                    value={tableFilters.end_date}
                                    onChange={(e, { value }) => {
                                        setTableFilters({ ...tableFilters, end_date: value })
                                    }}
                                />
                                <Form.Field style={{ marginTop: '1.7rem' }}>
                                    <Button
                                        primary
                                        content={t('confirm')}
                                        disabled={tableFilters.start_date === '' || tableFilters.end_date === ''}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <ListView
                as="table"
                forceLoading={isLoading}
                externalData={data}
                tableHeaders={[
                    { title: t('recruiter') },
                    { title: t('total_hired') },
                    { title: t('two_weeks_hired') },
                    { title: t('month_hired') },
                    { title: t('two_month_hired') },
                    { title: t('three_month_hired') },
                    { title: t('year_hired') },
                    { title: t('two_years_hired') },
                ]}
                renderCells={(item) => [
                    { content: item?.fullname },
                    { content: item?.hired_candidate_count },
                    { content: item?.two_weeks_hired_candidate_count },
                    { content: item?.month_hired_candidate_count },
                    { content: item?.two_month_hired_candidate_count },
                    { content: item?.three_month_hired_candidate_count },
                    { content: item?.year_hired_candidate_count },
                    { content: item?.two_years_hired_candidate_count },
                ]}
            />
        </>
    )
}

export default RecruiterStats
